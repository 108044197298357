import React from "react";

function CardRowStyleOne({ datas }) {
  return (
    <div className="item mt-30">
      <div className="thumb">
        <img
          src={require(`../../assets/images/${datas.image}`).default}
          alt=""
        />
      </div>
      <div className="content">
        <a href="#">
          <h5 className="title">{datas.title}</h5>
        </a>
        <span>{datas.date}</span>
      </div>
    </div>
  );
}

export default CardRowStyleOne;
