import React from "react";
import data from "../../Data/HomeOne";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Project from "../../HomeFive/Project";
import Team from "../../HomeOne/Team";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Services from "./Services";

function ServiceOne() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Service page one"
        breadcrumb={["Home", "Service one"]}
      />
      <Services />
      <Project />
      <Team datas={data.teamMembers} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default ServiceOne;
