import React, { useEffect } from "react";
import MasonryCss from "../../../Lib/MasonryCss";

function MainSection() {
  useEffect(() => {
    setTimeout(() => {
      MasonryCss(".grid-2", ".grid-item");
    }, 500);
  });
  return (
    <div className="portfolio-style-2">
      <div className="container">
        <div className="row grid-2">
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-1.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-2.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-4.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-3.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-5.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-6.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3  grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-7.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="portfolio-style-2-item item-8 mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-8.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3  grid-item">
            <div className="portfolio-style-2-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-item-9.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../../assets/images/project-line.png`)}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={
                      require(`../../../assets/images/icon/portfolio-arrow.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
