import React, { useEffect } from "react";
import MasonryCss from "../../../Lib/MasonryCss";

function Project() {
  useEffect(() => {
    setTimeout(() => {
      MasonryCss(".grid-2", ".grid-item");
    }, 500);
  });
  return (
    <section className="portfolio-style-3-area portfolio-service-3 mb-35">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <h3 className="title">Our Project</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className="row grid-2">
          <div className="col-lg-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-1.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital artist design</h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-2.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-3.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-4.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-6.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-7.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-5.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-8.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital </h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-10.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital artist design</h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="portfolio-style-2-item portfolio-style-3-item mt-30">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-9.png`)
                    .default
                }
                alt=""
              />
              <div className="project-overlay d-flex justify-content-center align-items-center">
                <div className="item text-center">
                  <h4 className="title">Digital</h4>
                  <span>Illustration</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
