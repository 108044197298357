import React from "react";

function Article() {
  return (
    <section className="article-3-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-3">
            <div className="article-3-title">
              <h3 className="title">My Latest Article</h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="article-3-title">
              <p className="text">
                From research and strategy to design and implementation, we
                partner with some of the world’s most impactful companies to
                transform their brands and businesses.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="article-3-item mt-30">
              <div className="article-thumb">
                <img
                  src={
                    require(`../../assets/images/article-thumb-1.png`).default
                  }
                  alt=""
                />
              </div>
              <div className="article-content">
                <span>
                  TECHNOLOGY <span>April 26, 2020</span>
                </span>
                <a href="#">
                  <h3 className="title">
                    Unlocking Design for a New Generation (Theo Strauss,
                    Stanford)
                  </h3>
                </a>
                <p>
                  From research and strategy to and implementation, we partner
                  with some of the world’s most impactful companies to transform
                  brands and businesses.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="article-3-list">
              <div className="item mt-30 d-block d-sm-flex">
                <div className="thumb">
                  <img
                    src={
                      require(`../../assets/images/article-thumb-2.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="content">
                  <a href="#">
                    <h4 className="title">
                      Miami woman deliver her powerful winds kept help from
                      nuture…
                    </h4>
                  </a>
                  <p>
                    From research and strategy to design and implementation, we
                    partner with some of the world’s.
                  </p>
                </div>
              </div>
              <div className="item mt-30 d-block d-sm-flex">
                <div className="thumb">
                  <img
                    src={
                      require(`../../assets/images/article-thumb-3.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="content">
                  <a href="#">
                    <h4 className="title">
                      Miami woman deliver her powerful winds kept help from
                      nuture…
                    </h4>
                  </a>
                  <p>
                    From research and strategy to design and implementation, we
                    partner with some of the world’s.
                  </p>
                </div>
              </div>
              <div className="item mt-30 d-block d-sm-flex">
                <div className="thumb">
                  <img
                    src={
                      require(`../../assets/images/article-thumb-4.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="content">
                  <a href="#">
                    <h4 className="title">
                      Miami woman deliver her powerful winds kept help from
                      nuture…
                    </h4>
                  </a>
                  <p>
                    From research and strategy to design and implementation, we
                    partner with some of the world’s.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Article;
