import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import PortfolioTwo from "../../HomeTwo/Portfolio";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function Portfolio() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Portfolio Four"
        breadcrumb={["Home", "Portfolio One"]}
      />
      <PortfolioTwo />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default Portfolio;
