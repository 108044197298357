import React from "react";

function About({ className, contentStyle }) {
  return (
    <section className={`about-6-area ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className={`about-6-content ${contentStyle || ""}`}>
              <span>About us</span>
              <h3 className="title">We create mind blowing </h3>
              <div className="text">
                <p>
                  Sitting proudly atop is the two-storey penthouse. The nati
                  master bedroom suite is phenomenally spacious makes the most
                  of a its unrivalled position.
                </p>
              </div>
              <ul>
                <li>Graphic design template</li>
                <li>Motion ui design</li>
                <li>Theme template design</li>
                <li>User interface design</li>
              </ul>
              <a className="main-btn" href="/about-us-one">
                Learn more
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-6-thumb animated wow fadeInRight"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <img
                src={require(`../../assets/images/about-6-thumb.jpg`).default}
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
