import React from "react";
import data from "../../Data/HomeOne";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Project from "../../HomeFive/Project";
import About from "../../HomeOne/About";
import Team from "../../HomeOne/Team";
import Testimonial from "../../HomeOne/Testimonial";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function AboutOne() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="About Us" breadcrumb={["Home", "About One"]} />
      <About className="pt-100" />
      <Project />
      <Testimonial />
      <Team datas={data.teamMembers} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default AboutOne;
