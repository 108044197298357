import React from "react";

function FooterTwo(props) {
  const { className, children, subScribeStyle } = props;
  return (
    <footer className={`footer-area footer-2-area ${className || ""}`}>
      {children && children}
      <div className="container">
        <div className="footer-item">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-list mt-30">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <a href="/about-us-one">About</a>
                  </li>
                  <li>
                    <a href="/service-one">Services</a>
                  </li>
                  <li>
                    <a href="/blog-two">Our shop</a>
                  </li>
                  <li>
                    <a href="/portfolio-details-one">Details Portfolio</a>
                  </li>
                  <li>
                    <a href="#">Calendar of events</a>
                  </li>
                  <li>
                    <a href="#">Location</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-list mt-30">
                <h4 className="title">Additional links</h4>
                <ul>
                  <li>
                    <a href="/about-us-two">About us</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/">Privacy policy</a>
                  </li>
                  <li>
                    <a href="/single-post">News</a>
                  </li>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-5">
              <div className="footer-list mt-30">
                <h4 className="title">Categories</h4>
                <ul>
                  <li>
                    <a href="/blog-two">Blogs</a>
                  </li>
                  <li>
                    <a href="/single-post">Recent Post</a>
                  </li>
                  <li>
                    <a href="/portfolio-five">Portfolio</a>
                  </li>
                  <li>
                    <a href="/contact">Support</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-7">
              <div
                className={`footer-newsletter-item ${
                  subScribeStyle || ""
                } mt-30`}
              >
                <div className="dot">
                  <img
                    src={
                      require(`../../../assets/images/icon/footer-newsletter-dot.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <h3 className="title">
                  Subscribe to our newsletter to receive exclusive offers.
                </h3>
                <form action="#">
                  <div className="input-box">
                    <input type="text" placeholder="Enter email…" />
                    <button className="main-btn-3" type="button">
                      Join us
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-copyright d-flex align-items-end">
              <ul>
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
              <p>Copyright @ 2020. Design by Seative.Digital</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterTwo;
