import React from "react";

function Pricing({ className }) {
  return (
    <section className={`pricing-3-area ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="pricing-3-content">
              <h3 className="title">
                Simple pricing for advanced app UI, UX design.
              </h3>
              <p>
                Over 30% of app fail because they are designed by stakeholders
                for stakeholders through an “opinion-driven process”.
              </p>
              <p className="text">
                We begin each project with quantitative and qualitative research
                to remove guesswork and learn exactly what your users want.
                After all, we can’t help you accomplish your goals without
                helping.
              </p>
              <div className="info">
                <ul>
                  <li>
                    <i className="fas fa-paper-plane"></i>
                    hello@brandingname.com
                  </li>
                  <li>
                    <i className="fas fa-phone"></i> +7 964 523 79 28
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="pricing-3-box animated wow fadeInRight"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <h3 className="title">Pricing</h3>
              <div className="text">
                <p>
                  Brand is a collective of young, up coming latral thinkers who
                  would describe
                </p>
              </div>
              <ul>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    UX Research
                  </span>
                  <span>Free</span>
                </li>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    Wireframe Design
                  </span>
                  <span>Free</span>
                </li>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    Prototype
                  </span>
                  <span>$86</span>
                </li>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    App Design (Per screen)
                  </span>
                  <span>$70</span>
                </li>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    Modal Screens (Per screen)
                  </span>
                  <span>$50</span>
                </li>
                <li>
                  <span>
                    <img
                      src={
                        require(`../../assets/images/pricing-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                    Revisions (Per revisions)
                  </span>
                  <span>$30</span>
                </li>
              </ul>
              <a className="main-btn" href="/contact">
                Free consultation
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
