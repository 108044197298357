import React from "react";

function FunFact() {
  return (
    <section className="fun-facts-3-area pt-50 pb-80">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0ms"
            >
              <img
                src={
                  require(`../../assets/images/icon/fun-facts-1.png`).default
                }
                alt=""
              />
              <h3 className="title count">63</h3>
              <span>Years of experience</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <img
                src={
                  require(`../../assets/images/icon/fun-facts-2.png`).default
                }
                alt=""
              />
              <h3 className="title count">27</h3>
              <span>Awards won</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <img
                src={
                  require(`../../assets/images/icon/fun-facts-3.png`).default
                }
                alt=""
              />
              <h3 className="title count">79</h3>
              <span>Project taken</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="900ms"
            >
              <img
                src={
                  require(`../../assets/images/icon/fun-facts-4.png`).default
                }
                alt=""
              />
              <h3 className="title">
                <span className="count">32</span>K
              </h3>
              <span>Twitter followers</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FunFact;
