import React from "react";

function CardStyleTwo({ datas = [], className = "" }) {
  return (
    <div
      className={`article-2-item mt-30 animated wow fadeInLeft ${
        className || ""
      }`}
      data-wow-duration="1000ms"
      data-wow-delay="0ms"
    >
      <div className="article-thumb">
        <img src={require(`../../assets/images/${datas.img}`).default} alt="" />
      </div>
      <div className="article-content">
        <div className="date">
          <div className="item">
            <a href="/single-post">
              <h5 className="title">{datas.day}</h5>
            </a>
            <span>{datas.month}</span>
          </div>
          <span>{datas.category}</span>
        </div>
        <h3 className="title">
          <a href="/single-post">{datas.heading}</a>
        </h3>
        <a href="/single-post">
          Learn more
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.196"
              height="12.663"
              viewBox="0 0 10.196 12.663"
            >
              <path
                data-name="Path 18155"
                d="M32.324,974.539,28.2,978.448a.731.731,0,0,0-.052.947.678.678,0,0,0,.948,0l3.027-2.864v9.346a.652.652,0,1,0,1.3,0v-9.346l3.027,2.864a.664.664,0,0,0,.948,0,.714.714,0,0,0-.052-.947l-4.126-3.909A.661.661,0,0,0,32.324,974.539Z"
                transform="translate(-27.676 -974.112)"
                fill="#fff"
                stroke="#fff"
                strokeWidth="0.5"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  );
}

export default CardStyleTwo;
