import React from "react";
import CardStyleOne from "../../Cards/CardStyleOne";

function Post({ datas }) {
  return (
    <>
      <section className="article-page-area pt-70 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            {datas &&
              datas.length > 0 &&
              datas.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-sm-9">
                  <CardStyleOne datas={item} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Post;
