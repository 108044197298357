import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import About from "../../HomeFive/About";
import Services from "../../HomeFive/Services";
import Testimonial from "../../HomeOne/Testimonial";
import Team from "../../HomeTwo/Team";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function AboutThree() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="About us three "
        breadcrumb={["Home", "About Three"]}
      />
      <About />
      <Services />
      <Testimonial
        className="about-testimonial-area testimonial-5-area"
        itemStyle="testimonial-5-item"
      />
      <Team />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default AboutThree;
