import React from "react";
import SliderCom from "../Helper/SliderCom";

function Testimonial({
  className,
  direction,
  itemStyle,
  thumbStyle,
  quoteImg = "quote.png",
}) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };
  return (
    <section className="testimonial-3-area">
      <div className="container">
        <div className={`testimonial-3-active ${className || ""}`}>
          {direction === "rtl" ? (
            <SliderCom settings={settings}>
              <div className={`testimonial-items ${itemStyle || ""}`}>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className={`testimonial-3-thumb ${thumbStyle || ""}`}>
                      <img
                        src={
                          require(`../../assets/images/testimonial-item-2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="testimonial-3-content">
                      <h3 className="title">
                        What our clients say <br /> about our studio.
                      </h3>
                      <img
                        src={
                          require(`../../assets/images/icon/${quoteImg}`)
                            .default
                        }
                        alt=""
                      />
                      <p>
                        They are designed by Micro-interaction is the visual
                        translation of what the user is doing (scrolling,
                        changing pages, clicking a button, etc.) in the product
                        context. It is the detail that will create emotion in
                        the user , improve his experience by bringing more life
                        to the interfaces. After quantitative and qualitative
                        research to remove guesswork and learn exactly what your
                        users want.
                      </p>
                      <div className="info">
                        <h5 className="title">Katja Westphal</h5>
                        <span>Co-Founder & CEO, Seative Digital</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`testimonial-items ${itemStyle || ""}`}>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className={`testimonial-3-thumb ${thumbStyle || ""}`}>
                      <img
                        src={
                          require(`../../assets/images/testimonial-item-1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="testimonial-3-content">
                      <h3 className="title">
                        What our clients say <br /> about our studio.
                      </h3>
                      <img
                        src={
                          require(`../../assets/images/icon/${quoteImg}`)
                            .default
                        }
                        alt=""
                      />
                      <p>
                        They are designed by Micro-interaction is the visual
                        translation of what the user is doing (scrolling,
                        changing pages, clicking a button, etc.) in the product
                        context. It is the detail that will create emotion in
                        the user , improve his experience by bringing more life
                        to the interfaces. After quantitative and qualitative
                        research to remove guesswork and learn exactly what your
                        users want.
                      </p>
                      <div className="info">
                        <h5 className="title">Katja Westphal</h5>
                        <span>Co-Founder & CEO, Seative Digital</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SliderCom>
          ) : (
            <SliderCom settings={settings}>
              <div className={`testimonial-items ${itemStyle || ""}`}>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="testimonial-3-content">
                      <h3 className="title">
                        What our clients say <br /> about our studio.
                      </h3>
                      <img
                        src={
                          require(`../../assets/images/icon/${quoteImg}`)
                            .default
                        }
                        alt=""
                      />
                      <p>
                        They are designed by Micro-interaction is the visual
                        translation of what the user is doing (scrolling,
                        changing pages, clicking a button, etc.) in the product
                        context. It is the detail that will create emotion in
                        the user , improve his experience by bringing more life
                        to the interfaces. After quantitative and qualitative
                        research to remove guesswork and learn exactly what your
                        users want.
                      </p>
                      <div className="info">
                        <h5 className="title">Katja Westphal</h5>
                        <span>Co-Founder & CEO, Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={`testimonial-3-thumb ${thumbStyle || ""}`}>
                      <img
                        src={
                          require(`../../assets/images/testimonial-item-1.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`testimonial-items ${itemStyle || ""}`}>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="testimonial-3-content">
                      <h3 className="title">
                        What our clients say <br /> about our studio.
                      </h3>
                      <img
                        src={
                          require(`../../assets/images/icon/${quoteImg}`)
                            .default
                        }
                        alt=""
                      />
                      <p>
                        They are designed by Micro-interaction is the visual
                        translation of what the user is doing (scrolling,
                        changing pages, clicking a button, etc.) in the product
                        context. It is the detail that will create emotion in
                        the user , improve his experience by bringing more life
                        to the interfaces. After quantitative and qualitative
                        research to remove guesswork and learn exactly what your
                        users want.
                      </p>
                      <div className="info">
                        <h5 className="title">Katja Westphal</h5>
                        <span>Co-Founder & CEO, Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={`testimonial-3-thumb ${thumbStyle || ""}`}>
                      <img
                        src={
                          require(`../../assets/images/testimonial-item-2.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SliderCom>
          )}
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
