import React from "react";
import data from "../Data/HomeFour";
import Navigations from "../Data/Navigation";
import BackToTop from "../Helper/BackToTop";
import Drawer from "../Helper/Drawer";
import Services from "../HomeThree/Services";
import Sponsers from "../HomeThree/Sponsers";
import Testimonial from "../HomeThree/Testimonial";
import ArticleSection from "../HomeTwo/ArticleSection";
import useToggle from "../Hooks/useToggle";
import FooterFour from "../Partials/Footers/FooterFour";
import HeaderThree from "../Partials/Headers/HeaderThree";
import About from "./About";
import FunFact from "./FunFact";
import Hero from "./Hero";
import Project from "./Project";
import SubscribeArea from "./SubscribeArea";

function HomeFour() {
  const [toggle, setValue] = useToggle(false);
  return (
    <>
      <Drawer
        className="offcanvas_menu_4"
        toggle={toggle}
        action={setValue.toggle}
        navItem={Navigations}
      />
      <HeaderThree className="header-4-area" action={setValue.toggle} />
      <Hero />
      <FunFact />
      <About datas={data.skills}>
        <div
          className="about-4-thumb animated wow fadeInLeft"
          data-wow-duration="3000ms"
          data-wow-delay="0ms"
        >
          <img
            src={require(`../../assets/images/about-4-thumb.png`).default}
            alt=""
          />
        </div>
      </About>
      <Services className="our-services-area our-services-4-area" />
      <Project />
      <Testimonial />
      <Sponsers />
      <ArticleSection
        cardStyleClasses="article-4-item"
        className="artical-4-area"
        datas={data.article}
      />
      <SubscribeArea />
      <FooterFour />
      <BackToTop className="back-to-top-4" />
    </>
  );
}

export default HomeFour;
