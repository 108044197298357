import React from "react";
import CommonHero from "../../Helper/CommonHero";
import About from "../../HomeTwo/About";
import Services from "../../HomeTwo/Services";
import Team from "../../HomeTwo/Team";
import Testimonial from "../../HomeTwo/Testimonial";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function AboutTwo() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="About Us two" breadcrumb={["Home", "About two"]} />
      <About />
      <Services />
      <Testimonial />
      <Team />
      <FooterOne />
    </>
  );
}

export default AboutTwo;
