import React, { useEffect, useState } from "react";
import StickyMenu from "../../../Lib/StickyMenu";
import Navigation from "../../Helper/Navigation";

function HeaderTwo({ className, logo = "logo-2.png", primaryBtnContent }) {
  const [toggleMenu, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!toggleMenu);
  };
  useEffect(() => {
    StickyMenu(".header-nav");
  });
  return (
    <header className={`header-area header-2-area ${className || ""}`}>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-top-item">
                <div className="info">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/phone.svg`).default
                        }
                        alt=""
                      />
                      504-882-0554
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/email.svg`).default
                        }
                        alt=""
                      />
                      contact@pharma.com
                    </li>
                  </ul>
                </div>
                <div className="sicial">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="navigation">
                <nav className="navbar navbar-expand-lg navbar-light ">
                  <a className="navbar-brand" href="/">
                    <img
                      src={require(`../../../assets/images/${logo}`).default}
                      alt=""
                    />
                  </a>

                  <button
                    className={`navbar-toggler ${toggleMenu ? "active" : ""}`}
                    type="button"
                    onClick={(e) => toggleHandler(e)}
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className={`collapse navbar-collapse sub-menu-bar ${
                      toggleMenu ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <Navigation />
                  </div>

                  <div className="navbar-btn d-none d-sm-block">
                    <a className="main-btn main-btn-3" href="#">
                      {primaryBtnContent || "Get Started"}
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderTwo;
