import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Project from "../../HomeFour/Project";
import Testimonial from "../../HomeTwo/Testimonial";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import "./index.css";
import PricingGrid from "./PricingGrid";

function PricingPage() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Pricing two" breadcrumb={["Home", "Pricing two"]} />
      <PricingGrid />
      <Project className="portfolio-style-2" />
      <Testimonial />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PricingPage;
