const data = {
  services: [
    {
      id: Math.random(),
      name: "Business Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "king",
    },
    {
      id: Math.random(),
      name: "UX & Product Design",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "geo-love",
    },
    {
      id: Math.random(),
      name: "App Development",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "app-dev",
    },
    {
      id: Math.random(),
      name: "Marketing Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "brain",
    },
    {
      id: Math.random(),
      name: "Digital Marketing",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "cursor-drop",
    },
    {
      id: Math.random(),
      name: "Keyword Research",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "eye",
    },
  ],
  homeEightServices: [
    {
      id: Math.random(),
      name: "Business Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "king_two",
    },
    {
      id: Math.random(),
      name: "UX & Product Design",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "geo-love_two",
    },
    {
      id: Math.random(),
      name: "App Development",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "app-dev_two",
    },
    {
      id: Math.random(),
      name: "Marketing Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "brain_two",
    },
    {
      id: Math.random(),
      name: "Digital Marketing",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "cursor-drop_two",
    },
    {
      id: Math.random(),
      name: "Keyword Research",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "eye_two",
    },
  ],
  homeNineServices: [
    {
      id: Math.random(),
      name: "Business Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "king_three",
    },
    {
      id: Math.random(),
      name: "UX & Product Design",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "geo-love_three",
    },
    {
      id: Math.random(),
      name: "App Development",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "app-dev_three",
    },
    {
      id: Math.random(),
      name: "Marketing Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "brain_two",
    },
    {
      id: Math.random(),
      name: "Digital Marketing",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "cursor-drop_two",
    },
    {
      id: Math.random(),
      name: "Keyword Research",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "eye_two",
    },
  ],
  homeFifteenServices: [
    {
      id: Math.random(),
      name: "Business Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "king_four",
    },
    {
      id: Math.random(),
      name: "UX & Product Design",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "geo-love_four",
    },
    {
      id: Math.random(),
      name: "App Development",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "app-dev_four",
    },
    {
      id: Math.random(),
      name: "Marketing Stratagy",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "brain_three",
    },
    {
      id: Math.random(),
      name: "Digital Marketing",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "cursor-drop_three",
    },
    {
      id: Math.random(),
      name: "Keyword Research",
      description:
        "Sitting proudly atop is the storey penthouse. The natty master suite bedroom phenomenally.",
      icon: "eye_three",
    },
  ],
};
export default data;
