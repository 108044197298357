import React from "react";

function ArticleSection({ datas }) {
  return (
    <section className="article-post-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="article-post-title text-center">
              <h3 className="title">Related product</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {datas &&
            datas.length > 0 &&
            datas.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6">
                <div className="portfolio-style-4-item mt-30">
                  <div className="portfolio-thumb">
                    <img
                      src={require(`../assets/images/${item.image}`).default}
                      alt=""
                    />
                    <a href="#">
                      See details
                      <span>
                        <img
                          src={
                            require(`../assets/images/icon/portfolio-arrow-2.png`)
                              .default
                          }
                          alt=""
                        />
                      </span>
                    </a>
                  </div>
                  <div className="portfolio-content">
                    <h5 className="title">{item.title}</h5>
                    <span>{item.category}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ArticleSection;
