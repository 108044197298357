import React, { useEffect, useState } from "react";

function About(props) {
  const { datas, children, mainSectionStyle } = props;
  const [anim, setValue] = useState(false);
  useEffect(() => {
    const rec = document.getElementById("about-four");
    if (rec) {
      const currentPosition = rec.offsetTop - document.body.scrollTop;
      window.addEventListener("scroll", () => {
        const currentScrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollPosition + 500 > currentPosition) {
          setValue(true);
        }
      });
    }
  });
  return (
    <section className="about-4-area pb-100 pt-65" id="about-four">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">{children && children}</div>
          <div className="col-lg-6">
            <div className={`about-4-content ${mainSectionStyle || ""}`}>
              <h3 className="title">About Me</h3>
              <p>
                I help agencies & brands like Google, Volvo and others to turn
                their ideas into designs. Great designs and a healthy freelance
                relationship. <span>Avery Ella</span>
              </p>
              <div className="skills-items">
                <div className="skills">
                  {datas &&
                    datas.length > 0 &&
                    datas.map((item) => (
                      <div key={item.id} className="skill-item">
                        <div className="skill-header">
                          <h3 className="skill-title">{item.name}</h3>
                          <div className="skill-percentage">
                            <div className="count-box">
                              (
                              <span
                                className="count-text"
                                data-speed="2000"
                                data-stop="90"
                              >
                                {item.percentage}
                              </span>
                              %)
                            </div>
                          </div>
                        </div>
                        <div className="skill-bar">
                          <div className="bar-inner">
                            <div
                              className="bar progress-line"
                              style={{
                                width: anim ? `${item.percentage}%` : "0",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
