import { Route, Routes } from "react-router-dom";
import AboutOne from "./Components/AboutPages/AboutOne/index";
import AboutThree from "./Components/AboutPages/AboutThree/index";
import AboutTwo from "./Components/AboutPages/AboutTwo/index";
import BlogOne from "./Components/BlogPages/BlogOne/index";
import BlogTwo from "./Components/BlogPages/BlogTwo/index";
import SinglePost from "./Components/BlogPages/SinglePost/index";
import Contact from "./Components/Contact/index";
import HomeFive from "./Components/HomeFive/index";
import HomeFour from "./Components/HomeFour/index";
import HomeOne from "./Components/HomeOne/index";
import HomeSeven from "./Components/HomeSeven/index";
import HomeSix from "./Components/HomeSix/index";
import HomeThree from "./Components/HomeThree/index";
import HomeTwo from "./Components/HomeTwo/index";
import PortfolioDetailPageOne from "./Components/PortfolioPages/PortfolioDetailPageOne/index";
import PortfolioDetailPageTwo from "./Components/PortfolioPages/PortfolioDetailsPageTwo/index";
import PortfolioFive from "./Components/PortfolioPages/PortfolioFive/index";
import PortfolioFour from "./Components/PortfolioPages/PortfolioFour/index";
import PortfolioOne from "./Components/PortfolioPages/PortfolioOne/index";
import PortfolioThree from "./Components/PortfolioPages/PortfolioThree/index";
import PortfolioTwo from "./Components/PortfolioPages/PortfolioTwo/index";
import PricingPageOne from "./Components/PricingPages/PricingPageOne/index";
import PricingPageThree from "./Components/PricingPages/PricingPageThree/index";
import PricingPageTwo from "./Components/PricingPages/PricingPageTwo/index";
import ServiceOne from "./Components/ServicePages/ServiceOne/index";
import ServiceThree from "./Components/ServicePages/ServiceThree/index";
import ServiceTwo from "./Components/ServicePages/ServiceTwo/index";
import ScrollToTop from "./Components/Helper/ScrollToTop";

function Routers() {
  return (
    <>
      <ScrollToTop/>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/home-two" element={<HomeTwo />} />
        <Route exact path="/home-three" element={<HomeThree />} />
        <Route exact path="/home-four" element={<HomeFour />} />
        <Route exact path="/home-five" element={<HomeFive />} />
        <Route exact path="/home-Six" element={<HomeSix />} />
        <Route exact path="/home-seven" element={<HomeSeven />} />
        <Route exact path="/about-us-one" element={<AboutOne />} />
        <Route exact path="/about-us-two" element={<AboutTwo />} />
        <Route exact path="/about-us-three" element={<AboutThree />} />
        <Route exact path="/service-one" element={<ServiceOne />} />
        <Route exact path="/service-two" element={<ServiceTwo />} />
        <Route exact path="/service-three" element={<ServiceThree />} />
        <Route exact path="/portfolio-one" element={<PortfolioOne />} />
        <Route exact path="/portfolio-two" element={<PortfolioTwo />} />
        <Route exact path="/portfolio-three" element={<PortfolioThree />} />
        <Route exact path="/portfolio-four" element={<PortfolioFour />} />
        <Route exact path="/portfolio-five" element={<PortfolioFive />} />
        <Route
          exact
          path="/portfolio-details-one"
          element={<PortfolioDetailPageOne />}
        />
        <Route
          exact
          path="/portfolio-details-two"
          element={<PortfolioDetailPageTwo />}
        />
        <Route exact path="/blog-one" element={<BlogOne />} />
        <Route exact path="/blog-two" element={<BlogTwo />} />
        <Route exact path="/single-post" element={<SinglePost />} />
        <Route exact path="/pricing-one" element={<PricingPageOne />} />
        <Route exact path="/pricing-two" element={<PricingPageTwo />} />
        <Route exact path="/pricing-three" element={<PricingPageThree />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}
export default Routers;
