import React from "react";
import SimpleSlider from "../../Helper/SliderCom";

function ClientArea() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="client-area">
        <div className="section__bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="client-slide">
                <SimpleSlider settings={settings}>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img
                      src={
                        require(`../../../assets/images/icon/quote-icon.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="content">
                      <p>
                        I must explain to you how all this mistake idea
                        denouncing pleasure & praising pain was born and I will
                        give you a complete account of the system, and expound
                        the actual teachings of the great explorer of the truth,
                        the master-builder of human happiness.
                      </p>
                      <div className="info">
                        <img
                          src={
                            require(`../../../assets/images/post-news-1.png`)
                              .default
                          }
                          alt=""
                        />
                        <h5 className="title">Subash Chandra</h5>
                        <span>Founder at Seative Digital</span>
                      </div>
                    </div>
                  </div>
                </SimpleSlider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details-content mt-65">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="text">
                      <p>
                        New York saw its highest single-day increase in deaths,
                        up 562 to 2,935 - nearly half of all virus-related US
                        deaths recorded yesterday. The White House may advise
                        those in virus hotspots to wear face coverings in public
                        to help stem the spread mid deepening crisis, top health
                        official Dr Anthony Fauci has said he believes all
                        states should issue stay-at-home orders.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="text">
                      <p>
                        I don’t understand why that’s not happening,” Dr Fauci
                        told CNN on Thursday. “If you look at what’s going on in
                        this country, I just don’t understand why we’re not
                        doing that.”
                      </p>
                    </div>
                    <div className="text pt-15">
                      <p>
                        You’ve got to put your foot on the accelerator to bring
                        that number down,” he added, referring to infection and
                        death rates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post-next">
                <div className="row justify-content-between">
                  <div className="col-lg-4 col-md-6">
                    <div className="prev-post">
                      <span>PREVIOUS NEWS</span>
                      <a href="#">
                        <p>
                          Kushner puts himself in middle of white house’s chaoti
                          coronavirus response.
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="prev-post">
                      <span>NEXT NEWS</span>
                      <a href="#">
                        <p>
                          C.I.A. Hunts for authentic virus totals in china,
                          dismissing government tallies
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientArea;
