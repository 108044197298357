import React from "react";

function Sponser() {
  return (
    <div className="brand-3-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="brand-3-items d-flex justify-content-between">
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-1.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-2.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-3.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-4.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-5.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="brand-3-items d-flex justify-content-between mt-85">
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-5.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-4.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-3.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-2.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="item">
                <a href="#">
                  <img
                    src={
                      require(`../../assets/images/brand/brand-item-1.png`)
                        .default
                    }
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponser;
