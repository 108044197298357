import React, { useRef } from "react";
import SliderCom from "../Helper/SliderCom";

function Testimonial({
  className,
  activeStyle,
  itemStyle,
  shape = "hero-line.png",
}) {
  const testimonialSlider = useRef();
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className={`testimonial-area ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title section-title-2 text-center">
              <img
                src={require(`../../assets/images/${shape}`).default}
                alt=""
              />
              <h3 className="title">Testimonial</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className={`testimonial-active ${activeStyle || ""}`}>
          <SliderCom selector={testimonialSlider} settings={settings}>
            <div className="testimonial-item-padding">
              <div className={`testimonial-item ${itemStyle || ""} mt-30`}>
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53.729"
                    height="40"
                    viewBox="0 0 53.729 40"
                  >
                    <g data-name="Group 21" transform="translate(0 0)">
                      <path
                        data-name="Union 1"
                        d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </div>
                <p>
                  There are many variations from into passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don’t look
                  even slightly believable.
                </p>
                <div className="info">
                  <img
                    src={
                      require(`../../assets/images/testimonial-user.png`)
                        .default
                    }
                    alt=""
                  />
                  <h5 className="title">Abdur Rohman</h5>
                  <span>Sr. Product designer</span>
                </div>
              </div>
            </div>
            <div className="testimonial-item-padding">
              <div className={`testimonial-item ${itemStyle || ""} mt-30`}>
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53.729"
                    height="40"
                    viewBox="0 0 53.729 40"
                  >
                    <g data-name="Group 21" transform="translate(0 0)">
                      <path
                        data-name="Union 1"
                        d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </div>
                <p>
                  There are many variations from into passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don’t look
                  even slightly believable.
                </p>
                <div className="info">
                  <img
                    src={
                      require(`../../assets/images/testimonial-user.png`)
                        .default
                    }
                    alt=""
                  />
                  <h5 className="title">Mehedi Hasan</h5>
                  <span>Sr. Product designer</span>
                </div>
              </div>
            </div>
            <div className="testimonial-item-padding">
              <div className={`testimonial-item ${itemStyle || ""} mt-30`}>
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53.729"
                    height="40"
                    viewBox="0 0 53.729 40"
                  >
                    <g data-name="Group 21" transform="translate(0 0)">
                      <path
                        data-name="Union 1"
                        d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </div>
                <p>
                  There are many variations from into passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don’t look
                  even slightly believable.
                </p>
                <div className="info">
                  <img
                    src={
                      require(`../../assets/images/testimonial-user.png`)
                        .default
                    }
                    alt=""
                  />
                  <h5 className="title">Mosharof Hossain</h5>
                  <span>Sr. Product designer</span>
                </div>
              </div>
            </div>
            <div className="testimonial-item-padding">
              <div className={`testimonial-item ${itemStyle || ""} mt-30`}>
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53.729"
                    height="40"
                    viewBox="0 0 53.729 40"
                  >
                    <g data-name="Group 21" transform="translate(0 0)">
                      <path
                        data-name="Union 1"
                        d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </div>
                <p>
                  There are many variations from into passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don’t look
                  even slightly believable.
                </p>
                <div className="info">
                  <img
                    src={
                      require(`../../assets/images/testimonial-user.png`)
                        .default
                    }
                    alt=""
                  />
                  <h5 className="title">Rayhan Uddin</h5>
                  <span>Sr. Product designer</span>
                </div>
              </div>
            </div>
            <div className="testimonial-item-padding">
              <div className={`testimonial-item ${itemStyle || ""} mt-30`}>
                <div className="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="53.729"
                    height="40"
                    viewBox="0 0 53.729 40"
                  >
                    <g data-name="Group 21" transform="translate(0 0)">
                      <path
                        data-name="Union 1"
                        d="M47.055,40a1.21,1.21,0,0,1-1.018-.509L31.106,19.357A12.178,12.178,0,0,1,29.07,11.1,12.364,12.364,0,1,1,45.98,23.881l6.957,14.253A1.313,1.313,0,0,1,51.806,40ZM18.1,40a1.209,1.209,0,0,1-1.018-.509L2.149,19.357A12.77,12.77,0,0,1,.056,11.043,12.395,12.395,0,1,1,17.023,23.881L23.98,38.134A1.313,1.313,0,0,1,22.849,40Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                </div>
                <p>
                  There are many variations from into passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don’t look
                  even slightly believable.
                </p>
                <div className="info">
                  <img
                    src={
                      require(`../../assets/images/testimonial-user.png`)
                        .default
                    }
                    alt=""
                  />
                  <h5 className="title">Imran Ahmed</h5>
                  <span>Sr. Product designer</span>
                </div>
              </div>
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
