import React from "react";

function CardStyleOne({ datas, className }) {
  return (
    <div className={`article-item mt-30 ${className || ""}`}>
      <div className="article-top text-center">
        <a href="#">
          <span>{datas.heading}</span>
        </a>
      </div>
      <div className="article-thumb">
        <a href="#">
          <img
            src={require(`../../assets/images/${datas.img}`).default}
            alt=""
          />
        </a>
        <div className="date">
          <h5 className="title">{datas.day}</h5>
          <span style={{ textTransform: "uppercase" }}>{datas.month}</span>
        </div>
      </div>
      <div className="article-content pl-25 pr-25 pt-25">
        <p>{datas.article}</p>
        <a href="/single-post">READ MORE</a>
      </div>
    </div>
  );
}

export default CardStyleOne;
