import React from "react";
import Data from "../Data/HomeTwo";
import NavItem from "../Data/Navigation";
import data from "../Data/Services";
import BackToTop from "../Helper/BackToTop";
import Drawer from "../Helper/Drawer";
import VideoPopUp from "../Helper/VideoPopUp";
import Pricing from "../HomeOne/Pricing";
import Services from "../HomeOne/Services";
import useToggle from "../Hooks/useToggle";
import FooterTwo from "../Partials/Footers/FooterTwo";
import HeaderSeven from "../Partials/Headers/HeaderSeven";
import ArticleArea from "./ArticleArea";
import DownloadArea from "./DownloadArea";
import FunFact from "./FunFact";
import Hero from "./Hero";
import KeyFeatureArea from "./KeyFeatureArea";
import ScreenshotArea from "./ScreenshotArea";
import VideoSection from "./VideoSection";

function HomeSeven() {
  const [open, setValue] = useToggle(false);
  const [openVideo, setVideo] = useToggle(false);
  return (
    <>
      {openVideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={setVideo.toggle}
        />
      )}
      <Drawer
        action={setValue.toggle}
        toggle={open}
        navItem={NavItem}
        className="offcanvas_menu_7"
      />
      <HeaderSeven action={setValue.toggle} />
      <Hero />
      <Services
        limit="3"
        className="service-7-area pb-85"
        shape="hero-line-4.png"
        services={data.services}
        cardStyle="service-7-item"
      />
      <KeyFeatureArea />
      <VideoSection action={setVideo.toggle} />
      <ScreenshotArea />
      <DownloadArea />
      <Pricing className="pricing-7-area" shape="hero-line-4.png" />
      <FunFact />
      <ArticleArea datas={Data.article} />
      <FooterTwo className="footer-7-area" />
      <BackToTop className="back-to-top-7" />
    </>
  );
}

export default HomeSeven;
