import React from "react";
import PricingCardStyleOne from "../Cards/PricingCardStyleOne";

function Pricing({ className, cardStyle, titleAreaStyle }) {
  return (
    <section className={`pricing-2-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              className={`section-title-3 section-title-4 text-center ${
                titleAreaStyle || ""
              }`}
            >
              <span>Our Pricing</span>
              <h3 className="title">Our Pricing</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <PricingCardStyleOne className={cardStyle || ""} />
          </div>
          <div className="col-lg-6 col-md-9">
            <PricingCardStyleOne className={cardStyle || ""} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
