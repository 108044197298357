import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import MainSection from "./MainSection";

function PortfolioOne() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Portfolioi One"
        breadcrumb={["Home", "Portfolio One"]}
      />
      <MainSection />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PortfolioOne;
