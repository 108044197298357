import React, { useEffect } from "react";
import initMasonry from "../../Lib/MasonryCss";

function Project({ children }) {
  useEffect(() => {
    setTimeout(() => {
      initMasonry("grid", "grid-item");
    }, 500);
  });

  return (
    <section className="project-5-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="project-title text-center">
              {children && children}
              <h3 className="title">Our Project</h3>
            </div>
          </div>
        </div>
        <div className="row grid">
          <div className="col-lg-6 grid-item">
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-1.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product Design</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-2.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-3.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product </h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-4.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product </h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-5.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product Design</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="project-5-item mt-30">
                  <img
                    src={
                      require(`../../assets/images/project/project-6.png`)
                        .default
                    }
                    alt=""
                  />
                  <div className="project-overlay">
                    <h3 className="title">Digital Product</h3>
                    <span>Artist, Illustration</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="project-5-item mt-30">
                  <img
                    src={
                      require(`../../assets/images/project/project-7.png`)
                        .default
                    }
                    alt=""
                  />
                  <div className="project-overlay">
                    <h3 className="title">Digital Product </h3>
                    <span>Artist, Illustration</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="project-5-item mt-30">
              <img
                src={
                  require(`../../assets/images/project/project-8.png`).default
                }
                alt=""
              />
              <div className="project-overlay">
                <h3 className="title">Digital Product Design</h3>
                <span>Artist, Illustration</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
