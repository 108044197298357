import React from "react";

function OverView() {
  return (
    <section className="overview-area pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="overview-content">
              <img
                src={require(`../../assets/images/hero-line.png`).default}
                alt=""
              />
              <span>overview</span>
              <h3 className="title">About our company insight</h3>
              <p>
                Triplet I award-winning interdisciplinary architectural studio
                cultural, residential and commercial projects built worldwide.
                We pride ourselves on being builders creating architectural.
              </p>
              <a className="main-btn" href="/contact">
                Let’s Chat
              </a>
              <div className="brand-item d-flex align-items-center mt-70">
                <div className="item">
                  <a href="#">
                    <img
                      src={require(`../../assets/images/brand-1.png`).default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="#">
                    <img
                      src={require(`../../assets/images/brand-2.png`).default}
                      alt=""
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="#">
                    <img
                      src={require(`../../assets/images/brand-3.png`).default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="overview-counter-area">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div
                    className="overview-counter-item mt-10 animated wow fadeInUp"
                    data-wow-duration="3000ms"
                    data-wow-delay="0ms"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/images/overview-1.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="title count">63</h3>
                    <span>Years of experience</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="overview-counter-item mt-10 animated wow fadeInUp"
                    data-wow-duration="3000ms"
                    data-wow-delay="300ms"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/images/overview-2.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="title count">79</h3>
                    <span>Project taken</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="overview-counter-item mt-30 animated wow fadeInUp"
                    data-wow-duration="3000ms"
                    data-wow-delay="600ms"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/images/overview-3.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="title count">27</h3>
                    <span>Awards won</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div
                    className="overview-counter-item mt-30 animated wow fadeInUp"
                    data-wow-duration="3000ms"
                    data-wow-delay="900ms"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/images/overview-4.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <h3 className="title">
                      <span className="count">32</span>k
                    </h3>
                    <span>Twitter followers</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OverView;
