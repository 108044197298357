import React, { useState } from "react";

function About({ className, thumbStyle, contentStyle }) {
  const [tab, setTab] = useState("skill");
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className={`about-2-area about-5-area ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className={`about-2-thumb mt-30 animated wow fadeInLeft ${
                thumbStyle || ""
              }`}
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/about-thumb-4.png`).default}
                  alt=""
                />
              </div>
              <div className="thumb-2 ml-80">
                <img
                  src={require(`../../assets/images/about-thumb-5.png`).default}
                  alt=""
                />
                <div className="box">
                  <h3 className="title">
                    <span>25</span>+
                  </h3>
                  <span> Years of Experience</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className={`about-5-content ${contentStyle || ""}`}>
              <h3 className="title">About us</h3>
              <div className="text">
                <p>
                  I help agencies & brands like Google, Volvo and others to turn
                  their ideas into designs. Great designs and a healthy
                  freelance relationship. <span>Benjamin</span>
                </p>
              </div>
              <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${tab === "skill" ? "active" : ""}`}
                    onClick={(e) => tabHandler(e, "skill")}
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Skills
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${
                      tab === "experience" ? "active" : ""
                    }`}
                    onClick={(e) => tabHandler(e, "experience")}
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Experience
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${tab === "award" ? "active" : ""}`}
                    onClick={(e) => tabHandler(e, "award")}
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    Awards
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link ${
                      tab === "education" ? "active" : ""
                    }`}
                    onClick={(e) => tabHandler(e, "education")}
                    id="pills-4-tab"
                    data-toggle="pill"
                    href="#pills-4"
                    role="tab"
                    aria-controls="pills-4"
                    aria-selected="false"
                  >
                    Educations
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    tab === "skill" ? "show active" : ""
                  }`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="text">
                    <p>
                      I help agencies & brands like Google, Volvo and others to
                      turn their ideas into designs.
                    </p>
                  </div>
                  <div className="list d-sm-flex d-block">
                    <ul className="item-1">
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    tab === "experience" ? "show active" : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="text">
                    <p>
                      I help agencies & brands like Google, Volvo and others to
                      turn their ideas into designs.
                    </p>
                  </div>
                  <div className="list d-sm-flex d-block">
                    <ul className="item-1">
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    tab === "award" ? "show active" : ""
                  }`}
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="text">
                    <p>
                      I help agencies & brands like Google, Volvo and others to
                      turn their ideas into designs.
                    </p>
                  </div>
                  <div className="list d-sm-flex d-block">
                    <ul className="item-1">
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    tab === "education" ? "show active" : ""
                  }`}
                  id="pills-4"
                  role="tabpanel"
                  aria-labelledby="pills-4-tab"
                >
                  <div className="text">
                    <p>
                      I help agencies & brands like Google, Volvo and others to
                      turn their ideas into designs.
                    </p>
                  </div>
                  <div className="list d-sm-flex d-block">
                    <ul className="item-1">
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="#">Graphic design template</a>
                      </li>
                      <li>
                        <a href="#">Motion ui design</a>
                      </li>
                      <li>
                        <a href="#">Theme template design</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
