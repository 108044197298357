import React, { useState } from "react";
import SliderCom from "../Helper/SliderCom";
import VideoPopUp from "../Helper/VideoPopUp";

function Hero() {
  const [popupvideo, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!popupvideo);
  };
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    dots: true,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {popupvideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={toggleHandler}
        />
      )}
      <section className="hero-slider">
        <SliderCom settings={settings}>
          <div className="hero-area hero-2-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-2-content">
                    <span data-animation="fadeInDown" data-delay="0.5s">
                      Welcome to Aball
                    </span>
                    <h1
                      className="title"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                      Global Leader in Modern Business Innovation
                    </h1>
                    <div
                      className="play"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <a
                        className="video-popup"
                        href="#"
                        onClick={(e) => toggleHandler(e)}
                      >
                        <img
                          src={require(`../../assets/images/play.svg`).default}
                          alt=""
                        />
                      </a>
                      <p>
                        Sitting proudly atop is the two-storey penthouse. The
                        nati master bedroom suite is phenomenally spacious makes
                        the most unrivalled.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-area hero-2-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-2-content">
                    <span data-animation="fadeInDown" data-delay="0.5s">
                      Welcome to Aball
                    </span>
                    <h1
                      className="title"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                      We create mind blowing
                    </h1>
                    <div
                      className="play"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <a
                        className="video-popup"
                        href="#"
                        onClick={(e) => toggleHandler(e)}
                      >
                        <img
                          src={require(`../../assets/images/play.svg`).default}
                          alt=""
                        />
                      </a>
                      <p>
                        Sitting proudly atop is the two-storey penthouse. The
                        nati master bedroom suite is phenomenally spacious makes
                        the most unrivalled.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SliderCom>
      </section>
    </>
  );
}

export default Hero;
