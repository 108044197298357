import React from "react";

function Services() {
  return (
    <section className="service-area service-page-area pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44.286"
                  height="50"
                  viewBox="0 0 44.286 50"
                >
                  <defs>
                    <linearGradient
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Union 6"
                    d="M-1644.982,50V41.689h3.593a46.853,46.853,0,0,0,4.9-13.213h-10.016l1.524,1.523-1.289,1.288L-1650,27.561l3.728-3.728,1.289,1.289-1.532,1.532h10.38a58.26,58.26,0,0,0,.874-9.449h-3.341V12.362h3.044L-1640.629,0h8.054V3.454h1.654V0h5.215V3.454h1.654V0H-1616l-5.07,12.362h3.044V17.2h-3.341a58.245,58.245,0,0,0,.874,9.449h4.512l2.443-2.443h7.823l-3.351,3.35,3.351,3.35h-7.823l-2.434-2.435h-4.164a46.863,46.863,0,0,0,4.9,13.213h3.593V50Zm1.792-1.822h29.755V43.511h-29.755Zm3.837-6.488h22.082a49.18,49.18,0,0,1-4.688-13.213h-2.09a4.354,4.354,0,0,1-4.256,3.438,4.356,4.356,0,0,1-4.355-4.356,4.354,4.354,0,0,1,4.355-4.354,4.351,4.351,0,0,1,4.259,3.45h1.743a60.111,60.111,0,0,1-.846-9.322h-10.328A53.392,53.392,0,0,1-1639.354,41.689Zm25.044-14.128,1.527,1.527h2.667l-1.527-1.527,1.527-1.527h-2.667Zm-7.06-12.012v0h1.572V14.016h-17.033v1.535h1.573v0Zm-12.379-3.46h10.869l4.231-10.317h-3.624V5.225h-5.216V1.771h-1.653V5.225h-5.216V1.771h-3.623Z"
                    transform="translate(1650)"
                    fill="url(#linear-gradient)"
                  />
                </svg>
              </div>
              <h4 className="title">Business Stratagy</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54.772"
                  height="41"
                  viewBox="0 0 54.772 41"
                >
                  <defs>
                    <linearGradient
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Union 27"
                    d="M-1644.021,21.72a13.906,13.906,0,0,1-4.045-7.081H-1650V9.759h1.854A12.389,12.389,0,0,1-1636.063,0a12.422,12.422,0,0,1,11.476,7.757A12.425,12.425,0,0,1-1613.11,0a12.388,12.388,0,0,1,12.082,9.759h1.854v4.88h-1.933a13.908,13.908,0,0,1-4.045,7.08l-.82.814-1.16-1.15.82-.813a12.485,12.485,0,0,0,3.525-5.931h-1.307V9.759h1.385a10.749,10.749,0,0,0-10.4-8.132,10.749,10.749,0,0,0-10.4,8.132h1.384v1.955h9.323v1.172h-9.323v1.752h-4.919V12.886h-8.784V11.714h8.784V9.759h1.385a10.75,10.75,0,0,0-10.4-8.132,10.749,10.749,0,0,0-10.4,8.132h1.384v4.88h-1.307a12.481,12.481,0,0,0,3.526,5.932l18.276,18.129,9.257-9.184,1.159,1.15L-1624.587,41Zm41.567-8.708h1.64V11.386h-1.64Zm-22.953,0h1.64V11.386h-1.64Zm-22.953,0h1.64V11.386h-1.64Zm39.023,25.872-6.8-18.3,18.445,6.741,2.46,1.016-10.649,2.419-2.694,10.172Zm.543-3.244,1.567-6.219,6.268-1.555-12.407-4.536ZM-1625.2,32.8V30.457h1.771V32.8Zm0-4.686V25.771h1.771v2.343Zm0-5.271V21.086h1.771v1.757Z"
                    transform="translate(1650)"
                    fill="url(#linear-gradient)"
                  />
                </svg>
              </div>
              <h4 className="title">UX & Product Design</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44.34"
                  height="39"
                  viewBox="0 0 44.34 39"
                >
                  <defs>
                    <linearGradient
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <path
                    data-name="Union 24"
                    d="M-1645.085,39A4.913,4.913,0,0,1-1650,34.1V4.9a4.913,4.913,0,0,1,4.915-4.9h34.509a4.913,4.913,0,0,1,4.915,4.9V34.1a4.913,4.913,0,0,1-4.915,4.9Zm-3.451-4.9a3.45,3.45,0,0,0,3.451,3.441h34.509a3.45,3.45,0,0,0,3.451-3.441V11.685h-41.412Zm41.412-23.874V4.9a3.45,3.45,0,0,0-3.451-3.44h-34.509a3.45,3.45,0,0,0-3.451,3.44v5.327Zm-22.535,21.144a.729.729,0,0,1-.566-.864l2.952-13.974a.731.731,0,0,1,.867-.563.729.729,0,0,1,.565.864l-2.952,13.974a.732.732,0,0,1-.715.58A.751.751,0,0,1-1629.659,31.371Zm5.946-3.257a.728.728,0,0,1,.083-1.028l4.082-3.466-4.082-3.465a.728.728,0,0,1-.083-1.028.732.732,0,0,1,1.031-.083l4.737,4.021a.726.726,0,0,1,.257.555.726.726,0,0,1-.257.556l-4.737,4.021a.73.73,0,0,1-.473.175A.732.732,0,0,1-1623.712,28.114Zm-9.268.083-4.737-4.021a.729.729,0,0,1-.257-.556.732.732,0,0,1,.257-.555l4.737-4.021a.733.733,0,0,1,1.032.083.727.727,0,0,1-.083,1.028l-4.082,3.465,4.082,3.466a.727.727,0,0,1,.083,1.028.731.731,0,0,1-.558.257A.732.732,0,0,1-1632.98,28.2Zm.288-22.29a2.316,2.316,0,0,1,2.317-2.309,2.316,2.316,0,0,1,2.317,2.309,2.317,2.317,0,0,1-2.317,2.31A2.317,2.317,0,0,1-1632.692,5.906Zm1.465,0a.852.852,0,0,0,.852.85.852.852,0,0,0,.852-.85.852.852,0,0,0-.852-.85A.852.852,0,0,0-1631.227,5.906Zm-8.583-.022a2.316,2.316,0,0,1,2.317-2.31,2.316,2.316,0,0,1,2.317,2.31,2.316,2.316,0,0,1-2.317,2.309A2.316,2.316,0,0,1-1639.809,5.884Zm1.464,0a.853.853,0,0,0,.853.85.853.853,0,0,0,.853-.85.853.853,0,0,0-.853-.85A.853.853,0,0,0-1638.346,5.884Zm-8.582-.023a2.316,2.316,0,0,1,2.317-2.309,2.315,2.315,0,0,1,2.317,2.309,2.315,2.315,0,0,1-2.317,2.31A2.316,2.316,0,0,1-1646.927,5.862Zm1.465,0a.852.852,0,0,0,.852.85.852.852,0,0,0,.853-.85.852.852,0,0,0-.853-.85A.852.852,0,0,0-1645.463,5.862Z"
                    transform="translate(1650)"
                    fill="url(#linear-gradient)"
                  />
                </svg>
              </div>
              <h4 className="title">App Development</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39.312"
                  height="49"
                  viewBox="0 0 39.312 49"
                >
                  <defs>
                    <linearGradient
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <g data-name="Group 2379" transform="translate(-195 -3392)">
                    <path
                      id="Union_30"
                      data-name="Union 30"
                      d="M-1856.578,481.712l2.183-13.28-3.658-12.046a17.668,17.668,0,0,1-.948-5.721A17.661,17.661,0,0,1-1841.384,433a17.66,17.66,0,0,1,17.618,17.661l.01,1.869,4.067,11.931h-4.78l-.847,10.693-8.437-.777-.932,7.587-1.759-.218,1.143-9.3,8.36.77.834-10.535h3.939l-3.36-9.856-.011-2.165a15.886,15.886,0,0,0-15.847-15.889,15.884,15.884,0,0,0-15.845,15.889,15.921,15.921,0,0,0,.861,5.174l3.787,12.471-2.251,13.69Zm11.295.144V460.1a3.844,3.844,0,0,0-3.839-3.852,3.839,3.839,0,0,1-2.718-1.128,3.844,3.844,0,0,1-1.124-2.725,3.849,3.849,0,0,1,2.8-3.7,3.851,3.851,0,0,1,.632-4.6,3.832,3.832,0,0,1,4.589-.638,3.839,3.839,0,0,1,3.694-2.8,3.84,3.84,0,0,1,3.7,2.8,3.834,3.834,0,0,1,4.589.638,3.857,3.857,0,0,1,.633,4.6,3.848,3.848,0,0,1,2.8,3.7,3.848,3.848,0,0,1-3.842,3.853h-6.389a3.847,3.847,0,0,0-3.842,3.852v21.757Z"
                      transform="translate(2054 2959)"
                      fill="url(#linear-gradient)"
                    />
                  </g>
                </svg>
              </div>
              <h4 className="title">Marketing Stratagy</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                >
                  <defs>
                    <linearGradient
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <g transform="translate(0.302 0.315)">
                    <path
                      id="Union_29"
                      data-name="Union 29"
                      d="M23.166,25.981a5.08,5.08,0,1,1,2.813-2.813L48,32.055,37.817,36.5l5.644,5.644-1.314,1.314L36.5,37.817,32.049,48ZM32.119,43.2l3.372-7.713L43.2,32.122,24.956,24.759q-.1.1-.2.2ZM0,21.262a21.264,21.264,0,0,1,42.527,0H40.668a19.4,19.4,0,1,0-19.4,19.407v1.86A21.289,21.289,0,0,1,0,21.262Zm6.687,0a14.579,14.579,0,0,1,29.158,0H31.091a9.827,9.827,0,1,0-9.828,9.829v4.746A14.591,14.591,0,0,1,6.686,21.262Z"
                      transform="translate(-0.302 -0.315)"
                      fill="url(#linear-gradient)"
                    />
                  </g>
                </svg>
              </div>
              <h4 className="title">Digital Marketing</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-5.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-11">
            <div
              className="service-2-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.984"
                  height="40.26"
                  viewBox="0 0 43.984 40.26"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.5"
                      x2="0.5"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stopColor="#ff564f" />
                      <stop offset="1" stopColor="#fad029" />
                    </linearGradient>
                  </defs>
                  <g id="visibility" transform="translate(0 -21.668)">
                    <path
                      data-name="Union 28"
                      d="M19.832,39.365a3.033,3.033,0,0,1-.894-2.161V26.262h2.247V24.075A26.062,26.062,0,0,1,.3,12.493L0,12.044l.3-.45a26.088,26.088,0,0,1,43.382,0l.3.45-.3.449A26.063,26.063,0,0,1,22.8,24.075v2.187h2.246V37.205a3.056,3.056,0,0,1-5.217,2.16Zm.723-2.16a1.439,1.439,0,0,0,2.877,0V27.878H20.554ZM21.992,22.47h.179a10.427,10.427,0,1,0-.358,0ZM1.953,12.044a24.468,24.468,0,0,0,12.206,9.142,12.029,12.029,0,0,1,0-18.287A24.47,24.47,0,0,0,1.953,12.044Zm32.083,0a12.021,12.021,0,0,1-4.21,9.141,24.474,24.474,0,0,0,12.2-9.142A24.474,24.474,0,0,0,29.823,2.9,12.023,12.023,0,0,1,34.036,12.045Zm-17.645,0a5.6,5.6,0,1,1,5.6,5.6A5.6,5.6,0,0,1,16.392,12.045Z"
                      transform="translate(0 21.668)"
                      fill="url(#linear-gradient)"
                    />
                  </g>
                </svg>
              </div>
              <h4 className="title">Keyword Research</h4>
              <p>
                Sitting proudly atop is the storey penthouse. The natty master
                suite bedroom phenomenally.
              </p>
              <div className="service-dot">
                <img src="assets/images/service-dot-2.png" alt="" />
                <div className="item">
                  <img src="assets/images/icon/service-icon-6.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
