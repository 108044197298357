import React from "react";
import CardStyleTwo from "../Cards/CardStyleTwo";

function ArticleSection({ datas, className, cardStyleClasses }) {
  return (
    <section className={`article-2-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-3 text-center">
              <span>Our blog</span>
              <h3 className="title">Recent Article</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {datas &&
            datas.length > 0 &&
            datas.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6 col-sm-9">
                <CardStyleTwo datas={item} className={cardStyleClasses} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ArticleSection;
