const data = {
  filterData: [
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-1.png",
      filter: ["graphic"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-2.png",
      filter: ["graphic", "motion"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-3.png",
      filter: ["graphic", "illustration"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-5.png",
      filter: ["graphic", "development"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-6.png",
      filter: ["graphic", "product"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-4.png",
      filter: ["graphic", "product", "development"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-9.png",
      filter: ["graphic"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-8.png",
      filter: ["graphic", "motion"],
    },
    {
      id: Math.random(),
      title: "Product photography around the world",
      category: "illustration, graphic",
      image: "portfolio-7.png",
      filter: ["graphic", "illustration"],
    },
  ],
};
export default data.filterData;
