import React, { useEffect } from "react";
import bottomToTop from "../../Lib/BackToTop";

function BackToTop({ className }) {
  useEffect(() => {
    bottomToTop(".back-to-top");
  });
  const handler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <a onClick={handler} className={`back-to-top ${className || ""}`}>
      <i className="fal fa-angle-up"></i>
    </a>
  );
}

export default BackToTop;
