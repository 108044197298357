import React from "react";

function FooterSix() {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-top footer-top-5 d-block d-sm-flex  justify-content-between align-items-center">
              <div className="footer-logo">
                <a href="#">
                  <img
                    src={require(`../../../assets/images/logo-5.png`).default}
                    alt=""
                  />
                </a>
              </div>
              <div className="footer-social">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-item">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-about mt-30">
                <h4 className="title">Company</h4>
                <p>
                  DNP Installations can carry out all maintenance on phone and
                  data and phone points in DNP Installations can carry out all
                  maintenance on phone and data and phone.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-list footer-list-5 mt-30 ml-95">
                <h4 className="title">Quick links</h4>
                <ul>
                  <li>
                    <a href="/blog-two">Blogs</a>
                  </li>
                  <li>
                    <a href="/single-post">Recent Post</a>
                  </li>
                  <li>
                    <a href="/portfolio-five">Portfolio</a>
                  </li>
                  <li>
                    <a href="/contact">Support</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-list footer-list-5 mt-30">
                <h4 className="title">Categories</h4>
                <ul>
                  <li>
                    <a href="/about-us-one">About us</a>
                  </li>
                  <li>
                    <a href="/pricing-one">Pricing</a>
                  </li>
                  <li>
                    <a href="/service-one">Services</a>
                  </li>
                  <li>
                    <a href="/blog-one">News</a>
                  </li>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-info footer-info-5 mt-30">
                <h3 className="title">Categories</h3>
                <ul>
                  <li>
                    <i className="fal fa-map-marker-alt"></i> 445 Main Street,
                    New York CA-12325, USA
                  </li>
                  <li>
                    <i className="fal fa-phone"></i> +00 125 456 888
                  </li>
                  <li>
                    <i className="fal fa-envelope"></i> contact@aball.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-copyright">
              <p>Copyright @ 2020. Design by Seative.Digital</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterSix;
