import React from "react";

function GetIntouch() {
  return (
    <section className="contact-us-area">
      <div className="section__bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="contact-us-title">
              <h3 className="title">
                Ready to get started? <br /> Contact us!
              </h3>
            </div>
            <div
              className="contact-us-box animated wow fadeInLeft"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <h3 className="title">Get in Touch</h3>
              <p>
                Give us a call or drop by anytime, we endeavour to <br /> answer
                all enquiries within 24 hours.
              </p>
              <form action="#">
                <div className="row no-gutters">
                  <div className="col-lg-6">
                    <div className="input-box mt-10">
                      <input type="text" placeholder="Your name" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box mt-10">
                      <input type="email" placeholder="Email address" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box mt-10">
                      <input
                        type="text"
                        placeholder="Telephone number (optional)"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box mt-10">
                      <input type="text" placeholder="Location (optional)" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box mt-10">
                      <textarea
                        name="#"
                        id="#"
                        cols="30"
                        rows="10"
                        placeholder="Briefly describe your project"
                      ></textarea>
                    </div>
                    <ul className="checkbox_common checkbox_style5">
                      <li>
                        <input
                          type="checkbox"
                          name="checkbox5"
                          id="checkbox13"
                        />
                        <label htmlFor="checkbox13">
                          <span></span>I agree to the data protection
                          regulations
                        </label>
                      </li>
                    </ul>
                    <div className="input-box">
                      <button className="main-btn" type="submit">
                        Free consultation
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetIntouch;
