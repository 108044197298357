import React from "react";

function ReviewSection() {
  return (
    <section className="review-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="review-item">
              <div className="review-content">
                <h3 className="title">
                  4,9 <span>/5</span>
                </h3>
                <ul>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
                <span>
                  About <span>Benjamin</span>
                </span>
              </div>
              <div className="review-box">
                <ul>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-1.png`).default
                      }
                      alt=""
                    />
                    <span>Top Rated</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-2.png`).default
                      }
                      alt=""
                    />
                    <span>Our Rated</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-3.png`).default
                      }
                      alt=""
                    />
                    <span>Our Rated 5 of 5</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ReviewSection;
