import React from "react";

function PricingCardStyleOne({ className, iconStyle = "pricing-arrow.png" }) {
  return (
    <div
      className={`pricing-2-item mt-30 animated wow fadeInLeft ${
        className || ""
      }`}
      data-wow-duration="1000ms"
      data-wow-delay="0ms"
    >
      <div className="item">
        <h4 className="title">Startup</h4>
        <div className="text">
          <p>
            Brand is a collective of young, up-and-coming lateral thinkers who
            would describe
          </p>
        </div>
        <ul>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />{" "}
            Idea making
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            Information architecture
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            Customer journey map
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            <span>Road map</span> depend of your project
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />{" "}
            Design system
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            Wireframe
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            <span>User persona</span> depend of your project
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />{" "}
            Visual design
          </li>
          <li>
            <img
              src={require(`../../assets/images/${iconStyle}`).default}
              alt=""
            />
            Interaction
          </li>
        </ul>
        <a className="main-btn" href="/contact">
          Free Consultation
        </a>
      </div>
    </div>
  );
}

export default PricingCardStyleOne;
