import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Pricing from "./Pricing";
import Project from "./Project";
import Services from "./Services";
import Team from "./Team";

function ServiceThree() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Service three"
        breadcrumb={["Home", "Service three"]}
      />
      <Services />
      <Project />
      <Team />
      <Pricing />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default ServiceThree;
