import React from "react";

function HeaderThree({ action, className }) {
  return (
    <section className={`header-3-area ${className || ""}`}>
      <div className="heraer-item">
        <div className="header-menu-item">
          <div className="toggle-menu" onClick={(e) => action(e)}>
            <a className="canvas_open" href="#">
              <img
                src={
                  require(`../../../assets/images/icon/header-dot.png`).default
                }
                alt=""
              />
            </a>
          </div>
          <div className="header-menu-info d-none d-sm-block">
            <ul>
              <li>
                <span>Phone</span>
                <h4 className="title">+00 125 456 888</h4>
              </li>
              <li>
                <span>Email</span>
                <h4 className="title">contact@pharma.com</h4>
              </li>
            </ul>
          </div>
        </div>
        <div className="header-menu-btn d-none d-md-block">
          <a className="main-btn" href="#">
            Hire us
          </a>
        </div>
      </div>
    </section>
  );
}

export default HeaderThree;
