import React from "react";
import data from "../../Data/Blogs";
import CommonHero from "../../Helper/CommonHero";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Posts from "./Posts";

function BlogOne() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Blog One" breadcrumb={["Home", "Blog One"]} />
      <Posts datas={data.posts} />
      <FooterOne />
    </>
  );
}

export default BlogOne;
