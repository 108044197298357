import React from "react";

function ContactForm() {
  return (
    <div className="post-form-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="post-form-title">
              <h3 className="title">Leave an opinion</h3>
            </div>
            <div className="post-form-item">
              <form action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-box mt-30">
                      <input type="text" placeholder="Full name" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box mt-30">
                      <input type="email" placeholder="Email address" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box mt-30">
                      <textarea
                        name="#"
                        id="#"
                        cols="30"
                        rows="10"
                        placeholder="Tell us about your opinion…"
                      ></textarea>
                      <button type="submit" className="main-btn mt-20">
                        POST OPINION
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="post-news">
              <div className="post-news-title">
                <h3 className="title">Our latest news</h3>
              </div>
              <div className="post-news-items">
                <div className="item mt-20">
                  <img
                    src={
                      require(`../../../assets/images/post-news-1.png`).default
                    }
                    alt=""
                  />
                  <span>Quomodosoft</span>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellenesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                  <a href="#">Reply</a>
                </div>
                <div className="item mt-20">
                  <img
                    src={
                      require(`../../../assets/images/post-news-2.png`).default
                    }
                    alt=""
                  />
                  <span>Quomodosoft</span>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellenesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                  <a href="#">Reply</a>
                </div>
                <div className="item mt-20 pl-30 item-2">
                  <img
                    src={
                      require(`../../../assets/images/post-news-3.png`).default
                    }
                    alt=""
                  />
                  <span>Quomodosoft</span>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellentesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                  <a href="#">Reply</a>
                </div>
                <div className="item mt-20 item-4">
                  <img
                    src={
                      require(`../../../assets/images/post-news-4.png`).default
                    }
                    alt=""
                  />
                  <span>Quomodosoft</span>
                  <p>
                    We’ve invested every aspect of how we serve our users over
                    the past Pellenesque rutrum ante in nulla suscipit, vel
                    posuere leo tristique.
                  </p>
                  <a href="#">Reply</a>
                </div>
                <a className="main-btn mt-15" href="#">
                  LOAD MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
