import React from "react";

function CommonHero({ title, breadcrumb = [] }) {
  const nextSection = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 444, behavior: "smooth" });
  };
  return (
    <>
      <div className="page-title-area">
        <div className="section__bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-title-content text-center">
                <h3 className="title">{title}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    {breadcrumb &&
                      breadcrumb.length > 0 &&
                      breadcrumb.map((item) => (
                        <li key={Math.random()} className="breadcrumb-item">
                          <a href="#">{item}</a>
                        </li>
                      ))}
                  </ol>
                </nav>
                <div className="scroll-to-down">
                  <a href="#portfolio-scroll" onClick={(e) => nextSection(e)}>
                    <i className="fal fa-arrow-down"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonHero;
