import React, { useEffect, useState } from "react";
import isotopeData from "../Data/FilterDatas";

function Portfolio() {
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("graphic");
  useEffect(() => {
    setTimeout(() => {
      setIsotope(
        // eslint-disable-next-line no-undef
        new Isotope(".filter-container", {
          // filter-container: className of the parent of the isotope elements
          itemSelector: ".filter-item", // filter-item: className of the isotope elements
          // layoutMode: "fitRows", // for horizontal isotope
        })
      );
    }, 500);
  }, []);

  useEffect(() => {
    if (isotope) {
      // sanity check
      // eslint-disable-next-line no-unused-expressions
      filterKey === "graphic"
        ? isotope.arrange({ filter: `.graphic` })
        : isotope.arrange({ filter: `.${filterKey}` });
    }
  }, [isotope, filterKey]);
  const handleFilterKeyChange = (key) => setFilterKey(key);
  const replaceCommaToSpace = (value) => value.join(" ");
  return (
    <section className="portfolio-2-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-3 text-center">
              <span>Our work</span>
              <h3 className="title">Our Portfolio</h3>
            </div>
            <div className="project-menu">
              <ul>
                <li
                  className={filterKey === "graphic" ? "active" : ""}
                  onClick={() => handleFilterKeyChange("graphic")}
                >
                  Graphic design
                </li>
                <li
                  className={filterKey === "motion" ? "active" : ""}
                  onClick={() => handleFilterKeyChange("motion")}
                >
                  Motion design
                </li>
                <li
                  className={filterKey === "illustration" ? "active" : ""}
                  onClick={() => handleFilterKeyChange("illustration")}
                >
                  Illustration
                </li>
                <li
                  className={filterKey === "product" ? "active" : ""}
                  onClick={() => handleFilterKeyChange("product")}
                >
                  Product Design
                </li>
                <li
                  className={filterKey === "development" ? "active" : ""}
                  onClick={() => handleFilterKeyChange("development")}
                >
                  Development
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row filter-container ">
          {isotopeData &&
            isotopeData.length > 0 &&
            isotopeData.map((item) => (
              <div
                key={item.id}
                className={`filter-item col-lg-4 col-md-6  ${
                  item.filter.length > 0
                    ? // eslint-disable-next-line no-unused-vars
                      replaceCommaToSpace(item.filter)
                    : ""
                }`}
              >
                <div className="portfolio-2-item mt-30">
                  <img
                    src={
                      require(`../../assets/images/portfolio/${item.image}`)
                        .default
                    }
                    alt=""
                  />
                  <div className="portfolio-overlay">
                    <h4 className="title">{item.title}</h4>
                    <span>{item.category}</span>
                  </div>
                  <div className="portfolio-overlay-2 text-center">
                    <h4 className="title">{item.title}</h4>
                    <span>{item.category}</span>
                    <br />
                    <a href="#">
                      Show more
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.196"
                          height="12.663"
                          viewBox="0 0 10.196 12.663"
                        >
                          <path
                            data-name="Path 18155"
                            d="M32.324,974.539,28.2,978.448a.731.731,0,0,0-.052.947.678.678,0,0,0,.948,0l3.027-2.864v9.346a.652.652,0,1,0,1.3,0v-9.346l3.027,2.864a.664.664,0,0,0,.948,0,.714.714,0,0,0-.052-.947l-4.126-3.909A.661.661,0,0,0,32.324,974.539Z"
                            transform="translate(-27.676 -974.112)"
                            fill="#fff"
                            stroke="#fff"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-btn text-center mt-40">
              <a className="main-btn main-btn-3" href="#">
                ALL Projects
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
