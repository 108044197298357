import React from "react";

function Pricing({ className, shape = "hero-line.png" }) {
  return (
    <section className={`pricing-area ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title section-title-2 text-center">
              <img
                src={require(`../../assets/images/${shape}`).default}
                alt=""
              />
              <h3 className="title">Pricing Plan</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-9 order-1">
            <div className="pricing-item mt-30 animated wow fadeInLeft">
              <h3 className="title">Free</h3>
              <span>Personal Plan</span>
              <p>
                Aussehen ist nicht alles, aber ein guter Anfang! Sei schön mit…
              </p>
              <ul>
                <li>Logos & Branding</li>
                <li>2D Visuals</li>
                <li>3D Animations</li>
                <li>Social Media</li>
              </ul>
              <a className="main-btn" href="/contact">
                Get Started
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9 order-3 order-lg-2">
            <div className="pricing-item mt-30 center animated wow fadeInUp">
              <h3 className="title">$249.00</h3>
              <span>Professional Plan</span>
              <p>
                Aussehen ist nicht alles, aber ein guter Anfang! Sei schön mit…
              </p>
              <ul>
                <li>Logos & Branding</li>
                <li>2D Visuals</li>
                <li>3D Animations</li>
                <li>Social Media</li>
              </ul>
              <a className="main-btn" href="/contact">
                Get Started
              </a>
              <div className="tag">Popular</div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9 order-2 order-lg-3">
            <div className="pricing-item mt-30 animated wow fadeInRight">
              <h3 className="title">$249.00</h3>
              <span>Premium Plan</span>
              <p>
                Aussehen ist nicht alles, aber ein guter Anfang! Sei schön mit…
              </p>
              <ul>
                <li>Logos & Branding</li>
                <li>2D Visuals</li>
                <li>3D Animations</li>
                <li>Social Media</li>
              </ul>
              <a className="main-btn" href="/contact">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
