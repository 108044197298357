import React from "react";
import CounterUpCom from "../../Lib/CounterUp";

function FunFact() {
  return (
    <section
      id="funFact"
      className="fun-facts-3-area fun-facts-4-area pt-50 pb-80"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item fun-facts-4-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0ms"
            >
              <img
                src={
                  require(`../../assets/images/brand/fun-facts-1.png`).default
                }
                alt=""
              />

              <h3 className="title count">
                <CounterUpCom endValue={63} sectionSelect="funFact" />
              </h3>
              <span>Years of experience</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item fun-facts-4-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <img
                src={
                  require(`../../assets/images/brand/fun-facts-2.png`).default
                }
                alt=""
              />
              <h3 className="title count">
                <CounterUpCom endValue={27} sectionSelect="funFact" />
              </h3>
              <span>Awards won</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item fun-facts-4-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <img
                src={
                  require(`../../assets/images/brand/fun-facts-3.png`).default
                }
                alt=""
              />
              <h3 className="title count">
                <CounterUpCom endValue={79} sectionSelect="funFact" />
              </h3>
              <span>Project taken</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-9">
            <div
              className="fun-facts-3-item fun-facts-4-item text-center mt-30 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="900ms"
            >
              <img
                src={
                  require(`../../assets/images/brand/fun-facts-4.png`).default
                }
                alt=""
              />
              <h3 className="title">
                <span className="count">
                  <CounterUpCom endValue={63} sectionSelect="funFact" />
                </span>
                K
              </h3>
              <span>Twitter followers</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FunFact;
