import React from "react";
import Icon from "../Helper/Icon";

function ServiceCardStyleOne({ className, datas = {} }) {
  return (
    <div
      className={`service-item mt-30 animated wow fadeInUp ${className || ""}`}
      data-wow-duration="1000ms"
      data-wow-delay="0ms"
    >
      <Icon name={datas.icon} />
      <h3 className="title">{datas.name}</h3>
      <p>{datas.description}</p>
    </div>
  );
}

export default ServiceCardStyleOne;
