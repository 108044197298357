import React from "react";
import data from "../Data/HomeOne";
import BackToTop from "../Helper/BackToTop";
import FooterSix from "../Partials/Footers/FooterSix";
import HeaderSix from "../Partials/Headers/HeaderSix";
import About from "./About";
import ArticleSection from "./ArticleSection";
import Hero from "./Hero";
import Services from "./Services";
import Subscribe from "./Subscribe";
import Testimonial from "./Testimonial";
import UpdatePartSection from "./UpdatePartSection";

function HomeSix() {
  return (
    <>
      <HeaderSix />
      <Hero />
      <Services />
      <About />
      <UpdatePartSection />
      <Testimonial />
      <ArticleSection datas={data.article} />
      <Subscribe formStyle="subscribe-6-form" />
      <FooterSix />
      <BackToTop className="back-to-top-6" />
    </>
  );
}

export default HomeSix;
