import React from "react";

function DownloadArea() {
  return (
    <div className="download-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="download-content">
              <h3 className="title">Download The App</h3>
              <span>
                Since ancient times, wine has been perceive part of our culture
                and a symbol of good life role of wine has centuries.
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="download-btn">
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/brand/play-store.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Download on the</span>
                    <b>GOOGLE PLAY</b>
                  </a>
                </li>
                <li>
                  <a className="btn-2" href="#">
                    <img
                      src={
                        require(`../../assets/images/brand/apple-store.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Download on the</span>
                    <b>APP STORE</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadArea;
