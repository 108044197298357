const data = {
  categories: [
    {
      id: Math.random(),
      name: "counsalting",
      availableItem: 2,
    },
    {
      id: Math.random(),
      name: "marketing",
      availableItem: 6,
    },
    {
      id: Math.random(),
      name: "strategy",
      availableItem: 6,
    },
    {
      id: Math.random(),
      name: "analysis",
      availableItem: 7,
    },
    {
      id: Math.random(),
      name: "buisness",
      availableItem: 9,
    },
    {
      id: Math.random(),
      name: "finance",
      availableItem: 3,
    },
    {
      id: Math.random(),
      name: "news",
      availableItem: 5,
    },
  ],
};
export default data;
