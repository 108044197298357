import React from "react";

function Hero() {
  return (
    <section className="hero-7-area">
      <div className="section__bg">
        <div className="hero-shape">
          <img
            src={require(`../../assets/images/hero-shape-7.png`).default}
            alt=""
          />
        </div>
        <div className="hero-shape-2">
          <img
            src={require(`../../assets/images/hero-shape-7.2.png`).default}
            alt=""
          />
        </div>
        <div className="hero-case">
          <img
            src={require(`../../assets/images/hero-case-7.png`).default}
            alt=""
          />
        </div>
        <div className="hero-line">
          <img
            src={require(`../../assets/images/hero-line-7.png`).default}
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-10">
            <div className="hero-7-content">
              <h3 className="title">Let’s Design Digital</h3>
              <div className="text">
                <p>
                  We provide dedicated creative teams to tech companies provide
                  dedicated creative.
                </p>
              </div>
              <ul className="store-btns">
                <li>
                  <a href="#">
                    <img
                      src={
                        require(`../../assets/images/brand/play-store.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Download on the</span>
                    <b>GOOGLE PLAY</b>
                  </a>
                </li>
                <li>
                  <a className="btn-2" href="#">
                    <img
                      src={
                        require(`../../assets/images/brand/apple-store.png`)
                          .default
                      }
                      alt=""
                    />
                    <span>Download on the</span>
                    <b>APP STORE</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
