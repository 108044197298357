import React from "react";
import CardStyleTwo from "../../Cards/CardStyleTwo";

function Posts({ datas = [] }) {
  return (
    <>
      <section className="article-page-area pt-70 pb-100">
        <div className="container">
          <div className="row justify-content-center">
            {datas &&
              datas.length > 0 &&
              datas.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-sm-9">
                  <CardStyleTwo datas={item} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Posts;
