import React from "react";

function Team() {
  return (
    <>
      <section className="team-2-area team-servies-3 white-bg team-service-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div className="section-title text-center">
                <h3 className="title">Our Team</h3>
                <p>
                  Our focus is on delivering you the absolute best support
                  guiding you through training and providing.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-8">
              <div
                className="team-2-item team-service-item mt-30 animated wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={
                    require(`../../../assets/images/team-member.png`).default
                  }
                  alt=""
                />
                <div className="team-overlay">
                  <div className="item">
                    <h4 className="title">Abdur Rohman</h4>
                    <span>Product Designer</span>
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-overlay-2 text-center">
                  <img
                    src={require(`../../../assets/images/team-1.png`).default}
                    alt=""
                  />
                  <h4 className="title">Abdur Rohman</h4>
                  <span>Product Designer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-8">
              <div
                className="team-2-item team-service-item mt-30 animated wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <img
                  src={
                    require(`../../../assets/images/team-member.png`).default
                  }
                  alt=""
                />
                <div className="team-overlay">
                  <div className="item">
                    <h4 className="title">Mehedi Hasan</h4>
                    <span>Product Designer</span>
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-overlay-2 text-center">
                  <img
                    src={require(`../../../assets/images/team-1.png`).default}
                    alt=""
                  />
                  <h4 className="title">Mehedi Hasan</h4>
                  <span>Product Designer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-8">
              <div
                className="team-2-item team-service-item mt-30 animated wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="600ms"
              >
                <img
                  src={
                    require(`../../../assets/images/team-member.png`).default
                  }
                  alt=""
                />
                <div className="team-overlay">
                  <div className="item">
                    <h4 className="title">Rayhan Uddin</h4>
                    <span>Product Designer</span>
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-overlay-2 text-center">
                  <img
                    src={require(`../../../assets/images/team-2.png`).default}
                    alt=""
                  />
                  <h4 className="title">Rayhan Uddin</h4>
                  <span>Product Designer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-8">
              <div
                className="team-2-item team-service-item mt-30 animated wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="900ms"
              >
                <img
                  src={
                    require(`../../../assets/images/team-member.png`).default
                  }
                  alt=""
                />
                <div className="team-overlay">
                  <div className="item">
                    <h4 className="title">Thaherul Islam</h4>
                    <span>Product Designer</span>
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-overlay-2 text-center">
                  <img
                    src={require(`../../../assets/images/team-3.png`).default}
                    alt=""
                  />
                  <h4 className="title">Thaherul Islam</h4>
                  <span>Product Designer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
