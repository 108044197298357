import React from "react";

function MainSection() {
  return (
    <section className="portfolio-style-5-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <h3 className="title">Our Project</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0 mt-30">
        <div className="row no-gutters justify-content-center">
          <div className="col-lg-4 col-md-7">
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-20.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-24.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-27.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-7">
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-21.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-23.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-25.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-7">
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-22.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-26.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
            <div className="portfolio-style-5-item">
              <img
                src={
                  require(`../../../assets/images/portfolio/portfolio-style-28.png`)
                    .default
                }
                alt=""
              />
              <div className="portfolio-overlay d-flex align-items-end">
                <div className="item">
                  <h4 className="title">
                    Graphic design vs product design <br /> vs graphic
                    illustration
                  </h4>
                  <span>Illustration, Graphic Design</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
