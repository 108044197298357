import React from "react";

function VideoSection({ action }) {
  return (
    <div className="video-area">
      <div className="section__bg"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="video-content text-center">
              <a
                className="video-popup"
                onClick={(e) => action(e)}
                href="https://www.youtube.com/watch?v=lMml8SOr19E"
              >
                <i className="fas fa-play"></i>
              </a>
              <span>
                Wide With Title Four Style Layout Helse Partner Nord Wide With
                Title Four Style Layout Helsepartner Nord
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;
