import React from "react";
import CardStyleOne from "../Cards/CardStyleOne";

function ArticleSection({ datas, shape = "hero-line.png", itemStyle = "" }) {
  return (
    <section className="article-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/${shape}`).default}
                alt=""
              />
              <h3 className="title">Recent Article</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {datas &&
            datas.length > 0 &&
            datas.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6 col-sm-9">
                <CardStyleOne datas={item} className={`${itemStyle}`} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ArticleSection;
