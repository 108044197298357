const data = [
  {
    id: Math.random(),
    title: "home",
    link: "#",
    submenu: true,
    items: [
      {
        id: `${Math.random()}sub`,
        title: "home one",
        link: "/",
      },
      {
        id: `${Math.random()}sub`,
        title: "home two",
        link: "/home-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "home three",
        link: "/home-three",
      },
      {
        id: `${Math.random()}sub`,
        title: "home four",
        link: "/home-four",
      },
      {
        id: `${Math.random()}sub`,
        title: "home five",
        link: "/home-five",
      },
      {
        id: `${Math.random()}sub`,
        title: "home six",
        link: "/home-six",
      },
    ],
  },
  {
    id: Math.random(),
    title: "About",
    link: "#",
    submenu: true,
    items: [
      {
        id: `${Math.random()}sub`,
        title: "About one",
        link: "/about-us-one",
      },
      {
        id: `${Math.random()}sub`,
        title: "About two",
        link: "/about-us-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "About three",
        link: "/about-us-three",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Service",
    link: "#",
    submenu: true,
    items: [
      {
        id: Math.random(),
        title: "Service one",
        link: "/service-one",
      },
      {
        id: `${Math.random()}sub`,
        title: "Service two",
        link: "/service-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "Service three",
        link: "/service-three",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Pages",
    submenu: true,
    items: [
      {
        id: Math.random(),
        title: "Portfolio one",
        link: "/portfolio-one",
      },
      {
        id: Math.random(),
        title: "Portfolio two",
        link: "/portfolio-two",
      },
      {
        id: Math.random(),
        title: "Portfolio three",
        link: "/portfolio-three",
      },
      {
        id: `${Math.random()}sub`,
        title: "Portfolio Four",
        link: "/portfolio-four",
      },
      {
        id: `${Math.random()}sub`,
        title: "Portfolio five",
        link: "/portfolio-five",
      },
      {
        id: `${Math.random()}sub`,
        title: "Portfolio Details",
        link: "/portfolio-details-one",
      },
      {
        id: `${Math.random()}sub`,
        title: "Portfolio Details two",
        link: "/portfolio-details-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "Blog",
        link: "/blog-one",
      },
      {
        id: `${Math.random()}sub`,
        title: "Blog two",
        link: "/blog-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "Single post",
        link: "/single-post",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Pricing",
    submenu: true,
    items: [
      {
        id: Math.random(),
        title: "Pricing one",
        link: "/pricing-one",
      },
      {
        id: `${Math.random()}sub`,
        title: "pricing two",
        link: "/pricing-two",
      },
      {
        id: `${Math.random()}sub`,
        title: "Pricing three",
        link: "/pricing-three",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Contact",
    link: "/contact",
    submenu: false,
  },
];
export default data;
