import React, { useState } from "react";
import CardRowStyleOne from "../../Cards/CardRowStyleOne";
import VideoPopUp from "../../Helper/VideoPopUp";

function MainSection({ categories = [], posts = [] }) {
  const [popupvideo, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!popupvideo);
  };
  return (
    <>
      {popupvideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={toggleHandler}
        />
      )}
      <section className="single-post-area pt-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="single-post-content mt-30">
                <div className="single-post-topbar">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/icon/post-icon-1.png`)
                            .default
                        }
                        alt=""
                      />
                      July 5, 2020
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/icon/post-icon-2.png`)
                            .default
                        }
                        alt=""
                      />
                      General, Insights
                    </li>
                  </ul>
                  categories
                  <div className="social">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-heart"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-bookmark"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-ellipsis-v"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="post-content">
                  <div className="text mt-30">
                    <p>
                      Entilators will be taken from certain New York hospitals
                      and redistriuted to the worst-hit parts of the state under
                      an order to be signed by Governor Andrew Cuomo.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      New York saw its highest single-day increase in deaths, up
                      by 562 to 2,935 - nearly half of all virus-related US
                      deaths recorded yesterday. The White House may advise
                      those in virus hotspots to wear face coverings in public
                      to help stem the spread.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      A shortage of several hundred ventilators in New York
                      City, the epicentre of the outbreak in the US, prompted Mr
                      Cuomo to say that he will order the machines be taken from
                      various parts of the state and give them to harder-hit
                      areas.
                    </p>
                    <div className="text">
                      <p>
                        Amid a deepening crisis, top health official Dr Anthony
                        Fauci has said he believes all states should issue
                        stay-at-home orders.
                      </p>
                    </div>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “I don’t understand why that’s not happening,” Dr Fauci
                      told CNN on Thursday. “If you look at what’s going on in
                      this country, I just don’t understand why we’re not doing
                      that.” “You’ve got to put your foot on the accelerator to
                      bring that number down,” he added, referring to infection
                      and death rates. on the accelerator to bring that number
                      down,” he added.
                    </p>
                  </div>
                </div>
                <div className="post-thumb pt-60 pb-30">
                  <img
                    src={
                      require(`../../../assets/images/post-thumb-1.png`).default
                    }
                    alt=""
                  />
                </div>

                <div className="post-content">
                  <div className="text mt-30">
                    <p>
                      Entilators will be taken from certain New York hospitals
                      and redistributed to the worst-hit parts of the state
                      under an order to be signed by Governor Andrew Cuomo.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      New York saw its highest single-day increase in deaths, up
                      by 562 to 2,935 - nearly half of all virus-related US
                      deaths recorded yesterday. The White House may advise
                      those in virus hotspots to wear face coverings in public
                      to help stem the spread mid deepening crisis, top health
                      official Dr Anthony Fauci has said he believes all states
                      should issue stay-at-home orders.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “I don’t understand why that’s not happening,” Dr Fauci
                      told CNN on Thursday. “If you look at what’s going on in
                      this country, I just don’t understand why we’re not doing
                      that.”
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “You’ve got to put your foot on the accelerator to bring
                      that number down,” he added, referring to infection and
                      death rates.
                    </p>
                  </div>
                  <ul>
                    <li>Should more of us wear face masks?</li>
                    <li>Why some countries wear face masks and others don’t</li>
                    <li>Coronavirus: Are homemade face masks safe?</li>
                  </ul>
                  <div className="text mt-30">
                    <p>
                      The comments from Dr Fauci, who heads the National
                      Institute of Allergy and Infectious Diseases, appeared to
                      contradict those of President Trump, who has consistently
                      dismissed the notion of a nationwide lockdown.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “It’s awfully tough to say, ‘close it down.’ We have to
                      have a little bit of flexibility,” Mr Trump said on
                      Wednesday.
                    </p>
                  </div>
                </div>
                <div className="post-details-item">
                  <h3 className="title">What’s the debate over masks?</h3>
                  <div className="text">
                    <p>
                      Both the US Centers for Disease Control (CDC) and the
                      World Health Organization (WHO) are reassessing their
                      guidance on face masks, as experts race to find ways to
                      fight the highly contagious virus.
                    </p>
                  </div>
                  <div className="text">
                    <p>
                      Covid-19 is carried in airborne droplets from people
                      coughing or sneezing, but there is some dispute over how
                      far people should distance themselves from each other, and
                      whether masks are useful when used by the public.
                    </p>
                  </div>
                </div>
                <div className="post-thumb pt-60 mb-30">
                  <img
                    src={
                      require(`../../../assets/images/post-thumb-2.png`).default
                    }
                    alt=""
                  />
                  <a
                    className="video-popup"
                    onClick={(e) => toggleHandler(e)}
                    href="https://www.youtube.com/watch?v=UFTc0wl26TY"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                  <span>
                    I just had a baby - now I’m going to the frontline.
                  </span>
                </div>
                <div className="post-content pt-10">
                  <div className="text mt-30">
                    <p>
                      In global terms the US has the most Covid-19 cases - more
                      than 245,000. And on Thursday the US authorities said more
                      than 1,000 had died in the past 24 hours - the highest
                      daily toll so far in the world.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      Hospitals and morgues in New York are struggling to cope
                      with the pandemic, and New York Governor Andrew Cuomo has
                      warned that New York risks running out of ventilators for
                      patients in six days.
                    </p>
                  </div>
                  <div className="post-quote-item mt-30">
                    <p>
                      I must explain to you how all this mistake idea denouncing
                      pleasure and praising pain was born and I will give you a
                      complete account of the system, and expound the actual
                      teachings of the great explorer of the truth, the
                      master-builder of human happiness.
                    </p>
                    <div className="info">
                      <img
                        src={
                          require(`../../../assets/images/testimonial-info.png`)
                            .default
                        }
                        alt=""
                      />
                      <h4 className="title">Quomodosoft</h4>
                      <span>Software Development Company</span>
                    </div>
                  </div>
                  <div className="text mt-50">
                    <p>
                      Entilators will be taken from certain New York hospitals
                      and redistributed to the worst-hit parts of the state
                      under an order to be signed by Governor Andrew Cuomo.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      New York saw its highest single-day increase in deaths, up
                      by 562 to 2,935 - nearly half of all virus-related US
                      deaths recorded yesterday. The White House may advise
                      those in virus hotspots to wear face coverings in public
                      to help stem the spread mid deepening crisis, top health
                      official Dr Anthony Fauci has said he believes all states
                      should issue stay-at-home orders.
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “I don’t understand why that’s not happening,” Dr Fauci
                      told CNN on Thursday. “If you look at what’s going on in
                      this country, I just don’t understand why we’re not doing
                      that.”
                    </p>
                  </div>
                  <div className="text mt-30">
                    <p>
                      “You’ve got to put your foot on the accelerator to bring
                      that number down,” he added, referring to infection and
                      death rates.
                    </p>
                  </div>
                </div>
                <div className="post-next">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="prev-post">
                        <span>PREVIOUS NEWS</span>
                        <a href="#">
                          <p>
                            Kushner puts himself in middle of white house’s
                            chaoti coronavirus response.
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="prev-post">
                        <span>NEXT NEWS</span>
                        <a href="#">
                          <p>
                            C.I.A. Hunts for authentic virus totals in china,
                            dismissing government tallies
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="single-post-sidebar mt-30">
                <div className="sidebar-search">
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="Search here" />
                      <button type="button">
                        <i className="far fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="sidebar-categories mt-30">
                  <h4 className="title">Categories</h4>
                  <ul>
                    {categories &&
                      categories.length > 0 &&
                      categories.map((item) => (
                        <li
                          key={item.id}
                          style={{ textTransform: "uppercase" }}
                        >
                          <a href="#">
                            <img
                              src={
                                require(`../../../assets/images/post-arrow.png`)
                                  .default
                              }
                              alt=""
                            />
                            {item.name} ({item.availableItem})
                            <span>
                              <img
                                src={
                                  require(`../../../assets/images/icon/hero-icon-2.png`)
                                    .default
                                }
                                alt=""
                              />
                            </span>
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="sidebar-post mt-30">
                  <h4 className="title">Popular post</h4>
                  <div className="post-items">
                    {posts &&
                      posts.length > 0 &&
                      posts.map((item) => (
                        <CardRowStyleOne key={item.id} datas={item} />
                      ))}
                  </div>
                </div>
                <div className="sidebar-gallery mt-30">
                  <h3 className="title">Gallery</h3>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-1.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-2.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-3.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-4.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-5.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-6.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-7.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-8.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-9.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-newsletter">
                  <div className="footer-newsletter-item mt-60">
                    <div className="dot">
                      <img
                        src="assets/images/icon/footer-newsletter-dot.png"
                        alt=""
                      />
                    </div>
                    <h3 className="title">
                      Subscribe to our newsletter to receive exclusive offers.
                    </h3>
                    <form action="#">
                      <div className="input-box">
                        <input type="text" placeholder="Enter email…" />
                        <button className="main-btn-3" type="button">
                          Join us
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
