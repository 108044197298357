import React from "react";

function Subscribe({ className, formStyle }) {
  return (
    <div className={`subscribe-area subscribe-6-area ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="subscribe-item">
              <h3 className="title">Love Eating? Like offer?</h3>
              <span>
                Since ancient times, wine has been perceive part of our culture
                and a symbol of good life role of wine has centuries.
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className={`subscribe-form ${formStyle || ""}`}>
              <input type="email" placeholder="Your email address" />
              <button type="button" className="main-btn">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
