import React from "react";

function HeaderSeven({ className, action }) {
  return (
    <>
      <header className={`header-7-area ${className || ""}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-7-item d-flex align-items-center justify-content-between">
                <div className="header-nav-7 d-flex align-items-center">
                  <div className="toggle-menu" onClick={action}>
                    <a className="canvas_open" href="#">
                      <img
                        src={
                          require(`../../../assets/images/icon/header-dot.png`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="header-logo">
                    <a href="index-7.html">
                      <img
                        src={
                          require(`../../../assets/images/logo-6.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="header-btn">
                  <ul>
                    <li>
                      <a href="#">LOGIN</a>
                    </li>
                    <li>
                      <a className="main-btn" href="#">
                        Get started
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderSeven;
