import React, { useRef } from "react";
import SliderCom from "../Helper/SliderCom";

function Portfolio({ className }) {
  const sliderRef = useRef();
  const prev = () => {
    sliderRef.current.slickPrev();
  };
  const next = () => {
    sliderRef.current.slickNext();
  };
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "370px",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "140px",
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className={`portfolio-area pb-130 ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/hero-line.png`).default}
                alt=""
              />
              <h3 className="title">Our Portfolio</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="portfolio-active" style={{ position: "relative" }}>
          <span
            onClick={prev}
            className="prev slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fal fa-angle-left"></i>
          </span>
          <SliderCom settings={settings} selector={sliderRef} className="">
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30">
                <img
                  src={require(`../../assets/images/portfolio-1.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30 mb-30">
                <img
                  src={require(`../../assets/images/portfolio-2.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30 mb-30">
                <img
                  src={require(`../../assets/images/portfolio-1.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30 mb-30">
                <img
                  src={require(`../../assets/images/portfolio-3.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30 mb-30">
                <img
                  src={require(`../../assets/images/portfolio-4.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="react-slick-item-portfolio">
              <div className="portfolio-item mt-30 mb-30">
                <img
                  src={require(`../../assets/images/portfolio-4.png`).default}
                  alt=""
                />
                <div className="portfolio-overlay">
                  <div className="content">
                    <img
                      src={require(`../../assets/images/hero-line.png`).default}
                      alt=""
                    />
                    <a href="/portfolio-details-one"><h4 className="title">Real design inspiration</h4></a>
                    <p>
                      Sitting proudly atop is the storey form bedroom
                      phenomenally.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SliderCom>
          <span
            onClick={next}
            className="next slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fal fa-angle-right"></i>
          </span>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
