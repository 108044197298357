import React from "react";
import Navigations from "../Data/Navigation";
import Drawer from "../Helper/Drawer";
import useToggle from "../Hooks/useToggle";
import FooterThree from "../Partials/Footers/FooterThree";
import HeaderThree from "../Partials/Headers/HeaderThree";
import About from "./About";
import Article from "./Article";
import FunFact from "./FunFact";
import GetIntouch from "./GetIntouch";
import Hero from "./Hero";
import Pricing from "./Pricing";
import ReviewSection from "./ReviewSection";
import Services from "./Services";
import Sponsers from "./Sponsers";
import Testimonial from "./Testimonial";
import UpdateSection from "./UpdateSection";

function HomeThree() {
  const [toggle, setValue] = useToggle(false);
  return (
    <>
      <Drawer toggle={toggle} action={setValue.toggle} navItem={Navigations} />
      <HeaderThree action={setValue.toggle} />
      <Hero />
      <ReviewSection />
      <About />
      <Services />
      <UpdateSection />
      <Testimonial />
      <FunFact />
      <Sponsers />
      <Pricing />
      <Article />
      <GetIntouch />
      <FooterThree />
    </>
  );
}

export default HomeThree;
