import React, { useState } from "react";
import { Link } from "react-router-dom";

function Drawer({ navItem, action, toggle, className }) {
  const [itemSize, setSize] = useState("0px");
  const [tab, setTab] = useState();
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      setSize(`${50 * getItems}px`);
      if (tab !== value) {
        setTab(value);
      } else {
        setTab("");
      }
    }
  };
  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${toggle ? "active" : ""}`}
      ></div>
      <div className={`offcanvas_menu ${className || ""}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper  overflow-style-none ${
                  toggle ? "active" : ""
                }`}
              >
                <div className="canvas_close" onClick={(e) => action(e)}>
                  <Link to="#">
                    <i className="fal fa-times"></i>
                  </Link>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <Link to="$">
                        <i className="fab fa-facebook-square"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-dribbble"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    {navItem &&
                      navItem.length > 0 &&
                      navItem.map((item) => (
                        <li key={item.id} className="menu-item-has-children">
                          {item.submenu ? (
                            <>
                              <a
                                onClick={(a) => handler(a, item.title)}
                                className="nav-link"
                              >
                                {item.title}
                                <span className="menu-expand">
                                  <i className="fa fa-angle-down"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <Link className="nav-link" to={item.link}>
                              {item.title}
                            </Link>
                          )}
                          {item.submenu && (
                            <ul
                              id={item.title}
                              className="sub-menu overflow-hidden"
                              style={{
                                height: tab === item.title ? itemSize : "0px",
                              }}
                            >
                              {item.items.map((subItem) => (
                                <li key={subItem.id}>
                                  <Link
                                    to={subItem.link}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {subItem.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="offcanvas_footer">
                  <span>
                    <a href="mailto:quomodosoft@gmail.com">
                      <i className="fa fa-envelope-o"></i> quomodosoft@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
