import React from "react";
import Data from "../Data/HomeTwo";
import BackToTop from "../Helper/BackToTop";
import FooterTwo from "../Partials/Footers/FooterTwo";
import HeaderTwo from "../Partials/Headers/HeaderTwo";
import About from "./About";
import AboutCompany from "./AboutCompany";
import ArticleSection from "./ArticleSection";
import FunFact from "./FunFact";
import Hero from "./Hero";
import Portfolio from "./Portfolio";
import Pricing from "./Pricing";
import Services from "./Services";
import Sub from "./Sub";
import Team from "./Team";
import Testimonial from "./Testimonial";

function HomeTwo() {
  return (
    <>
      <HeaderTwo />
      <Hero />
      <Sub />
      <About />
      <Services />
      <Portfolio />
      <Testimonial />
      <AboutCompany />
      <FunFact />
      <Team>
        <div className="col-lg-12">
          <div className="section-title-3 text-center">
            <span>Our Team</span>
            <h3 className="title">Special Team</h3>
          </div>
        </div>
      </Team>
      <Pricing />
      <ArticleSection datas={Data.article} />
      <FooterTwo>
        <div className="footer-2-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <div className="footer-logo">
                  <a href="index-2.html">
                    <img
                      src={require(`../../assets/images/logo-2.png`).default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="footer-info">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../assets/images/icon/footer-icon-1.png`)
                            .default
                        }
                        alt=""
                      />
                      445 Main Street, New York CA-12325, USA
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="footer-info ml-60">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../assets/images/icon/footer-icon-2.png`)
                            .default
                        }
                        alt=""
                      />
                      +00 125 456 888
                    </li>
                    <li> +00 125 456 888</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="footer-info">
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../assets/images/icon/footer-icon-3.png`)
                            .default
                        }
                        alt=""
                      />
                      hello@pharma.com
                    </li>
                    <li> contact@pharma.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterTwo>
      <BackToTop />
    </>
  );
}

export default HomeTwo;
