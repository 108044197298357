import React from "react";
import SliderCom from "../Helper/SliderCom";

function Testimonial({ className }) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <section className={`testimonial-2-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title-3">
              <span>Testimonial</span>
              <h3 className="title">What our clients say about our agency.</h3>
            </div>
            <div className="testimonial-2-active mt-100">
              <SliderCom settings={settings}>
                <div className="item">
                  <span>Product Design</span>
                  <p>
                    “Sitting proudly atop is the two-storey penthouse. The
                    former master bedroom phenomenally spacious makes the most
                    of a its unrivalled position.”
                  </p>
                  <div className="info mt-30">
                    <img
                      src={
                        require(`../../assets/images/testimonial-info.png`)
                          .default
                      }
                      alt=""
                    />
                    <h5>Quomodo Theme</h5>
                    <span>Product Manager</span>
                  </div>
                </div>
                <div className="item">
                  <span>Product Design</span>
                  <p>
                    “Sitting proudly atop is the two-storey penthouse. The
                    former master bedroom phenomenally spacious makes the most
                    of a its unrivalled position.”
                  </p>
                  <div className="info mt-30">
                    <img
                      src={
                        require(`../../assets/images/testimonial-info.png`)
                          .default
                      }
                      alt=""
                    />
                    <h5>Quomodo Theme</h5>
                    <span>Product Manager</span>
                  </div>
                </div>
                <div className="item">
                  <span>Product Design</span>
                  <p>
                    “Sitting proudly atop is the two-storey penthouse. The
                    former master bedroom phenomenally spacious makes the most
                    of a its unrivalled position.”
                  </p>
                  <div className="info mt-30">
                    <img
                      src={
                        require(`../../assets/images/testimonial-info.png`)
                          .default
                      }
                      alt=""
                    />
                    <h5>Quomodo Theme</h5>
                    <span>Product Manager</span>
                  </div>
                </div>
                <div className="item">
                  <span>Product Design</span>
                  <p>
                    “Sitting proudly atop is the two-storey penthouse. The
                    former master bedroom phenomenally spacious makes the most
                    of a its unrivalled position.”
                  </p>
                  <div className="info mt-30">
                    <img
                      src={
                        require(`../../assets/images/testimonial-info.png`)
                          .default
                      }
                      alt=""
                    />
                    <h5>Quomodo Theme</h5>
                    <span>Product Manager</span>
                  </div>
                </div>
                <div className="item">
                  <span>Product Design</span>
                  <p>
                    “Sitting proudly atop is the two-storey penthouse. The
                    former master bedroom phenomenally spacious makes the most
                    of a its unrivalled position.”
                  </p>
                  <div className="info mt-30">
                    <img
                      src={
                        require(`../../assets/images/testimonial-info.png`)
                          .default
                      }
                      alt=""
                    />
                    <h5>Quomodo Theme</h5>
                    <span>Product Manager</span>
                  </div>
                </div>
              </SliderCom>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
