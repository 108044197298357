import React from "react";
import ServiceCardStyleOne from "../Cards/ServiceCardStyleOne";

function Services({
  className,
  shape = "hero-line.png",
  services = [],
  cardStyle = "",
  limit = "6",
}) {
  return (
    <section className={`service-area pb-100 ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/${shape}`).default}
                alt=""
              />
              <h3 className="title">Our Service</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {services &&
            services.length > 0 &&
            services.slice(0, limit).map((item) => (
              <div
                key={`${services.id + item.name}`}
                className="col-lg-4 col-md-6 col-sm-8"
              >
                <ServiceCardStyleOne datas={item} className={cardStyle} />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
