import React from "react";

function RelatedProducts() {
  return (
    <section className="article-post-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="article-post-title text-center">
              <h3 className="title">Related product</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-style-4-item mt-30">
              <div className="portfolio-thumb">
                <img
                  src={
                    require(`../../../assets/images/related-product-1.png`)
                      .default
                  }
                  alt=""
                />
                <a href="#">
                  See details{" "}
                  <span>
                    <img
                      src={
                        require(`../../../assets/images/icon/portfolio-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div className="portfolio-content">
                <h5 className="title">
                  Graphic design vs product design vs graphic illustration
                </h5>
                <span>Illustration, Graphic Design</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-style-4-item mt-30">
              <div className="portfolio-thumb">
                <img
                  src={
                    require(`../../../assets/images/related-product-2.png`)
                      .default
                  }
                  alt=""
                />
                <a href="#">
                  See details{" "}
                  <span>
                    <img
                      src={
                        require(`../../../assets/images/icon/portfolio-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div className="portfolio-content">
                <h5 className="title">
                  Graphic design vs product design vs graphic illustration
                </h5>
                <span>Illustration, Graphic Design</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="portfolio-style-4-item mt-30">
              <div className="portfolio-thumb">
                <img
                  src={
                    require(`../../../assets/images/related-product-3.png`)
                      .default
                  }
                  alt=""
                />
                <a href="#">
                  See details{" "}
                  <span>
                    <img
                      src={
                        require(`../../../assets/images/icon/portfolio-arrow-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
              <div className="portfolio-content">
                <h5 className="title">
                  Graphic design vs product design vs graphic illustration
                </h5>
                <span>Illustration, Graphic Design</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RelatedProducts;
