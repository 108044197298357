import React from "react";

export default function SubscribeArea() {
  return (
    <div className="subscribe-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="subscribe-item">
              <h3 className="title">Love Eating? Like offer?</h3>
              <span>
                Since ancient times, wine has been perceive part of our culture
                and a symbol of good life role of wine has centuries.
              </span>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="subscribe-form">
              <input type="email" placeholder="Your email address" />
              <button type="button" className="main-btn">
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
