import React from "react";

function Pricing() {
  return (
    <>
      <section className="pricing-services-area pb-110 pt-65">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div className="section-title text-center">
                <h3 className="title">Pricing</h3>
                <p>
                  Our focus is on delivering you the absolute best support
                  guiding you through training and providing.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div
                className="pricing-2-item mt-30 animated wow fadeInLeft"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <div className="item">
                  <h4 className="title">Startup</h4>
                  <div className="text">
                    <p>
                      Brand is a collective of young, up-and-coming lateral
                      thinkers who would describe
                    </p>
                  </div>
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />{" "}
                      Idea making
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Information architecture
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Customer journey map
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      <span>Road map</span> depend of your project
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Design system
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Wireframe
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      <span>User persona</span> depend of your project
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Visual design
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Interaction
                    </li>
                  </ul>
                  <a className="main-btn" href="#">
                    Free Consultation
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9">
              <div
                className="pricing-2-item item-2 mt-30 animated wow fadeInRight"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <div className="item">
                  <h4 className="title">Startup</h4>
                  <div className="text">
                    <p>
                      Brand is a collective of young, up-and-coming lateral
                      thinkers who would describe
                    </p>
                  </div>
                  <ul>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />{" "}
                      Idea making
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Information architecture
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Customer journey map
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      <span>Road map</span> depend of your project
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Design system
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Wireframe
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      <span>User persona</span> depend of your project
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Visual design
                    </li>
                    <li>
                      <img
                        src={
                          require(`../../../assets/images/pricing-arrow.png`)
                            .default
                        }
                        alt=""
                      />
                      Interaction
                    </li>
                  </ul>
                  <a className="main-btn" href="#">
                    Free Consultation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
