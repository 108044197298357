import React from "react";

function Hero() {
  const nextSection = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 600, behavior: "smooth" });
  };
  return (
    <div className="page-title-area page-title-2-area">
      <div className="section__bg"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="page-title-content page-title-content-2 text-center">
              <h3 className="title">The Most Impressive Copywriting Results</h3>
              <div className="info">
                <img
                  src={
                    require(`../../../assets/images/testimonial-info.png`)
                      .default
                  }
                  alt=""
                />
                <h5 className="title">Quomodosoft</h5>
                <span>Software Development Company</span>
              </div>
              <div className="scroll-to-down">
                <a href="#" onClick={(e) => nextSection(e)}>
                  <i className="fal fa-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
