import React from "react";
import data from "../Data/HomeOne";
import services from "../Data/Services";
import BackToTop from "../Helper/BackToTop";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderOne from "../Partials/Headers/HeaderOne";
import About from "./About";
import ArticleSection from "./ArticleSection";
import Hero from "./Hero";
import OverView from "./OverView";
import Portfolio from "./Portfolio";
import Pricing from "./Pricing";
import Services from "./Services";
import Team from "./Team";
import Testimonial from "./Testimonial";

function HomeOne() {
  return (
    <>
      <HeaderOne />
      <Hero />
      <About />
      <Services services={services.services} />
      <Portfolio />
      <Testimonial />
      <OverView />
      <Team datas={data.teamMembers} />
      <Pricing />
      <ArticleSection datas={data.article} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default HomeOne;
