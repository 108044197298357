import React from "react";

function Icon({ name }) {
  // name ex:king,geo-love,app-dev,brain,cursor-drop,eye
  return (
    <>
      {name === "king" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62.001"
            height="70"
            viewBox="0 0 62.001 70"
          >
            <g data-name="Group 15" transform="translate(-270 -1139)">
              <path
                data-name="Union 2"
                d="M-5,322.218.218,317l1.8,1.8-2.145,2.145H15.685V323.5H-.109l2.131,2.131-1.8,1.8Z"
                transform="translate(275 855.366)"
                fill="#ffab4a"
              />
              <g data-name="Group 9" transform="translate(277.025 1139)">
                <path
                  className="white"
                  data-name="Path 19"
                  d="M127.959,440.246H81.286V428.611h46.673v11.635Zm-44.165-2.551h41.657v-6.532H83.795Z"
                  transform="translate(-81.286 -370.246)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 20"
                  d="M146.328,199.2H106.764l1.088-1.873c5.87-10.114,8.973-22.572,8.973-36.027v-1.248h19.443V161.3c0,13.457,3.1,25.914,8.973,36.027Zm-35.269-2.5h30.974c-5.241-9.813-8.081-21.551-8.257-34.157H119.316C119.141,175.152,116.3,186.89,111.059,196.7Z"
                  transform="translate(-103.209 -138.284)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 21"
                  d="M151.5,19.4H132.928L124.97,0h11.275V4.835h2.316V0h7.3V4.835h2.316V0h11.276ZM134.6,16.924H149.82L155.744,2.48H150.67V7.315h-7.3V2.48h-2.315V7.315h-7.3V2.48h-5.072Z"
                  transform="translate(-118.875)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 22"
                  d="M174.109,131.565H145.3v-6.778h28.81Zm-26.329-2.314h23.846V127.1H147.781Z"
                  transform="translate(-136.367 -107.479)"
                  fill="#235c51"
                />
              </g>
              <path
                className="white"
                data-name="Path 23"
                d="M219.916,244.71a6.1,6.1,0,1,1-6.094-6.1A6.1,6.1,0,0,1,219.916,244.71Z"
                transform="translate(86.549 932.872)"
                fill="#235c51"
              />
              <path
                data-name="Union 1"
                d="M15.841,326.379l-3.41-3.408H-5v-2.551H12.421L15.841,317H26.792L22.1,321.69l4.692,4.689Zm-1.082-4.689,2.138,2.138h3.733l-2.138-2.138,2.138-2.137H16.9Z"
                transform="translate(305.208 855.895)"
                fill="#ffab4a"
              />
            </g>
          </svg>
        </div>
      ) : name === "king_two" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62.001"
            height="70"
            viewBox="0 0 62.001 70"
          >
            <g
              id="Group_15"
              data-name="Group 15"
              transform="translate(-270 -1139)"
            >
              <path
                id="Union_2"
                data-name="Union 2"
                d="M-5,322.218.218,317l1.8,1.8-2.145,2.145H15.685V323.5H-.109l2.131,2.131-1.8,1.8Z"
                transform="translate(275 855.366)"
                fill="#ff564f"
              ></path>
              <g
                id="Group_9"
                data-name="Group 9"
                transform="translate(277.025 1139)"
              >
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M127.959,440.246H81.286V428.611h46.673v11.635Zm-44.165-2.551h41.657v-6.532H83.795Z"
                  transform="translate(-81.286 -370.246)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M146.328,199.2H106.764l1.088-1.873c5.87-10.114,8.973-22.572,8.973-36.027v-1.248h19.443V161.3c0,13.457,3.1,25.914,8.973,36.027Zm-35.269-2.5h30.974c-5.241-9.813-8.081-21.551-8.257-34.157H119.316C119.141,175.152,116.3,186.89,111.059,196.7Z"
                  transform="translate(-103.209 -138.284)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M151.5,19.4H132.928L124.97,0h11.275V4.835h2.316V0h7.3V4.835h2.316V0h11.276ZM134.6,16.924H149.82L155.744,2.48H150.67V7.315h-7.3V2.48h-2.315V7.315h-7.3V2.48h-5.072Z"
                  transform="translate(-118.875)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M174.109,131.565H145.3v-6.778h28.81Zm-26.329-2.314h23.846V127.1H147.781Z"
                  transform="translate(-136.367 -107.479)"
                  fill="#ff564f"
                ></path>
              </g>
              <path
                id="Path_23"
                data-name="Path 23"
                d="M219.916,244.71a6.1,6.1,0,1,1-6.094-6.1A6.1,6.1,0,0,1,219.916,244.71Z"
                transform="translate(86.549 932.872)"
                fill="#ff564f"
              ></path>
              <path
                id="Union_1"
                data-name="Union 1"
                d="M15.841,326.379l-3.41-3.408H-5v-2.551H12.421L15.841,317H26.792L22.1,321.69l4.692,4.689Zm-1.082-4.689,2.138,2.138h3.733l-2.138-2.138,2.138-2.137H16.9Z"
                transform="translate(305.208 855.895)"
                fill="#ff564f"
              ></path>
            </g>
          </svg>
        </div>
      ) : name === "king_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="44.286"
            height="50"
            viewBox="0 0 44.286 50"
          >
            <defs>
              <linearGradient
                x1="0.962"
                y1="1.076"
                x2="0.024"
                y2="-0.094"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0.02" stopColor="#45108a"></stop>
                <stop offset="0.293" stopColor="#3d065f"></stop>
                <stop offset="1" stopColor="#12054e"></stop>
                <stop offset="1" stopColor="#10054d"></stop>
              </linearGradient>
            </defs>
            <path
              data-name="Union 6"
              d="M-1644.982,50V41.689h3.593a46.853,46.853,0,0,0,4.9-13.213h-10.016l1.524,1.523-1.289,1.288L-1650,27.561l3.728-3.728,1.289,1.289-1.532,1.532h10.38a58.26,58.26,0,0,0,.874-9.449h-3.341V12.362h3.044L-1640.629,0h8.054V3.454h1.654V0h5.215V3.454h1.654V0H-1616l-5.07,12.362h3.044V17.2h-3.341a58.245,58.245,0,0,0,.874,9.449h4.512l2.443-2.443h7.823l-3.351,3.35,3.351,3.35h-7.823l-2.434-2.435h-4.164a46.863,46.863,0,0,0,4.9,13.213h3.593V50Zm1.792-1.822h29.755V43.511h-29.755Zm3.837-6.488h22.082a49.18,49.18,0,0,1-4.688-13.213h-2.09a4.354,4.354,0,0,1-4.256,3.438,4.356,4.356,0,0,1-4.355-4.356,4.354,4.354,0,0,1,4.355-4.354,4.351,4.351,0,0,1,4.259,3.45h1.743a60.111,60.111,0,0,1-.846-9.322h-10.328A53.392,53.392,0,0,1-1639.354,41.689Zm25.044-14.128,1.527,1.527h2.667l-1.527-1.527,1.527-1.527h-2.667Zm-7.06-12.012v0h1.572V14.016h-17.033v1.535h1.573v0Zm-12.379-3.46h10.869l4.231-10.317h-3.624V5.225h-5.216V1.771h-1.653V5.225h-5.216V1.771h-3.623Z"
              transform="translate(1650)"
              fill="url(#linear-gradient)"
            ></path>
          </svg>
        </div>
      ) : name === "king_four" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62.001"
            height="70"
            viewBox="0 0 62.001 70"
          >
            <g
              id="Group_15"
              data-name="Group 15"
              transform="translate(-270 -1139)"
            >
              <path
                id="Union_2"
                data-name="Union 2"
                d="M-5,322.218.218,317l1.8,1.8-2.145,2.145H15.685V323.5H-.109l2.131,2.131-1.8,1.8Z"
                transform="translate(275 855.366)"
                fill="#ffab4a"
              />
              <g
                id="Group_9"
                data-name="Group 9"
                transform="translate(277.025 1139)"
              >
                <path
                  className="white"
                  id="Path_19"
                  data-name="Path 19"
                  d="M127.959,440.246H81.286V428.611h46.673v11.635Zm-44.165-2.551h41.657v-6.532H83.795Z"
                  transform="translate(-81.286 -370.246)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_20"
                  data-name="Path 20"
                  d="M146.328,199.2H106.764l1.088-1.873c5.87-10.114,8.973-22.572,8.973-36.027v-1.248h19.443V161.3c0,13.457,3.1,25.914,8.973,36.027Zm-35.269-2.5h30.974c-5.241-9.813-8.081-21.551-8.257-34.157H119.316C119.141,175.152,116.3,186.89,111.059,196.7Z"
                  transform="translate(-103.209 -138.284)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_21"
                  data-name="Path 21"
                  d="M151.5,19.4H132.928L124.97,0h11.275V4.835h2.316V0h7.3V4.835h2.316V0h11.276ZM134.6,16.924H149.82L155.744,2.48H150.67V7.315h-7.3V2.48h-2.315V7.315h-7.3V2.48h-5.072Z"
                  transform="translate(-118.875)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_22"
                  data-name="Path 22"
                  d="M174.109,131.565H145.3v-6.778h28.81Zm-26.329-2.314h23.846V127.1H147.781Z"
                  transform="translate(-136.367 -107.479)"
                  fill="#F0323C"
                />
              </g>
              <path
                className="white"
                id="Path_23"
                data-name="Path 23"
                d="M219.916,244.71a6.1,6.1,0,1,1-6.094-6.1A6.1,6.1,0,0,1,219.916,244.71Z"
                transform="translate(86.549 932.872)"
                fill="#F0323C"
              />
              <path
                id="Union_1"
                data-name="Union 1"
                d="M15.841,326.379l-3.41-3.408H-5v-2.551H12.421L15.841,317H26.792L22.1,321.69l4.692,4.689Zm-1.082-4.689,2.138,2.138h3.733l-2.138-2.138,2.138-2.137H16.9Z"
                transform="translate(305.208 855.895)"
                fill="#ffab4a"
              />
            </g>
          </svg>
        </div>
      ) : name === "geo-love" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92.773"
            height="70"
            viewBox="0 0 92.773 70"
          >
            <g data-name="003-vector" transform="translate(0 -53.596)">
              <g data-name="Group 30" transform="translate(2.93 53.596)">
                <path
                  className="white"
                  data-name="Path 126"
                  d="M420.307,203.524l-1.963-1.963,1.389-1.389A20.692,20.692,0,0,0,426,188.46l2.749.393a23.391,23.391,0,0,1-7.058,13.281Z"
                  transform="translate(-348.665 -165.053)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 127"
                  d="M57.655,235.059,24.738,202.142a23.391,23.391,0,0,1-7.058-13.281l2.748-.393A20.692,20.692,0,0,0,26.7,200.18l30.955,30.952,15.68-15.68,1.963,1.963Z"
                  transform="translate(-17.541 -165.059)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 128"
                  d="M55.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.348,0Z"
                  transform="translate(-16.88 -53.596)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  id="Path_129"
                  data-name="Path 129"
                  d="M279.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.35,0Z"
                  transform="translate(-202.002 -53.596)"
                  fill="#235c51"
                />
              </g>
              <g
                id="Group_31"
                data-name="Group 31"
                transform="translate(0 70.258)"
              >
                <rect
                  className="white"
                  data-name="Rectangle 66"
                  width="3"
                  height="4"
                  transform="translate(42 27.338)"
                  fill="#235c51"
                />
                <rect
                  className="white"
                  data-name="Rectangle 71"
                  width="3"
                  height="4"
                  transform="translate(42 35.338)"
                  fill="#235c51"
                />
                <rect
                  className="white"
                  data-name="Rectangle 67"
                  width="3"
                  height="3"
                  transform="translate(42 19.338)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 130"
                  d="M8.331,157.927H0V149.6H8.331ZM2.777,155.15H5.554v-2.777H2.777Z"
                  transform="translate(0 -149.596)"
                  fill="#235c51"
                />
                <path
                  data-name="Union 3"
                  d="M-640.122-2454.331v-2.993H-655v-2h14.878v-3.338h8.331v3.338H-616v2h-15.791v2.993Zm2.777-2.778h2.777v-2.776h-2.777Z"
                  transform="translate(679 2462.662)"
                  fill="#ffab4a"
                />
                <path
                  className="white"
                  data-name="Path 132"
                  d="M456.331,157.927H448V149.6h8.331Zm-5.554-2.777h2.777v-2.777h-2.777Z"
                  transform="translate(-370.244 -149.596)"
                  fill="#235c51"
                />
              </g>
              <path
                data-name="Path 133"
                d="M342.013,287.341,330.5,256.1l31.241,11.509,4.168,1.734-18.038,4.13-4.562,17.366Zm-6.823-26.555,7.742,21.015,2.655-10.617L356.2,268.53Z"
                transform="translate(-273.141 -167.357)"
                fill="#ffab4a"
              />
            </g>
          </svg>
        </div>
      ) : name === "geo-love_two" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92.773"
            height="70"
            viewBox="0 0 92.773 70"
          >
            <g
              id="_003-vector"
              data-name="003-vector"
              transform="translate(0 -53.596)"
            >
              <g
                id="Group_30"
                data-name="Group 30"
                transform="translate(2.93 53.596)"
              >
                <path
                  id="Path_126"
                  data-name="Path 126"
                  d="M420.307,203.524l-1.963-1.963,1.389-1.389A20.692,20.692,0,0,0,426,188.46l2.749.393a23.391,23.391,0,0,1-7.058,13.281Z"
                  transform="translate(-348.665 -165.053)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_127"
                  data-name="Path 127"
                  d="M57.655,235.059,24.738,202.142a23.391,23.391,0,0,1-7.058-13.281l2.748-.393A20.692,20.692,0,0,0,26.7,200.18l30.955,30.952,15.68-15.68,1.963,1.963Z"
                  transform="translate(-17.541 -165.059)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_128"
                  data-name="Path 128"
                  d="M55.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.348,0Z"
                  transform="translate(-16.88 -53.596)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_129"
                  data-name="Path 129"
                  d="M279.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.35,0Z"
                  transform="translate(-202.002 -53.596)"
                  fill="#ff564f"
                ></path>
              </g>
              <g
                id="Group_31"
                data-name="Group 31"
                transform="translate(0 70.258)"
              >
                <rect
                  id="Rectangle_66"
                  data-name="Rectangle 66"
                  width="3"
                  height="4"
                  transform="translate(42 27.338)"
                  fill="#ff564f"
                ></rect>
                <rect
                  id="Rectangle_71"
                  data-name="Rectangle 71"
                  width="3"
                  height="4"
                  transform="translate(42 35.338)"
                  fill="#ff564f"
                ></rect>
                <rect
                  id="Rectangle_67"
                  data-name="Rectangle 67"
                  width="3"
                  height="3"
                  transform="translate(42 19.338)"
                  fill="#ff564f"
                ></rect>
                <path
                  id="Path_130"
                  data-name="Path 130"
                  d="M8.331,157.927H0V149.6H8.331ZM2.777,155.15H5.554v-2.777H2.777Z"
                  transform="translate(0 -149.596)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Union_3"
                  data-name="Union 3"
                  d="M-640.122-2454.331v-2.993H-655v-2h14.878v-3.338h8.331v3.338H-616v2h-15.791v2.993Zm2.777-2.778h2.777v-2.776h-2.777Z"
                  transform="translate(679 2462.662)"
                  fill="#ff564f"
                ></path>
                <path
                  id="Path_132"
                  data-name="Path 132"
                  d="M456.331,157.927H448V149.6h8.331Zm-5.554-2.777h2.777v-2.777h-2.777Z"
                  transform="translate(-370.244 -149.596)"
                  fill="#ff564f"
                ></path>
              </g>
              <path
                id="Path_133"
                data-name="Path 133"
                d="M342.013,287.341,330.5,256.1l31.241,11.509,4.168,1.734-18.038,4.13-4.562,17.366Zm-6.823-26.555,7.742,21.015,2.655-10.617L356.2,268.53Z"
                transform="translate(-273.141 -167.357)"
                fill="#ff564f"
              ></path>
            </g>
          </svg>
        </div>
      ) : name === "geo-love_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="54.772"
            height="41"
            viewBox="0 0 54.772 41"
          >
            <defs>
              <linearGradient
                x1="0.962"
                y1="1.076"
                x2="0.024"
                y2="-0.094"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0.02" stopColor="#45108a"></stop>
                <stop offset="0.293" stopColor="#3d065f"></stop>
                <stop offset="1" stopColor="#12054e"></stop>
                <stop offset="1" stopColor="#10054d"></stop>
              </linearGradient>
            </defs>
            <path
              data-name="Union 27"
              d="M-1644.021,21.72a13.906,13.906,0,0,1-4.045-7.081H-1650V9.759h1.854A12.389,12.389,0,0,1-1636.063,0a12.422,12.422,0,0,1,11.476,7.757A12.425,12.425,0,0,1-1613.11,0a12.388,12.388,0,0,1,12.082,9.759h1.854v4.88h-1.933a13.908,13.908,0,0,1-4.045,7.08l-.82.814-1.16-1.15.82-.813a12.485,12.485,0,0,0,3.525-5.931h-1.307V9.759h1.385a10.749,10.749,0,0,0-10.4-8.132,10.749,10.749,0,0,0-10.4,8.132h1.384v1.955h9.323v1.172h-9.323v1.752h-4.919V12.886h-8.784V11.714h8.784V9.759h1.385a10.75,10.75,0,0,0-10.4-8.132,10.749,10.749,0,0,0-10.4,8.132h1.384v4.88h-1.307a12.481,12.481,0,0,0,3.526,5.932l18.276,18.129,9.257-9.184,1.159,1.15L-1624.587,41Zm41.567-8.708h1.64V11.386h-1.64Zm-22.953,0h1.64V11.386h-1.64Zm-22.953,0h1.64V11.386h-1.64Zm39.023,25.872-6.8-18.3,18.445,6.741,2.46,1.016-10.649,2.419-2.694,10.172Zm.543-3.244,1.567-6.219,6.268-1.555-12.407-4.536ZM-1625.2,32.8V30.457h1.771V32.8Zm0-4.686V25.771h1.771v2.343Zm0-5.271V21.086h1.771v1.757Z"
              transform="translate(1650)"
              fill="url(#linear-gradient)"
            ></path>
          </svg>
        </div>
      ) : name === "geo-love_four" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92.773"
            height="70"
            viewBox="0 0 92.773 70"
          >
            <g
              id="_003-vector"
              data-name="003-vector"
              transform="translate(0 -53.596)"
            >
              <g
                id="Group_30"
                data-name="Group 30"
                transform="translate(2.93 53.596)"
              >
                <path
                  className="white"
                  id="Path_126"
                  data-name="Path 126"
                  d="M420.307,203.524l-1.963-1.963,1.389-1.389A20.692,20.692,0,0,0,426,188.46l2.749.393a23.391,23.391,0,0,1-7.058,13.281Z"
                  transform="translate(-348.665 -165.053)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_127"
                  data-name="Path 127"
                  d="M57.655,235.059,24.738,202.142a23.391,23.391,0,0,1-7.058-13.281l2.748-.393A20.692,20.692,0,0,0,26.7,200.18l30.955,30.952,15.68-15.68,1.963,1.963Z"
                  transform="translate(-17.541 -165.059)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_128"
                  data-name="Path 128"
                  d="M55.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.348,0Z"
                  transform="translate(-16.88 -53.596)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_129"
                  data-name="Path 129"
                  d="M279.482,71.849a18.121,18.121,0,0,0-35.854,0l-2.748-.4a20.9,20.9,0,0,1,41.35,0Z"
                  transform="translate(-202.002 -53.596)"
                  fill="#F0323C"
                />
              </g>
              <g
                id="Group_31"
                data-name="Group 31"
                transform="translate(0 70.258)"
              >
                <rect
                  className="white"
                  id="Rectangle_66"
                  data-name="Rectangle 66"
                  width="3"
                  height="4"
                  transform="translate(42 27.338)"
                  fill="#F0323C"
                />
                <rect
                  className="white"
                  id="Rectangle_71"
                  data-name="Rectangle 71"
                  width="3"
                  height="4"
                  transform="translate(42 35.338)"
                  fill="#F0323C"
                />
                <rect
                  className="white"
                  id="Rectangle_67"
                  data-name="Rectangle 67"
                  width="3"
                  height="3"
                  transform="translate(42 19.338)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  id="Path_130"
                  data-name="Path 130"
                  d="M8.331,157.927H0V149.6H8.331ZM2.777,155.15H5.554v-2.777H2.777Z"
                  transform="translate(0 -149.596)"
                  fill="#F0323C"
                />
                <path
                  id="Union_3"
                  data-name="Union 3"
                  d="M-640.122-2454.331v-2.993H-655v-2h14.878v-3.338h8.331v3.338H-616v2h-15.791v2.993Zm2.777-2.778h2.777v-2.776h-2.777Z"
                  transform="translate(679 2462.662)"
                  fill="#ffab4a"
                />
                <path
                  className="white"
                  id="Path_132"
                  data-name="Path 132"
                  d="M456.331,157.927H448V149.6h8.331Zm-5.554-2.777h2.777v-2.777h-2.777Z"
                  transform="translate(-370.244 -149.596)"
                  fill="#F0323C"
                />
              </g>
              <path
                id="Path_133"
                data-name="Path 133"
                d="M342.013,287.341,330.5,256.1l31.241,11.509,4.168,1.734-18.038,4.13-4.562,17.366Zm-6.823-26.555,7.742,21.015,2.655-10.617L356.2,268.53Z"
                transform="translate(-273.141 -167.357)"
                fill="#ffab4a"
              />
            </g>
          </svg>
        </div>
      ) : name === "app-dev" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="79.34"
            height="70"
            viewBox="0 0 79.34 70"
          >
            <path
              data-name="Path 10826"
              d="M124.784,198.044a1.3,1.3,0,0,1-.848-.313l-8.476-7.217a1.31,1.31,0,0,1,0-1.995l8.476-7.217a1.31,1.31,0,1,1,1.7,1.994l-7.3,6.22,7.3,6.22a1.31,1.31,0,0,1-.85,2.308Zm0,0"
              transform="translate(-93.481 -147.122)"
              fill="#ffab4a"
            />
            <path
              data-name="Path 10827"
              d="M251.018,198.044a1.311,1.311,0,0,1-.85-2.308l7.3-6.22-7.3-6.22a1.309,1.309,0,0,1,1.7-1.994l8.476,7.217a1.31,1.31,0,0,1,0,1.995l-8.476,7.217A1.3,1.3,0,0,1,251.018,198.044Zm0,0"
              transform="translate(-202.982 -147.122)"
              fill="#ffab4a"
            />
            <path
              data-name="Path 10828"
              d="M190.266,180.725a1.333,1.333,0,0,1-.272-.028,1.31,1.31,0,0,1-1.012-1.552l5.283-25.081a1.31,1.31,0,1,1,2.563.54l-5.283,25.081A1.31,1.31,0,0,1,190.266,180.725Zm0,0"
              transform="translate(-153.596 -124.39)"
              fill="#ffab4a"
            />
            <path
              className="white"
              data-name="Path 10829"
              d="M70.545,70H8.795A8.8,8.8,0,0,1,0,61.205V8.795A8.805,8.805,0,0,1,8.795,0H70.545A8.805,8.805,0,0,1,79.34,8.795v52.41A8.8,8.8,0,0,1,70.545,70ZM8.795,2.62A6.182,6.182,0,0,0,2.62,8.795v52.41A6.182,6.182,0,0,0,8.795,67.38H70.545a6.182,6.182,0,0,0,6.175-6.175V8.795A6.182,6.182,0,0,0,70.545,2.62Zm0,0"
              fill="#235c51"
            />
            <path
              className="white"
              data-name="Path 10830"
              d="M78.03,100.71H1.31a1.31,1.31,0,0,1,0-2.62H78.03a1.31,1.31,0,0,1,0,2.62Zm0,0"
              transform="translate(0 -79.735)"
              fill="#235c51"
            />
            <path
              className="white"
              data-name="Path 10831"
              d="M101.594,42.579a4.145,4.145,0,1,1,4.145-4.145A4.151,4.151,0,0,1,101.594,42.579Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,101.594,36.909Zm0,0"
              transform="translate(-79.214 -27.873)"
              fill="#235c51"
            />
            <path
              className="white"
              data-name="Path 10832"
              d="M33.532,42.365a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,33.532,42.365Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.528,1.528,0,0,0,33.532,36.694Zm0,0"
              transform="translate(-23.888 -27.698)"
              fill="#235c51"
            />
            <path
              className="white"
              data-name="Path 10833"
              d="M169.66,42.794a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,169.66,42.794Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,169.66,37.123Zm0,0"
              transform="translate(-134.544 -28.047)"
              fill="#235c51"
            />
          </svg>
        </div>
      ) : name === "app-dev_two" ? (
        <div className="icon">
          <svg
            id="web-programming"
            xmlns="http://www.w3.org/2000/svg"
            width="79.34"
            height="70"
            viewBox="0 0 79.34 70"
          >
            <path
              id="Path_10826"
              data-name="Path 10826"
              d="M124.784,198.044a1.3,1.3,0,0,1-.848-.313l-8.476-7.217a1.31,1.31,0,0,1,0-1.995l8.476-7.217a1.31,1.31,0,1,1,1.7,1.994l-7.3,6.22,7.3,6.22a1.31,1.31,0,0,1-.85,2.308Zm0,0"
              transform="translate(-93.481 -147.122)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10827"
              data-name="Path 10827"
              d="M251.018,198.044a1.311,1.311,0,0,1-.85-2.308l7.3-6.22-7.3-6.22a1.309,1.309,0,0,1,1.7-1.994l8.476,7.217a1.31,1.31,0,0,1,0,1.995l-8.476,7.217A1.3,1.3,0,0,1,251.018,198.044Zm0,0"
              transform="translate(-202.982 -147.122)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10828"
              data-name="Path 10828"
              d="M190.266,180.725a1.333,1.333,0,0,1-.272-.028,1.31,1.31,0,0,1-1.012-1.552l5.283-25.081a1.31,1.31,0,1,1,2.563.54l-5.283,25.081A1.31,1.31,0,0,1,190.266,180.725Zm0,0"
              transform="translate(-153.596 -124.39)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10829"
              data-name="Path 10829"
              d="M70.545,70H8.795A8.8,8.8,0,0,1,0,61.205V8.795A8.805,8.805,0,0,1,8.795,0H70.545A8.805,8.805,0,0,1,79.34,8.795v52.41A8.8,8.8,0,0,1,70.545,70ZM8.795,2.62A6.182,6.182,0,0,0,2.62,8.795v52.41A6.182,6.182,0,0,0,8.795,67.38H70.545a6.182,6.182,0,0,0,6.175-6.175V8.795A6.182,6.182,0,0,0,70.545,2.62Zm0,0"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10830"
              data-name="Path 10830"
              d="M78.03,100.71H1.31a1.31,1.31,0,0,1,0-2.62H78.03a1.31,1.31,0,0,1,0,2.62Zm0,0"
              transform="translate(0 -79.735)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10831"
              data-name="Path 10831"
              d="M101.594,42.579a4.145,4.145,0,1,1,4.145-4.145A4.151,4.151,0,0,1,101.594,42.579Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,101.594,36.909Zm0,0"
              transform="translate(-79.214 -27.873)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10832"
              data-name="Path 10832"
              d="M33.532,42.365a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,33.532,42.365Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.528,1.528,0,0,0,33.532,36.694Zm0,0"
              transform="translate(-23.888 -27.698)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10833"
              data-name="Path 10833"
              d="M169.66,42.794a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,169.66,42.794Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,169.66,37.123Zm0,0"
              transform="translate(-134.544 -28.047)"
              fill="#ff564f"
            ></path>
          </svg>
        </div>
      ) : name === "app-dev_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="44.34"
            height="39"
            viewBox="0 0 44.34 39"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.962"
                y1="1.076"
                x2="0.024"
                y2="-0.094"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0.02" stopColor="#45108a"></stop>
                <stop offset="0.293" stopColor="#3d065f"></stop>
                <stop offset="1" stopColor="#12054e"></stop>
                <stop offset="1" stopColor="#10054d"></stop>
              </linearGradient>
            </defs>
            <path
              data-name="Union 24"
              d="M4.915,39A4.913,4.913,0,0,1,0,34.1V4.9A4.913,4.913,0,0,1,4.915,0H39.424A4.913,4.913,0,0,1,44.34,4.9V34.1A4.913,4.913,0,0,1,39.424,39ZM1.464,34.1a3.45,3.45,0,0,0,3.451,3.441H39.424A3.45,3.45,0,0,0,42.876,34.1V11.685H1.464ZM42.876,10.226V4.9a3.45,3.45,0,0,0-3.451-3.44H4.915A3.45,3.45,0,0,0,1.464,4.9v5.327ZM20.341,31.371a.729.729,0,0,1-.566-.864l2.952-13.974a.732.732,0,0,1,1.432.3L21.208,30.807a.732.732,0,0,1-.715.58A.751.751,0,0,1,20.341,31.371Zm5.946-3.257a.728.728,0,0,1,.083-1.028l4.082-3.466-4.082-3.465a.73.73,0,1,1,.948-1.111l4.737,4.021a.728.728,0,0,1,0,1.111L27.319,28.2a.732.732,0,0,1-1.031-.083ZM17.02,28.2l-4.737-4.021a.728.728,0,0,1,0-1.111l4.737-4.021a.731.731,0,1,1,.949,1.111l-4.082,3.465,4.082,3.466A.731.731,0,0,1,17.02,28.2Zm.288-22.29a2.317,2.317,0,1,1,2.317,2.31A2.316,2.316,0,0,1,17.308,5.906Zm1.465,0a.852.852,0,1,0,.852-.85A.852.852,0,0,0,18.773,5.906ZM10.19,5.884a2.317,2.317,0,1,1,2.317,2.309A2.316,2.316,0,0,1,10.19,5.884Zm1.464,0a.853.853,0,1,0,.853-.85A.853.853,0,0,0,11.654,5.884ZM3.073,5.862a2.317,2.317,0,1,1,2.317,2.31A2.316,2.316,0,0,1,3.073,5.862Zm1.465,0a.852.852,0,1,0,.852-.85A.852.852,0,0,0,4.537,5.862Z"
              fill="url(#linear-gradient)"
            ></path>
          </svg>
        </div>
      ) : name === "app-dev_four" ? (
        <div className="icon">
          <svg
            id="web-programming"
            xmlns="http://www.w3.org/2000/svg"
            width="79.34"
            height="70"
            viewBox="0 0 79.34 70"
          >
            <path
              id="Path_10826"
              data-name="Path 10826"
              d="M124.784,198.044a1.3,1.3,0,0,1-.848-.313l-8.476-7.217a1.31,1.31,0,0,1,0-1.995l8.476-7.217a1.31,1.31,0,1,1,1.7,1.994l-7.3,6.22,7.3,6.22a1.31,1.31,0,0,1-.85,2.308Zm0,0"
              transform="translate(-93.481 -147.122)"
              fill="#ffab4a"
            />
            <path
              id="Path_10827"
              data-name="Path 10827"
              d="M251.018,198.044a1.311,1.311,0,0,1-.85-2.308l7.3-6.22-7.3-6.22a1.309,1.309,0,0,1,1.7-1.994l8.476,7.217a1.31,1.31,0,0,1,0,1.995l-8.476,7.217A1.3,1.3,0,0,1,251.018,198.044Zm0,0"
              transform="translate(-202.982 -147.122)"
              fill="#ffab4a"
            />
            <path
              id="Path_10828"
              data-name="Path 10828"
              d="M190.266,180.725a1.333,1.333,0,0,1-.272-.028,1.31,1.31,0,0,1-1.012-1.552l5.283-25.081a1.31,1.31,0,1,1,2.563.54l-5.283,25.081A1.31,1.31,0,0,1,190.266,180.725Zm0,0"
              transform="translate(-153.596 -124.39)"
              fill="#ffab4a"
            />
            <path
              className="white"
              id="Path_10829"
              data-name="Path 10829"
              d="M70.545,70H8.795A8.8,8.8,0,0,1,0,61.205V8.795A8.805,8.805,0,0,1,8.795,0H70.545A8.805,8.805,0,0,1,79.34,8.795v52.41A8.8,8.8,0,0,1,70.545,70ZM8.795,2.62A6.182,6.182,0,0,0,2.62,8.795v52.41A6.182,6.182,0,0,0,8.795,67.38H70.545a6.182,6.182,0,0,0,6.175-6.175V8.795A6.182,6.182,0,0,0,70.545,2.62Zm0,0"
              fill="#F0323C"
            />
            <path
              className="white"
              id="Path_10830"
              data-name="Path 10830"
              d="M78.03,100.71H1.31a1.31,1.31,0,0,1,0-2.62H78.03a1.31,1.31,0,0,1,0,2.62Zm0,0"
              transform="translate(0 -79.735)"
              fill="#F0323C"
            />
            <path
              className="white"
              id="Path_10831"
              data-name="Path 10831"
              d="M101.594,42.579a4.145,4.145,0,1,1,4.145-4.145A4.151,4.151,0,0,1,101.594,42.579Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,101.594,36.909Zm0,0"
              transform="translate(-79.214 -27.873)"
              fill="#F0323C"
            />
            <path
              className="white"
              id="Path_10832"
              data-name="Path 10832"
              d="M33.532,42.365a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,33.532,42.365Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.528,1.528,0,0,0,33.532,36.694Zm0,0"
              transform="translate(-23.888 -27.698)"
              fill="#F0323C"
            />
            <path
              className="white"
              id="Path_10833"
              data-name="Path 10833"
              d="M169.66,42.794a4.145,4.145,0,1,1,4.145-4.145A4.15,4.15,0,0,1,169.66,42.794Zm0-5.671a1.525,1.525,0,1,0,1.525,1.525A1.527,1.527,0,0,0,169.66,37.123Zm0,0"
              transform="translate(-134.544 -28.047)"
              fill="#F0323C"
            />
          </svg>
        </div>
      ) : name === "brain" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56.911"
            height="70.537"
            viewBox="0 0 56.911 70.537"
          >
            <g transform="translate(0.25 0.25)">
              <path
                className="white"
                data-name="Path 10777"
                d="M56.033,70l3.225-19.556L53.832,32.628a22.7,22.7,0,1,1,44.162-7.392l.016,3.092,4.813,14.08H97.179l-1.2,15.051-11.975-1.1-1.638,13.28,2.519.31L86.227,59.11l12.084,1.11,1.214-15.275h6.847L100.546,27.9l-.014-2.67a25.236,25.236,0,1,0-49.114,8.18l5.239,17.207L53.528,69.588Z"
                transform="translate(-50.06)"
                fill="#235c51"
                strokeWidth="0.5"
              />
              <path
                data-name="Path 10778"
                d="M151.369,66.148a5.5,5.5,0,0,1,7.479-7.484,5.5,5.5,0,0,1,10.586,0,5.5,5.5,0,0,1,7.48,7.484,5.5,5.5,0,0,1-1.5,10.792h-9.152a5.5,5.5,0,0,0-5.5,5.5v31.081h-2.4V82.443a5.5,5.5,0,0,0-5.5-5.5,5.5,5.5,0,0,1-1.5-10.792Z"
                transform="translate(-138.717 -43.73)"
                fill="#ffab4a"
              />
              <g data-name="Group 2290" transform="translate(19.431 24.183)">
                <rect
                  data-name="Rectangle 3474"
                  width="2.397"
                  height="2.397"
                  transform="translate(9.59)"
                  fill="#fff"
                />
                <rect
                  data-name="Rectangle 3475"
                  width="2.397"
                  height="2.397"
                  transform="translate(4.793)"
                  fill="#fff"
                />
                <rect
                  data-name="Rectangle 3476"
                  width="2.397"
                  height="2.397"
                  transform="translate(0)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : name === "brain_two" ? (
        <div className="icon">
          <svg
            id="brain"
            xmlns="http://www.w3.org/2000/svg"
            width="56.312"
            height="70"
            viewBox="0 0 56.312 70"
          >
            <path
              id="Path_10777"
              data-name="Path 10777"
              d="M56.033,70l3.225-19.556L53.832,32.628a22.7,22.7,0,1,1,44.162-7.392l.016,3.092,4.813,14.08H97.179l-1.2,15.051-11.975-1.1-1.638,13.28,2.519.31L86.227,59.11l12.084,1.11,1.214-15.275h6.847L100.546,27.9l-.014-2.67a25.236,25.236,0,1,0-49.114,8.18l5.239,17.207L53.528,69.588Z"
              transform="translate(-50.06)"
              fill="#ff564f"
            ></path>
            <path
              id="Path_10778"
              data-name="Path 10778"
              d="M151.369,66.148a5.5,5.5,0,0,1,7.479-7.484,5.5,5.5,0,0,1,10.586,0,5.5,5.5,0,0,1,7.48,7.484,5.5,5.5,0,0,1-1.5,10.792h-9.152a5.5,5.5,0,0,0-5.5,5.5v31.081h-2.4V82.443a5.5,5.5,0,0,0-5.5-5.5,5.5,5.5,0,0,1-1.5-10.792Z"
              transform="translate(-138.717 -43.73)"
              fill="#ff564f"
            ></path>
            <g
              id="Group_2290"
              data-name="Group 2290"
              transform="translate(19.431 24.183)"
            >
              <rect
                id="Rectangle_3474"
                data-name="Rectangle 3474"
                width="2.397"
                height="2.397"
                transform="translate(9.59)"
                fill="#ff564f"
              ></rect>
              <rect
                id="Rectangle_3475"
                data-name="Rectangle 3475"
                width="2.397"
                height="2.397"
                transform="translate(4.793)"
                fill="#ff564f"
              ></rect>
              <rect
                id="Rectangle_3476"
                data-name="Rectangle 3476"
                width="2.397"
                height="2.397"
                transform="translate(0)"
                fill="#ff564f"
              ></rect>
            </g>
          </svg>
        </div>
      ) : name === "brain_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56.911"
            height="70.537"
            viewBox="0 0 56.911 70.537"
          >
            <g transform="translate(0.25 0.25)">
              <path
                className="white"
                data-name="Path 10777"
                d="M56.033,70l3.225-19.556L53.832,32.628a22.7,22.7,0,1,1,44.162-7.392l.016,3.092,4.813,14.08H97.179l-1.2,15.051-11.975-1.1-1.638,13.28,2.519.31L86.227,59.11l12.084,1.11,1.214-15.275h6.847L100.546,27.9l-.014-2.67a25.236,25.236,0,1,0-49.114,8.18l5.239,17.207L53.528,69.588Z"
                transform="translate(-50.06)"
                fill="#F0323C"
                strokeWidth="0.5"
              />
              <path
                data-name="Path 10778"
                d="M151.369,66.148a5.5,5.5,0,0,1,7.479-7.484,5.5,5.5,0,0,1,10.586,0,5.5,5.5,0,0,1,7.48,7.484,5.5,5.5,0,0,1-1.5,10.792h-9.152a5.5,5.5,0,0,0-5.5,5.5v31.081h-2.4V82.443a5.5,5.5,0,0,0-5.5-5.5,5.5,5.5,0,0,1-1.5-10.792Z"
                transform="translate(-138.717 -43.73)"
                fill="#ffab4a"
              />
              <g data-name="Group 2290" transform="translate(19.431 24.183)">
                <rect
                  data-name="Rectangle 3474"
                  width="2.397"
                  height="2.397"
                  transform="translate(9.59)"
                  fill="#fff"
                />
                <rect
                  data-name="Rectangle 3475"
                  width="2.397"
                  height="2.397"
                  transform="translate(4.793)"
                  fill="#fff"
                />
                <rect
                  data-name="Rectangle 3476"
                  width="2.397"
                  height="2.397"
                  transform="translate(0)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : name === "cursor-drop" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69.996"
            height="70"
            viewBox="0 0 69.996 70"
          >
            <g transform="translate(-0.014)">
              <path
                className="white"
                data-name="Path 15"
                d="M31.022,62.02A31.01,31.01,0,1,1,62.03,31.008H59.319a28.3,28.3,0,1,0-28.3,28.3V62.02h0Z"
                transform="translate(0 0)"
                fill="#235c51"
              />
              <g data-name="Group 7" transform="translate(9.764 9.757)">
                <path
                  data-name="Path 16"
                  d="M92.584,71.368a21.252,21.252,0,1,0,0,42.5v-6.921a14.331,14.331,0,1,1,14.332-14.334h6.931A21.283,21.283,0,0,0,92.584,71.368Z"
                  transform="translate(-71.328 -71.368)"
                  fill="#ffab4a"
                  stroke="#fff"
                  strokeWidth="1.5"
                />
                <path
                  data-name="Path 17"
                  d="M187.424,180.007a7.412,7.412,0,1,1-7.413-7.411A7.411,7.411,0,0,1,187.424,180.007Z"
                  transform="translate(-158.753 -158.756)"
                  fill="#ffab4a"
                  stroke="#fff"
                  strokeWidth="1.5"
                />
              </g>
              <g data-name="Group 8" transform="translate(31.022 31.01)">
                <path
                  className="white"
                  data-name="Path 18"
                  d="M242.544,265.809l-15.729-38.99L265.8,242.555l-16.183,7.068ZM231.716,231.72l10.929,27.091,4.917-11.248,11.243-4.911Z"
                  transform="translate(-226.815 -226.819)"
                  fill="#235c51"
                />
                <rect
                  className="white"
                  data-name="Rectangle 9"
                  width="13.629"
                  height="2.71"
                  transform="translate(30.452 32.37) rotate(-135)"
                  fill="#235c51"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : name === "cursor-drop_two" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69.996"
            height="70"
            viewBox="0 0 69.996 70"
          >
            <g id="click" transform="translate(-0.014)">
              <path
                id="Path_15"
                data-name="Path 15"
                d="M31.022,62.02A31.01,31.01,0,1,1,62.03,31.008H59.319a28.3,28.3,0,1,0-28.3,28.3V62.02h0Z"
                transform="translate(0 0)"
                fill="#ff564f"
              ></path>
              <g
                id="Group_7"
                data-name="Group 7"
                transform="translate(9.764 9.757)"
              >
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M92.584,71.368a21.252,21.252,0,1,0,0,42.5v-6.921a14.331,14.331,0,1,1,14.332-14.334h6.931A21.283,21.283,0,0,0,92.584,71.368Z"
                  transform="translate(-71.328 -71.368)"
                  fill="#ff564f"
                  stroke="#fff"
                  strokeWidth="1.5"
                ></path>
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M187.424,180.007a7.412,7.412,0,1,1-7.413-7.411A7.411,7.411,0,0,1,187.424,180.007Z"
                  transform="translate(-158.753 -158.756)"
                  fill="#ff564f"
                  stroke="#fff"
                  strokeWidth="1.5"
                ></path>
              </g>
              <g
                id="Group_8"
                data-name="Group 8"
                transform="translate(31.022 31.01)"
              >
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M242.544,265.809l-15.729-38.99L265.8,242.555l-16.183,7.068ZM231.716,231.72l10.929,27.091,4.917-11.248,11.243-4.911Z"
                  transform="translate(-226.815 -226.819)"
                  fill="#ff564f"
                ></path>
                <rect
                  id="Rectangle_9"
                  data-name="Rectangle 9"
                  width="13.629"
                  height="2.71"
                  transform="translate(30.452 32.37) rotate(-135)"
                  fill="#ff564f"
                ></rect>
              </g>
            </g>
          </svg>
        </div>
      ) : name === "cursor-drop_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69.996"
            height="70"
            viewBox="0 0 69.996 70"
          >
            <g transform="translate(-0.014)">
              <path
                className="white"
                data-name="Path 15"
                d="M31.022,62.02A31.01,31.01,0,1,1,62.03,31.008H59.319a28.3,28.3,0,1,0-28.3,28.3V62.02h0Z"
                transform="translate(0 0)"
                fill="#F0323C"
              />
              <g data-name="Group 7" transform="translate(9.764 9.757)">
                <path
                  data-name="Path 16"
                  d="M92.584,71.368a21.252,21.252,0,1,0,0,42.5v-6.921a14.331,14.331,0,1,1,14.332-14.334h6.931A21.283,21.283,0,0,0,92.584,71.368Z"
                  transform="translate(-71.328 -71.368)"
                  fill="#ffab4a"
                  stroke="#fff"
                  strokeWidth="1.5"
                />
                <path
                  data-name="Path 17"
                  d="M187.424,180.007a7.412,7.412,0,1,1-7.413-7.411A7.411,7.411,0,0,1,187.424,180.007Z"
                  transform="translate(-158.753 -158.756)"
                  fill="#ffab4a"
                  stroke="#fff"
                  strokeWidth="1.5"
                />
              </g>
              <g data-name="Group 8" transform="translate(31.022 31.01)">
                <path
                  className="white"
                  data-name="Path 18"
                  d="M242.544,265.809l-15.729-38.99L265.8,242.555l-16.183,7.068ZM231.716,231.72l10.929,27.091,4.917-11.248,11.243-4.911Z"
                  transform="translate(-226.815 -226.819)"
                  fill="#F0323C"
                />
                <rect
                  className="white"
                  data-name="Rectangle 9"
                  width="13.629"
                  height="2.71"
                  transform="translate(30.452 32.37) rotate(-135)"
                  fill="#F0323C"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : name === "eye" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76.473"
            height="70"
            viewBox="0 0 76.473 70"
          >
            <g transform="translate(0 -21.668)">
              <path
                className="white"
                data-name="Path 10718"
                d="M38.236,63.548A45.313,45.313,0,0,1,.523,43.39L0,42.608l.523-.782a45.357,45.357,0,0,1,75.426,0l.524.782-.524.782A45.32,45.32,0,0,1,38.236,63.548ZM3.4,42.608a42.543,42.543,0,0,0,69.682,0,42.543,42.543,0,0,0-69.682,0Z"
                fill="#235c51"
              />
              <path
                data-name="Path 10719"
                d="M196.974,90.273A12.571,12.571,0,1,1,184.4,77.7,12.572,12.572,0,0,1,196.974,90.273Z"
                transform="translate(-146.167 -47.664)"
                fill="#ffab4a"
              />
              <path
                className="white"
                data-name="Path 10720"
                d="M190.866,121h-2.811a10.1,10.1,0,0,1,2.968-7.177l1.992,1.984A7.309,7.309,0,0,0,190.866,121Z"
                transform="translate(-159.967 -78.39)"
                fill="#235c51"
              />
              <g data-name="Group 2240" transform="translate(17.296 21.668)">
                <path
                  className="white"
                  data-name="Path 10721"
                  d="M228.013,138.8a4.914,4.914,0,1,1,4.914-4.914A4.92,4.92,0,0,1,228.013,138.8Zm0-7.017a2.1,2.1,0,1,0,2.1,2.1A2.106,2.106,0,0,0,228.013,131.778Z"
                  transform="translate(-207.073 -112.941)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 10722"
                  d="M136.743,63.549a20.941,20.941,0,1,1,20.939-20.94A20.964,20.964,0,0,1,136.743,63.549Zm0-39.07a18.129,18.129,0,1,0,18.128,18.129A18.15,18.15,0,0,0,136.743,24.479Z"
                  transform="translate(-115.802 -21.668)"
                  fill="#235c51"
                />
                <rect
                  id="Rectangle_3408"
                  data-name="Rectangle 3408"
                  width="2.811"
                  height="6.59"
                  transform="translate(19.537 40.475)"
                  fill="#235c51"
                />
                <path
                  className="white"
                  data-name="Path 10723"
                  d="M225.751,351.713a5.313,5.313,0,0,1-5.312-5.314V327.373h10.625V346.4a5.317,5.317,0,0,1-5.313,5.312Zm-2.5-21.529V346.4a2.5,2.5,0,0,0,5,0V330.184Z"
                  transform="translate(-204.81 -281.713)"
                  fill="#235c51"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : name === "eye_two" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76.473"
            height="70"
            viewBox="0 0 76.473 70"
          >
            <g id="visibility" transform="translate(0 -21.668)">
              <path
                id="Path_10718"
                data-name="Path 10718"
                d="M38.236,63.548A45.313,45.313,0,0,1,.523,43.39L0,42.608l.523-.782a45.357,45.357,0,0,1,75.426,0l.524.782-.524.782A45.32,45.32,0,0,1,38.236,63.548ZM3.4,42.608a42.543,42.543,0,0,0,69.682,0,42.543,42.543,0,0,0-69.682,0Z"
                fill="#ff564f"
              ></path>
              <path
                id="Path_10719"
                data-name="Path 10719"
                d="M196.974,90.273A12.571,12.571,0,1,1,184.4,77.7,12.572,12.572,0,0,1,196.974,90.273Z"
                transform="translate(-146.167 -47.664)"
                fill="#ff564f"
              ></path>
              <path
                id="Path_10720"
                data-name="Path 10720"
                d="M190.866,121h-2.811a10.1,10.1,0,0,1,2.968-7.177l1.992,1.984A7.309,7.309,0,0,0,190.866,121Z"
                transform="translate(-159.967 -78.39)"
                fill="#fff"
              ></path>
              <g
                id="Group_2240"
                data-name="Group 2240"
                transform="translate(17.296 21.668)"
              >
                <path
                  id="Path_10721"
                  data-name="Path 10721"
                  d="M228.013,138.8a4.914,4.914,0,1,1,4.914-4.914A4.92,4.92,0,0,1,228.013,138.8Zm0-7.017a2.1,2.1,0,1,0,2.1,2.1A2.106,2.106,0,0,0,228.013,131.778Z"
                  transform="translate(-207.073 -112.941)"
                  fill="#fff"
                ></path>
                <path
                  id="Path_10722"
                  data-name="Path 10722"
                  d="M136.743,63.549a20.941,20.941,0,1,1,20.939-20.94A20.964,20.964,0,0,1,136.743,63.549Zm0-39.07a18.129,18.129,0,1,0,18.128,18.129A18.15,18.15,0,0,0,136.743,24.479Z"
                  transform="translate(-115.802 -21.668)"
                  fill="#ff564f"
                ></path>
                <rect
                  id="Rectangle_3408"
                  data-name="Rectangle 3408"
                  width="2.811"
                  height="6.59"
                  transform="translate(19.537 40.475)"
                  fill="#ff564f"
                ></rect>
                <path
                  id="Path_10723"
                  data-name="Path 10723"
                  d="M225.751,351.713a5.313,5.313,0,0,1-5.312-5.314V327.373h10.625V346.4a5.317,5.317,0,0,1-5.313,5.312Zm-2.5-21.529V346.4a2.5,2.5,0,0,0,5,0V330.184Z"
                  transform="translate(-204.81 -281.713)"
                  fill="#ff564f"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      ) : name === "eye_three" ? (
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76.473"
            height="70"
            viewBox="0 0 76.473 70"
          >
            <g transform="translate(0 -21.668)">
              <path
                className="white"
                data-name="Path 10718"
                d="M38.236,63.548A45.313,45.313,0,0,1,.523,43.39L0,42.608l.523-.782a45.357,45.357,0,0,1,75.426,0l.524.782-.524.782A45.32,45.32,0,0,1,38.236,63.548ZM3.4,42.608a42.543,42.543,0,0,0,69.682,0,42.543,42.543,0,0,0-69.682,0Z"
                fill="#F0323C"
              />
              <path
                data-name="Path 10719"
                d="M196.974,90.273A12.571,12.571,0,1,1,184.4,77.7,12.572,12.572,0,0,1,196.974,90.273Z"
                transform="translate(-146.167 -47.664)"
                fill="#ffab4a"
              />
              <path
                className="white"
                data-name="Path 10720"
                d="M190.866,121h-2.811a10.1,10.1,0,0,1,2.968-7.177l1.992,1.984A7.309,7.309,0,0,0,190.866,121Z"
                transform="translate(-159.967 -78.39)"
                fill="#F0323C"
              />
              <g data-name="Group 2240" transform="translate(17.296 21.668)">
                <path
                  className="white"
                  data-name="Path 10721"
                  d="M228.013,138.8a4.914,4.914,0,1,1,4.914-4.914A4.92,4.92,0,0,1,228.013,138.8Zm0-7.017a2.1,2.1,0,1,0,2.1,2.1A2.106,2.106,0,0,0,228.013,131.778Z"
                  transform="translate(-207.073 -112.941)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  data-name="Path 10722"
                  d="M136.743,63.549a20.941,20.941,0,1,1,20.939-20.94A20.964,20.964,0,0,1,136.743,63.549Zm0-39.07a18.129,18.129,0,1,0,18.128,18.129A18.15,18.15,0,0,0,136.743,24.479Z"
                  transform="translate(-115.802 -21.668)"
                  fill="#F0323C"
                />
                <rect
                  id="Rectangle_3408"
                  data-name="Rectangle 3408"
                  width="2.811"
                  height="6.59"
                  transform="translate(19.537 40.475)"
                  fill="#F0323C"
                />
                <path
                  className="white"
                  data-name="Path 10723"
                  d="M225.751,351.713a5.313,5.313,0,0,1-5.312-5.314V327.373h10.625V346.4a5.317,5.317,0,0,1-5.313,5.312Zm-2.5-21.529V346.4a2.5,2.5,0,0,0,5,0V330.184Z"
                  transform="translate(-204.81 -281.713)"
                  fill="#F0323C"
                />
              </g>
            </g>
          </svg>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Icon;
