import React from "react";
import BackToTop from "../Helper/BackToTop";
import CommonHero from "../Helper/CommonHero";
import FooterOne from "../Partials/Footers/FooterOne";
import HeaderOne from "../Partials/Headers/HeaderOne";
import MainSection from "./MainSection";

function Contact() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Contact" breadcrumb={["Home", "Contact"]} />
      <MainSection />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default Contact;
