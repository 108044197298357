import React from "react";
import ArticleSection from "../../ArticleSection";
import data from "../../Data/Category";
import detailPageData from "../../Data/PortfolioDetailsOne";
import posts from "../../Data/PostTwo";
import BackToTop from "../../Helper/BackToTop";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import ContactForm from "./ContactForm";
import Hero from "./Hero";
import MainSection from "./MainSection";

function SinglePost() {
  return (
    <>
      <HeaderOne />
      <Hero />
      <MainSection categories={data.categories} posts={posts.posts} />
      <ArticleSection datas={detailPageData.relatedProducts} />
      <ContactForm />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default SinglePost;
