import React from "react";

function Sub({ className, images = [] }) {
  return (
    <section className={`sub-2-area ${className || ""}`}>
      <div className="container">
        <div className="sub-box">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="sub-2-item mt-30">
                {images.length > 0 ? (
                  <img
                    src={
                      require(`../../assets/images/icon/${images[0]}`).default
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={require(`../../assets/images/sub-1.svg`).default}
                    alt=""
                  />
                )}

                <h4 className="title">Innovative solutions</h4>
                <p>
                  Sitting proudly atop the storey penthouse. Natty master suite
                  bedroom phenomenally.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sub-2-item mt-30">
                {images.length > 0 ? (
                  <img
                    src={
                      require(`../../assets/images/icon/${images[1]}`).default
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={require(`../../assets/images/sub-2.svg`).default}
                    alt=""
                  />
                )}
                <h4 className="title">Experienced team</h4>
                <p>
                  Sitting proudly atop the storey penthouse. Natty master suite
                  bedroom phenomenally.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="sub-2-item mt-30 item-3">
                {images.length > 0 ? (
                  <img
                    src={
                      require(`../../assets/images/icon/${images[2]}`).default
                    }
                    alt=""
                  />
                ) : (
                  <img
                    src={require(`../../assets/images/sub-3.svg`).default}
                    alt=""
                  />
                )}
                <h4 className="title">Flexible pricing</h4>
                <p>
                  Sitting proudly atop the storey penthouse. Natty master suite
                  bedroom phenomenally.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sub;
