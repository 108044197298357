import React, { useEffect, useState } from "react";
import StickyMenu from "../../../Lib/StickyMenu";
import Navigation from "../../Helper/Navigation";

function HeaderFive({ className, logo = "logo-2.png" }) {
  const [toggleMenu, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!toggleMenu);
  };
  useEffect(() => {
    StickyMenu(".header-nav");
  }, []);
  return (
    <header className={`header-area header-5-area ${className || ""}`}>
      <div className="header-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="navigation">
                <nav className="navbar navbar-expand-lg navbar-light ">
                  <a className="navbar-brand" href="/">
                    <img
                      src={require(`../../../assets/images/${logo}`).default}
                      alt=""
                    />
                  </a>

                  <button
                    onClick={(e) => toggleHandler(e)}
                    className={`navbar-toggler ${toggleMenu ? "active" : ""}`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className={`collapse navbar-collapse sub-menu-bar ${
                      toggleMenu ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <Navigation />
                  </div>

                  <div className="navbar-btn d-none d-sm-block">
                    <a className="main-btn" href="#">
                      Get Started
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderFive;
