import React from "react";

function Hero() {
  return (
    <section className="hero-6-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-7">
            <div className="hero-6-content">
              <span
                className=" animated wow fadeInDown"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                Welcome to Aball
              </span>
              <h1
                className="title animated wow fadeInLeft"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                Global Leader in Modern Business Innovation
              </h1>
              <div
                className="text animated wow fadeInLeft"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <p>
                  Sitting proudly atop is the two-storey penthouse. The nati
                  master bedroom suite is phenomenally spacious makes the most
                  unrivalled.
                </p>
              </div>
              <ul
                className=" animated wow fadeInUp"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <li>
                  <a className="main-btn" href="#">
                    LeARN MORE
                  </a>
                </li>
                <li>
                  <a className="main-btn main-btn-2" href="#">
                    INTRO VIDEO
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="hero-6-thumb text-right animated wow fadeInRight"
              data-wow-duration="3000ms"
              data-wow-delay="900ms"
            >
              <img
                src={require(`../../assets/images/hero-6-thumb.jpg`).default}
                alt=""
              />
              <div className="hero-dot">
                <img
                  src={require(`../../assets/images/hero-dot.png`).default}
                  alt=""
                />
              </div>
              <div className="box">
                <h3 className="title">
                  <span>25</span>+
                </h3>
                <span> Years of Experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
