import React from "react";
import Data from "../Data/HomeOne";
import BackToTop from "../Helper/BackToTop";
import ArticleSection from "../HomeOne/ArticleSection";
import Testimonial from "../HomeOne/Testimonial";
import Team from "../HomeTwo/Team";
import FooterTwo from "../Partials/Footers/FooterTwo";
import HeaderFive from "../Partials/Headers/HeaderFive";
import About from "./About";
import Hero from "./Hero";
import Project from "./Project";
import Services from "./Services";
import Sponser from "./Sponser";

function HomeFive() {
  return (
    <>
      <HeaderFive />
      <Hero />
      <About />
      <Sponser />
      <Services />
      <Project />
      <Testimonial
        className="testimonial-5-area"
        itemStyle="testimonial-5-item"
      />
      <Team />
      <ArticleSection datas={Data.article} />
      <FooterTwo />
      <BackToTop />
    </>
  );
}

export default HomeFive;
