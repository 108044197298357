import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import VideoPopUp from "../../Helper/VideoPopUp";
import useToggle from "../../Hooks/useToggle";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import ClientArea from "./ClientArea";
import Comments from "./Comments";
import Details from "./Details";
import RelatedProducts from "./RelatedProducts";

function PortfolioDetailsPageTwo() {
  const [openVideo, setVideo] = useToggle(false);
  return (
    <>
      {openVideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={setVideo.toggle}
        />
      )}
      <HeaderOne />
      <CommonHero
        title="Software Development Solutions For Great Business"
        breadcrumb={["Home", "Portfolio", "Details"]}
      />
      <Details action={setVideo.toggle} />
      <ClientArea />
      <RelatedProducts />
      <Comments />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PortfolioDetailsPageTwo;
