import React from "react";
import ArticleSection from "../../ArticleSection";
import detailPageData from "../../Data/PortfolioDetailsOne";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Details from "./Details";

function PortfolioDetailPageOne() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Software Development Solutions For Great Business"
        breadcrumb={["Home", "Protfolio", "Details"]}
      />
      <Details />
      <ArticleSection datas={detailPageData.relatedProducts} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PortfolioDetailPageOne;
