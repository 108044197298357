const data = {
  posts: [
    {
      id: Math.random(),
      img: "article-1.jpg",
      heading: "The Clear Differene Between Cold Brew & Iced Coffee",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
    {
      id: Math.random(),
      img: "article-2.jpg",
      heading: "Your Small Business Success Form Marketing",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
    {
      id: Math.random(),
      img: "article-3.jpg",
      heading: "The Start-Up Ultimate Guide Make Wordpress Journal.",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
    {
      id: Math.random(),
      img: "article-1.jpg",
      heading: "The Clear Differene Between Cold Brew & Iced Coffee",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
    {
      id: Math.random(),
      img: "article-2.jpg",
      heading: "Your Small Business Success Form Marketing",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
    {
      id: Math.random(),
      img: "article-3.jpg",
      heading: "The Start-Up Ultimate Guide Make Wordpress Journal.",
      article:
        "DNP Installations can carry out all maintenance on phone and data and phone points in...",
      day: "25",
      month: "jun",
    },
  ],
};
export default data;
