import React, { useRef } from "react";
import SliderCom from "../Helper/SliderCom";

function Team({ datas }) {
  const teamSlider = useRef();
  const prev = () => {
    teamSlider.current.slickPrev();
  };
  const next = () => {
    teamSlider.current.slickNext();
  };
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "140px",
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="team-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/hero-line.png`).default}
                alt=""
              />
              <h3 className="title">Our Team</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="team-active" style={{ position: "relative" }}>
          <span
            onClick={prev}
            className="prev slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fal fa-angle-left"></i>
          </span>
          <SliderCom settings={settings} selector={teamSlider} className="row">
            {datas &&
              datas.length > 0 &&
              datas.map((item) => (
                <div key={item.id} className="team-slider">
                  <div className="team-item mt-40 mb-100">
                    <img
                      src={require(`../../assets/images/${item.img}`).default}
                      alt=""
                    />
                    <div className="team-overlay d-flex align-items-end">
                      <div className="content">
                        <h4 className="title">Charlotte Emma</h4>
                        <span>Product Designer</span>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </SliderCom>
          <span
            onClick={next}
            className="next slick-arrow"
            style={{ display: "block" }}
          >
            <i className="fal fa-angle-right"></i>
          </span>
        </div>
      </div>
    </section>
  );
}

export default Team;
