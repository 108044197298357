import Masonry from "masonry-layout";

function initMasonry(selector, item, columnWidth = 300) {
  const grid = document.querySelector(`${selector}`);
  const settings = {
    // options...
    itemSelector: `${item}`,
    columnWidth,
  };
  // eslint-disable-next-line no-unused-vars
  const msnry = new Masonry(grid, settings);
}
export default initMasonry;
