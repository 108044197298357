import React, { useEffect, useState } from "react";
import StickyMenu from "../../../Lib/StickyMenu";
import Navigation from "../../Helper/Navigation";

function HeaderOne({ className, logo = "logo.png" }) {
  const [toggleMenu, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!toggleMenu);
  };
  useEffect(() => {
    StickyMenu(".header-nav");
  }, []);
  return (
    <>
      <header className={`header-area ${className}`}>
        <div className="header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="/">
                      <img
                        src={require(`../../../assets/images/${logo}`).default}
                        alt=""
                      />
                    </a>

                    <button
                      className={`navbar-toggler ${toggleMenu ? "active" : ""}`}
                      type="button"
                      onClick={(e) => toggleHandler(e)}
                    >
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                    </button>

                    <div
                      className={`collapse navbar-collapse sub-menu-bar ${
                        toggleMenu ? "show" : ""
                      }`}
                      id="navbarSupportedContent"
                    >
                      <Navigation />
                    </div>
                    <div className="navbar-btn d-none d-sm-block">
                      <a className="main-btn" href="/contact">
                        Get Started
                      </a>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderOne;
