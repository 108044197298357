import React from "react";

function About({ className }) {
  return (
    <>
      <section className={`about-us-area pb-100 ${className || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="about-content">
                <img
                  src={require(`../../assets/images/hero-line.png`).default}
                  alt=""
                />
                <span>About us</span>
                <h3 className="title">We create mind blowing </h3>
                <p>
                  Sitting proudly atop is the two-storey penthouse. The nati
                  master bedroom suite is phenomenally spacious makes the most
                  of a its unrivalled position.
                </p>
                <ul>
                  <li>Graphic design template</li>
                  <li>Motion ui design</li>
                  <li>Theme template design</li>
                  <li>User interface design</li>
                </ul>
                <a className="main-btn" href="/contact">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="about-thumb animated wow fadeInRight"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/about-thumb.png`).default}
                  alt=""
                />
                <ul>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-1.png`).default
                      }
                      alt=""
                    />
                    <span>Top Rated</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-2.png`).default
                      }
                      alt=""
                    />
                    <span>Our Rated</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                  <li>
                    <img
                      src={
                        require(`../../assets/images/about-brand-3.png`).default
                      }
                      alt=""
                    />
                    <span>Our Rated 5 of 5</span>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
