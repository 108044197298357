import React from "react";

function AboutCompany() {
  return (
    <section className="about-company-area">
      <div className="section__bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about-company-content mt-30">
              <h3 className="title">About our company</h3>
              <p>
                Triplet I award-winning interdisciplinary architectule studio
                cultural, residential and commercial projects built worldwide.
                We pride ourselves on being builders creating architectural. We
                pride ourselves on being from agency.
              </p>
              <a className="main-btn main-btn-3" href="/contact ">
                Let’s Chat
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="company-brand-area mt-30">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-1.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-2.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item mt-20">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-3.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item mt-20">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-4.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item mt-20">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-5.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="company-brand-item mt-20">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/brand/brand-6.png`)
                            .default
                        }
                        alt="brand"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutCompany;
