import React from "react";

function KeyFeatureArea() {
  return (
    <section className="key-feature-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/hero-line-4.png`).default}
                alt=""
              />
              <h3 className="title">Key Feature</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
        <div className="key-feature-item mb-80 mt-30">
          <div className="row align-items-center">
            <div className="col-lg-8 order-2 order-lg-1">
              <div className="key-feature-content">
                <h3 className="title">
                  Wide with title two style layout helsepartner nord
                </h3>
                <div className="text">
                  From research and strategy to design and implementation, we
                  partner with some of the world’s most impactful companies to
                  transform their brands and businesses.
                </div>
                <a href="/single-post">
                  View case study
                  <span>
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <div
                className="key-feature-thumb animated wow fadeInRight"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/key-1.png`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="key-feature-item">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div
                className="key-feature-thumb animated wow fadeInLeft"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/key-2.png`).default}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="key-feature-content">
                <h3 className="title">
                  Wide with title two style layout helsepartner nord
                </h3>
                <div className="text">
                  From research and strategy to design and implementation, we
                  partner with some of the world’s most impactful companies to
                  transform their brands and businesses.
                </div>
                <a href="/single-post">
                  View case study
                  <span>
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyFeatureArea;
