import React from "react";
import data from "../../Data/Blogs";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Post from "./Post";

function BlogTwo() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Blog" breadcrumb={["Home", "Blogs"]} />
      <Post datas={data.posts} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default BlogTwo;
