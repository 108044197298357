const data = {
  relatedProducts: [
    {
      id: Math.random(),
      title: "Graphic design vs product design vs graphic illustration",
      image: "related-product-1.png",
      category: "Illustration, Graphic Design",
    },
    {
      id: Math.random(),
      title: "Graphic design",
      image: "related-product-2.png",
      category: "Illustration, Graphic Design",
    },
    {
      id: Math.random(),
      title: "graphic illustration",
      image: "related-product-3.png",
      category: "Illustration, Graphic Design",
    },
  ],
};
export default data;
