import React from "react";

function UpdatePartSection({ className, itemStyle }) {
  return (
    <section className={`update-news-area pt-100 ${className || ""}`}>
      <div className={`update-news-item ${itemStyle || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="update-news-content">
                <span>iOS App Design</span>
                <h3 className="title">
                  Wide with title two style layout helsepartner nord
                </h3>
                <div className="text">
                  <p>
                    From research and strategy to design and impletion, we
                    partner with of the world’s most impactful companies to
                    transform their brands and businesses.
                  </p>
                </div>
                <a href="/single-post">
                  View case study
                  <span>
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-7  order-1 order-lg-2">
              <div
                className="update-news-thumb text-right animated wow fadeInRight"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/update-news-1.jpg`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`update-news-item ${itemStyle || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div
                className="update-news-thumb animated wow fadeInLeft"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/update-news-2.jpg`).default}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="update-news-content">
                <span>iOS App Design</span>
                <h3 className="title">
                  Wide with title two style layout helsepartner nord
                </h3>
                <div className="text">
                  <p>
                    From research and strategy to design and impletion, we
                    partner with of the world’s most impactful companies to
                    transform their brands and businesses.
                  </p>
                </div>
                <a href="/single-post">
                  View case study
                  <span>
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`update-news-item ${itemStyle || ""}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="update-news-content">
                <span>iOS App Design</span>
                <h3 className="title">
                  Wide with title two style layout helsepartner nord
                </h3>
                <div className="text">
                  <p>
                    From research and strategy to design and impletion, we
                    partner with of the world’s most impactful companies to
                    transform their brands and businesses.
                  </p>
                </div>
                <a href="/single-post">
                  View case study
                  <span>
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-7  order-1 order-lg-2">
              <div
                className="update-news-thumb text-right animated wow fadeInRight"
                data-wow-duration="3000ms"
                data-wow-delay="0ms"
              >
                <img
                  src={require(`../../assets/images/update-news-3.jpg`).default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpdatePartSection;
