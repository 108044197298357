import React, { useState } from "react";
import SimpleSlider from "../Helper/SliderCom";
import VideoPopUp from "../Helper/VideoPopUp";

function Hero() {
  const [popupvideo, setValue] = useState(false);
  const toggleHandler = (e) => {
    e.preventDefault();
    setValue(!popupvideo);
  };
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    dots: true,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {popupvideo && (
        <VideoPopUp
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={toggleHandler}
        />
      )}
      <section className="hero-slider hero-5-slide">
        <SimpleSlider settings={settings}>
          <div className="hero-area hero-2-area hero-5-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-2-content">
                    <span data-animation="fadeInDown" data-delay="0.5s">
                      Welcome to Aball
                    </span>
                    <h1
                      className="title"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                      Global Leader in Modern Business Innovation
                    </h1>
                    <div
                      className="play"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <a
                        className="video-popup"
                        href=""
                        onClick={(e) => toggleHandler(e)}
                      >
                        <img
                          src={require(`../../assets/images/play.svg`).default}
                          alt=""
                        />
                      </a>
                      <p>
                        Sitting proudly atop is the two-storey penthouse. The
                        nati master bedroom suite is phenomenally spacious makes
                        the most unrivalled.
                      </p>
                    </div>
                    <div className="slick-count">
                      <span>
                        01<span>/03</span>
                      </span>
                    </div>
                    <div className="hero-btn">
                      <a href="#">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-social">
              <ul className="nav">
                <li>
                  <a href="#">fb</a>
                </li>
                <li>
                  <a href="#">ig</a>
                </li>
                <li>
                  <a href="#">tw</a>
                </li>
              </ul>
            </div>
            <div className="scroll-to-down">
              <a href="#">
                <i className="far fa-long-arrow-down"></i>
              </a>
            </div>
            <div className="hero-thumb text-right">
              <img
                src={require(`../../assets/images/banner-thumb-5.png`).default}
                alt=""
              />
            </div>
          </div>
          <div className="hero-area hero-2-area hero-5-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-2-content">
                    <span data-animation="fadeInDown" data-delay="0.5s">
                      Welcome to Aball
                    </span>
                    <h1
                      className="title"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                      Global Leader in Modern Business Innovation
                    </h1>
                    <div
                      className="play"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <a
                        className="video-popup"
                        href=""
                        onClick={(e) => toggleHandler(e)}
                      >
                        <img
                          src={require(`../../assets/images/play.svg`).default}
                          alt=""
                        />
                      </a>
                      <p>
                        Sitting proudly atop is the two-storey penthouse. The
                        nati master bedroom suite is phenomenally spacious makes
                        the most unrivalled.
                      </p>
                    </div>
                    <div className="slick-count">
                      <span>
                        02<span>/03</span>
                      </span>
                    </div>
                    <div className="hero-btn">
                      <a href="#">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-social">
              <ul className="nav">
                <li>
                  <a href="#">fb</a>
                </li>
                <li>
                  <a href="#">ig</a>
                </li>
                <li>
                  <a href="#">tw</a>
                </li>
              </ul>
            </div>
            <div className="scroll-to-down">
              <a href="#">
                <i className="far fa-long-arrow-down"></i>
              </a>
            </div>
            <div className="hero-thumb text-right">
              <img
                src={require(`../../assets/images/banner-thumb-5.png`).default}
                alt=""
              />
            </div>
          </div>
          <div className="hero-area hero-2-area hero-5-area">
            <div className="section__bg"></div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7">
                  <div className="hero-2-content">
                    <span data-animation="fadeInDown" data-delay="0.5s">
                      Welcome to Aball
                    </span>
                    <h1
                      className="title"
                      data-animation="fadeInLeft"
                      data-delay="0.6s"
                    >
                      Global Leader in Modern Business Innovation
                    </h1>
                    <div
                      className="play"
                      data-animation="fadeInUp"
                      data-delay="0.7s"
                    >
                      <a
                        className="video-popup"
                        href=""
                        onClick={(e) => toggleHandler(e)}
                      >
                        <img
                          src={require(`../../assets/images/play.svg`).default}
                          alt=""
                        />
                      </a>
                      <p>
                        Sitting proudly atop is the two-storey penthouse. The
                        nati master bedroom suite is phenomenally spacious makes
                        the most unrivalled.
                      </p>
                    </div>
                    <div className="slick-count">
                      <span>
                        03<span>/03</span>
                      </span>
                    </div>
                    <div className="hero-btn">
                      <a href="#">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hero-social">
              <ul className="nav">
                <li>
                  <a href="#">fb</a>
                </li>
                <li>
                  <a href="#">ig</a>
                </li>
                <li>
                  <a href="#">tw</a>
                </li>
              </ul>
            </div>
            <div className="scroll-to-down">
              <a href="#">
                <i className="far fa-long-arrow-down"></i>
              </a>
            </div>
            <div className="hero-thumb text-right">
              <img
                src={require(`../../assets/images/banner-thumb-5.png`).default}
                alt=""
              />
            </div>
          </div>
        </SimpleSlider>
      </section>
    </>
  );
}

export default Hero;
