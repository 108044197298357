import React from "react";

function MainSection() {
  return (
    <>
      <section className="contact-us-area contact-us-page">
        <div className="section__bg"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="contact-us-box">
                <h3 className="title">Get in Touch</h3>
                <p>
                  Give us a call or drop by anytime, we endeavour to <br />
                  answer all enquiries within 24 hours.
                </p>
                <form action="#">
                  <div className="row no-gutters">
                    <div className="col-lg-6">
                      <div className="input-box mt-10">
                        <input type="text" placeholder="Your name" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box mt-10">
                        <input type="email" placeholder="Email address" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box mt-10">
                        <input type="text" placeholder="number" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box mt-10">
                        <input type="text" placeholder="Location" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box mt-10">
                        <textarea
                          name="#"
                          id="#"
                          cols="30"
                          rows="10"
                          placeholder="write message"
                        ></textarea>
                      </div>
                      <ul className="checkbox_common checkbox_style5">
                        <li>
                          <input
                            type="checkbox"
                            name="checkbox5"
                            id="checkbox13"
                          />
                          <label htmlFor="checkbox13">
                            <span></span>I agree to the data protection
                            regulations
                          </label>
                        </li>
                      </ul>
                      <div className="input-box">
                        <button className="main-btn " type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-map">
        <iframe
          title="contact-map-frame"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14612.962398285066!2d90.45967325!3d23.703100600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1595237498018!5m2!1sen!2sbd"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
        ></iframe>
      </div>
      <div className="subscribe-area subscribe-9-area subscribe-13-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="subscribe-item">
                <h3 className="title">Love Eating? Like offer?</h3>
                <span>
                  Since ancient times, wine has been perceive part of our
                  culture and a symbol of good life role of wine has centuries.
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="subscribe-form subscribe-9-form subscribe-13-form">
                <input type="email" placeholder="Your email address" />
                <button type="button" className="main-btn">
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;
