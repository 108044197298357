import React, { useEffect } from "react";
import MasonryCss from "../../Lib/MasonryCss";

function Project({ className }) {
  useEffect(() => {
    setTimeout(() => {
      MasonryCss(".grid", ".grid-item");
    }, 500);
  });
  return (
    <section className={`project-area overflow-hidden ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 grid-item">
            <div className="project-title text-center">
              <h3 className="title">Our Project</h3>
              <p>
                I help agencies & brands like Google, Volvo and others to turn
                their ideas into designs.
              </p>
            </div>
          </div>
        </div>
        <div className="row grid">
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-1.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-2.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-4.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-3.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-5.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-6.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-7.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 grid-item">
            <div className="project-item item-9 mt-30">
              <img
                src={require(`../../assets/images/project-8.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digital artist design</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 grid-item">
            <div className="project-item mt-30">
              <img
                src={require(`../../assets/images/project-9.png`).default}
                alt=""
              />
              <div className="project-overlay">
                <img
                  src={require(`../../assets/images/project-line.png`).default}
                  alt=""
                />
                <h4 className="title">Digitaln</h4>
                <span>Illustration</span>
                <a href="#">
                  <img
                    src={require(`../../assets/images/icon/arrow.png`).default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
