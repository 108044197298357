import React from "react";

function Services() {
  return (
    <>
      <section className="services-page-area pt-70 about-service-3-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="services-5-item animated wow fadeInLeft"
                data-wow-duration="1000ms"
                data-wow-delay="0ms"
              >
                <div className="sercices-content mt-30">
                  <h4 className="title">Product design</h4>
                  <ul>
                    <li>Graphic design template</li>
                    <li>Motion ui design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                    <li>User experience design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                  </ul>
                  <div className="sercices-overlay">
                    <a className="main-btn" href="#">
                      More details
                    </a>
                  </div>
                </div>
                <div className="sercices-thumb mt-30">
                  <img
                    src={
                      require(`../../../assets/images/services-item-1.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="services-5-item animated wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <div className="sercices-thumb mt-30">
                  <img
                    src={
                      require(`../../../assets/images/services-item-2.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
                <div className="sercices-content mt-30">
                  <h4 className="title">Product design</h4>
                  <ul>
                    <li>Graphic design template</li>
                    <li>Motion ui design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                    <li>User experience design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                  </ul>
                  <div className="sercices-overlay">
                    <a className="main-btn" href="#">
                      More details
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div
                className="services-5-item item-3 animated wow fadeInRight"
                data-wow-duration="1000ms"
                data-wow-delay="600ms"
              >
                <div className="sercices-content mt-30">
                  <h4 className="title">Product design</h4>
                  <ul>
                    <li>Graphic design template</li>
                    <li>Motion ui design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                    <li>User experience design</li>
                    <li>Theme template design</li>
                    <li>User interface design</li>
                  </ul>
                  <div className="sercices-overlay">
                    <a className="main-btn" href="#">
                      More details
                    </a>
                  </div>
                </div>
                <div className="sercices-thumb mt-30">
                  <img
                    src={
                      require(`../../../assets/images/services-item-3.png`)
                        .default
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
