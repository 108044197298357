import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Project from "../../HomeFour/Project";
import Pricing from "../../HomeThree/Pricing";
import Team from "../../HomeTwo/Team";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function PricingPageThree() {
  return (
    <>
      <HeaderOne />
      <CommonHero
        title="Pricing Three"
        breadcrumb={["Home", "Pricing Three"]}
      />
      <Pricing />
      <Project />
      <Team />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PricingPageThree;
