import React from "react";
import CardStyleOne from "../Cards/CardStyleOne";

function ArticleSection({ datas }) {
  return (
    <section className="article-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="section-title-6 text-center">
              <h3 className="title">Recent Article</h3>
              <span>
                I help agencies & brands like Google, Volvo and others to turn
                their ideas into designs.
              </span>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {datas &&
            datas.length > 0 &&
            datas.map((item) => (
              <div key={item.id} className="col-lg-4 col-md-6 col-sm-9">
                <CardStyleOne datas={item} className="article-6-item" />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

export default ArticleSection;
