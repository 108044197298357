import React from "react";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Team from "../../HomeTwo/Team";
import Testimonial from "../../HomeTwo/Testimonial";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";
import Services from "./Services";

function ServiceTwo() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Service two" breadcrumb={["home", "Service two"]} />
      <Services />
      <Testimonial />
      <Team />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default ServiceTwo;
