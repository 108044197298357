import React from "react";

function FooterThree() {
  const backToTopHandler = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <section className="footer-3-area pt-70 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="footer-logo mt-30">
              <a href="index-3.html">
                <img
                  src={require(`../../../assets/images/logo-3.png`).default}
                  alt=""
                />
              </a>
            </div>
            <div className="go-top">
              <a className="back-to-top" onClick={(e) => backToTopHandler(e)}>
                <i className="fal fa-arrow-up"></i>
                <span>Go to top</span>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-3-item mt-30">
              <div className="footer-work">
                <span>Want to collaborate?</span>
                <div className="item">
                  <span>Work with us</span>
                  <h3 className="title">Hello.business@brand.com</h3>
                </div>
              </div>
              <div className="footer-work item-2">
                <span>View on maps</span>
                <div className="item ">
                  <span>Corporate office</span>
                  <h3 className="title">
                    Study Meeting 19, 4th floor DK-1455 Copenhagen
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="footer-3-item mt-30">
              <div className="footer-work">
                <span>What to say hi?</span>
                <div className="item">
                  <span>General inquires</span>
                  <h3 className="title">Hello@brand.com</h3>
                </div>
              </div>
              <div className="footer-work item-2">
                <span>Join Our Social Community</span>
                <div className="item ">
                  <span>Follow us</span>
                  <ul>
                    <li>
                      <a href="#">TW</a>
                    </li>
                    <li>
                      <a href="#">IG</a>
                    </li>
                    <li>
                      <a href="#">FB</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FooterThree;
