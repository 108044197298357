import React from "react";
import CounterUpCom from "../../Lib/CounterUp";

function FunFact() {
  return (
    <section className="fun-facts-area" id="homeTwoFunFact">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="fun-facts-item mt-50 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="0ms"
            >
              <div className="icon">
                <img
                  src={require(`../../assets/images/brand/item-1.png`).default}
                  alt=""
                />
              </div>
              <h3 className="title count">
                <CounterUpCom endValue={79} sectionSelect="homeTwoFunFact" />
              </h3>
              <span>Project taken</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="fun-facts-item mt-50 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <div className="icon">
                <img
                  src={require(`../../assets/images/brand/item-2.png`).default}
                  alt=""
                />
              </div>
              <h3 className="title count">
                <CounterUpCom endValue={63} sectionSelect="homeTwoFunFact" />
              </h3>
              <span>Years of experience</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="fun-facts-item mt-50 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <div className="icon">
                <img
                  src={require(`../../assets/images/brand/item-3.png`).default}
                  alt=""
                />
              </div>
              <h3 className="title count">
                <CounterUpCom endValue={27} sectionSelect="homeTwoFunFact" />
              </h3>
              <span>Awards won</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div
              className="fun-facts-item mt-50 animated wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="900ms"
            >
              <div className="icon">
                <img
                  src={require(`../../assets/images/brand/item-4.png`).default}
                  alt=""
                />
              </div>
              <h3 className="title">
                <span className="count">
                  <CounterUpCom endValue={32} sectionSelect="homeTwoFunFact" />
                </span>
                k
              </h3>
              <span>Twitter followers</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FunFact;
