const data = {
  posts: [
    {
      id: Math.random(),
      title: "What Gets in the Way Great Strategy?",
      date: "Jully 20, 2020",
      image: "popular-post-1.png",
      link: "#",
    },
    {
      id: Math.random(),
      title: "The Start-Up Ultimate Guide To Make Press Journal.",
      date: "Jully 20, 2020",
      image: "popular-post-2.png",
      link: "#",
    },
    {
      id: Math.random(),
      title: "YOUR SMALL BUSINESS Success Form MARKETING",
      date: "Jully 20, 2020",
      image: "popular-post-3.png",
      link: "#",
    },
    {
      id: Math.random(),
      title: "The Clear Difference Between Cold Brew And Iced Coffee",
      date: "Jully 20, 2020",
      image: "popular-post-4.png",
      link: "#",
    },
    {
      id: Math.random(),
      title: "What Gets in the Way Great Strategy?",
      date: "Jully 20, 2020",
      image: "popular-post-5.png",
      link: "#",
    },
  ],
};
export default data;
