import React from "react";

function Details({ action }) {
  return (
    <section className="portfolio-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-details-top">
              <div className="row">
                <div className="col-lg-7">
                  <div className="portfolio-details-top-title mt-30">
                    <h3 className="title">
                      Everything you need to grow your business Order today!
                    </h3>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="portfolio-details-top-text mt-30">
                    <p>
                      Entilators will be taken from certain New York hospitals
                      and redistributed to the worst-hit parts of the state
                      under an order to be signed by Governor Andrew Cuomo. New
                      York saw its highest single-day increase in deaths, up by
                      562 to 2,935 - nearly half of all virus-related US deaths
                      recorded yesterday.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-details-category">
              <div className="row">
                <div className="col-lg-8">
                  <div className="portfolio-details-category-thumb mt-30">
                    <img
                      src={
                        require(`../../../assets/images/portfolio-details-thumb.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="portfolio-details-category-list mt-30">
                    <div className="item">
                      <span>Client:</span>
                      <h4 className="title">Seative Digital</h4>
                    </div>
                    <div className="item">
                      <span>Services:</span>
                      <h4 className="title">
                        App Design, Development A/B Testing
                      </h4>
                    </div>
                    <div className="item">
                      <span>Team:</span>
                      <h4 className="title">Product Design and Development</h4>
                    </div>
                    <div className="item">
                      <span>Category:</span>
                      <h4 className="title">Digital Product</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-details-content mt-65">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text">
                    <p>
                      New York saw its highest single-day increase in deaths, up
                      562 to 2,935 - nearly half of all virus-related US deaths
                      recorded yesterday. The White House may advise those in
                      virus hotspots to wear face coverings in public to help
                      stem the spread mid deepening crisis, top health official
                      Dr Anthony Fauci has said he believes all states should
                      issue stay-at-home orders.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text">
                    <p>
                      I don’t understand why that’s not happening,” Dr Fauci
                      told CNN on Thursday. “If you look at what’s going on in
                      this country, I just don’t understand why we’re not doing
                      that.”
                    </p>
                  </div>
                  <div className="text pt-15">
                    <p>
                      You’ve got to put your foot on the accelerator to bring
                      that number down,” he added, referring to infection and
                      death rates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-business-planing pt-80 pb-60">
              <h3 className="title">Business planing</h3>
              <div className="business-planing-items d-flex justify-content-center justify-content-lg-between align-items-center">
                <div className="item mt-65">
                  <img
                    src={
                      require("../../../assets/images/icon/planing-1.png")
                        .default
                    }
                    alt=""
                  />
                  <span>Business Stratagy</span>
                </div>
                <div className="item mt-65">
                  <img
                    src={
                      require("../../../assets/images/icon/planing-2.png")
                        .default
                    }
                    alt=""
                  />
                  <span>UX & Product Design</span>
                </div>
                <div className="item mt-65">
                  <img
                    src={
                      require("../../../assets/images/icon/planing-3.png")
                        .default
                    }
                    alt=""
                  />
                  <span>App Development</span>
                </div>
                <div className="item mt-65">
                  <img
                    src={
                      require("../../../assets/images/icon/planing-4.png")
                        .default
                    }
                    alt=""
                  />
                  <span>Marketing Stratagy</span>
                </div>
                <div className="item mt-65">
                  <img
                    src={
                      require("../../../assets/images/icon/planing-5.png")
                        .default
                    }
                    alt=""
                  />
                  <span>Digital Marketing</span>
                </div>
              </div>
            </div>
            <div className="portfolio-details-thumb">
              <div className="row">
                <div className="col-lg-6">
                  <div className="portfolio-details-thumb-1">
                    <img
                      src={
                        require(`../../../assets/images/portfolio-details-thumb-1.png`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="portfolio-details-thumb-2">
                    <img
                      src={
                        require(`../../../assets/images/portfolio-details-thumb-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <a
                      onClick={(e) => action(e)}
                      className="video-popup"
                      href="https://www.youtube.com/watch?v=UFTc0wl26TY"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-details-content mt-65">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text">
                    <p>
                      New York saw its highest single-day increase in deaths, up
                      562 to 2,935 - nearly half of all virus-related US deaths
                      recorded yesterday. The White House may advise those in
                      virus hotspots to wear face coverings in public to help
                      stem the spread mid deepening crisis, top health official
                      Dr Anthony Fauci has said he believes all states should
                      issue stay-at-home orders.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text">
                    <ul>
                      <li>Should more of us wear face masks?</li>
                      <li>
                        Why some countries wear face masks and others don’t
                      </li>
                      <li>Coronavirus: Are homemade face masks safe?</li>
                      <li>
                        Should Subash theme cool more of us wear face masks?
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
