import React from "react";

function Hero() {
  return (
    <section className="hero-3-area">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-7">
            <div className="hero-3-content">
              <span>Hello</span>
              <h3 className="title">
                I am Benjamin Product <span>Designer</span>
              </h3>
              <div className="text">
                <p>
                  Sitting proudly atop is the two-storey penthouse. The nati
                  master bedroom suite is phenomenally spacious makes the most
                  unrivalled.
                </p>
              </div>
              <ul>
                <li>
                  <a className="main-btn" href="#">
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-1.png`)
                          .default
                      }
                      alt=""
                    />
                    Download cv
                  </a>
                </li>
                <li>
                  <a className="main-btn main-btn-2" href="/contact">
                    Learn more
                    <img
                      src={
                        require(`../../assets/images/icon/hero-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-social">
        <ul>
          <li>
            <a href="#">TW</a>
          </li>
          <li>
            <a href="#">ig</a>
          </li>
          <li>
            <a href="#">fb</a>
          </li>
        </ul>
      </div>
      <div className="hero-thumb">
        <img
          src={require(`../../assets/images/hero-thumb.png`).default}
          alt=""
        />
      </div>
    </section>
  );
}

export default Hero;
