import React from "react";

function About({ className, contentStyle, thumbStyle }) {
  return (
    <section className={`about-2-area ${className || ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-9">
            <div className={`about-2-content mt-30 ${contentStyle || ""}`}>
              <span>About us</span>
              <h3 className="title">We help to create</h3>
              <p>
                Sitting proudly atop is the two-storey penthouse. The nati
                master bedroom suite is phenomenally spacious makes the most of
                a its unrivalled position.
              </p>
              <div className="item active mt-60">
                <h4 className="title">Designs you will love</h4>
                <p>
                  Sitting proudly atop is the two-storey penthouse. The and
                  opens breathtaking plunge.
                </p>
              </div>
              <div className="item mt-40">
                <h4 className="title">A healthy agency relationship</h4>
                <p>
                  Sitting proudly atop is the two-storey penthouse. The and
                  opens breathtaking plunge.
                </p>
              </div>
              <a className="main-btn main-btn-3 mt-65" href="/contact">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <div
              className={`about-2-thumb mt-30 animated wow fadeInRight ${
                thumbStyle || ""
              }`}
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <div className="thumb text-right">
                <img
                  src={require(`../../assets/images/about-thumb-2.png`).default}
                  alt=""
                />
              </div>
              <div className="thumb-2 ml-80">
                <img
                  src={require(`../../assets/images/about-thumb-3.png`).default}
                  alt=""
                />
                <div className="box">
                  <h3 className="title">
                    <span>25</span>+
                  </h3>
                  <span> Years of Experience</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
