import React, { useRef } from "react";
import SimpleSlider from "../Helper/SliderCom";

function ScreenshotArea() {
  const ss = useRef(null);
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="screenshot-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="section-title text-center">
              <img
                src={require(`../../assets/images/hero-line-4.png`).default}
                alt=""
              />
              <h3 className="title">Key Feature</h3>
              <p>
                Our focus is on delivering you the absolute best support guiding
                you through training and providing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        <SimpleSlider
          className="screenshot-active"
          settings={settings}
          selector={ss}
        >
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-1.png`).default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-2.png`).default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-3.png`).default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-4.png`).default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-2.png`).default}
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="screenshot-item">
              <img
                src={require(`../../assets/images/screenshot-3.png`).default}
                alt=""
              />
            </div>
          </div>
        </SimpleSlider>
      </div>
    </section>
  );
}

export default ScreenshotArea;
