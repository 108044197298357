import React from "react";
import data from "../../Data/HomeOne";
import BackToTop from "../../Helper/BackToTop";
import CommonHero from "../../Helper/CommonHero";
import Pricing from "../../HomeOne/Pricing";
import Services from "../../HomeOne/Services";
import Team from "../../HomeOne/Team";
import FooterOne from "../../Partials/Footers/FooterOne";
import HeaderOne from "../../Partials/Headers/HeaderOne";

function PricingPage() {
  return (
    <>
      <HeaderOne />
      <CommonHero title="Pricing One" breadcrumb={["Home", "Pricing One"]} />
      <Pricing />
      <Services />
      <Team datas={data.teamMembers} />
      <FooterOne />
      <BackToTop />
    </>
  );
}

export default PricingPage;
