import React, { useState } from "react";

function About() {
  const [tab, setTab] = useState("skill");
  const tabHandler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <section className="about-3-area pt-100 pb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div
              className="about-3-thumb animated wow fadeInLeft"
              data-wow-duration="3000ms"
              data-wow-delay="0ms"
            >
              <img
                src={require(`../../assets/images/about-thumb.jpg`).default}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-3-content">
              <div className="content">
                <h3 className="title">About Me</h3>
                <div className="text">
                  <p>
                    I help agencies & brands like Google, Volvo and others to
                    turn their ideas into designs. Great designs and a healthy
                    freelance relationship. <span>Benjamin</span>
                  </p>
                </div>
              </div>
              <div className="about-tab">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => tabHandler(e, "skill")}
                      className={`nav-link ${tab === "skill" ? "active" : ""}`}
                      id="pills-1-tab"
                    >
                      Skills
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => tabHandler(e, "experience")}
                      className={`nav-link ${
                        tab === "experience" ? "active" : ""
                      }`}
                      id="pills-2-tab"
                    >
                      Experience
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => tabHandler(e, "awards")}
                      className={`nav-link ${tab === "awards" ? "active" : ""}`}
                      id="pills-3-tab"
                    >
                      Awards
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      onClick={(e) => tabHandler(e, "education")}
                      className={`nav-link ${
                        tab === "education" ? "active" : ""
                      }`}
                      id="pills-4-tab"
                    >
                      Educations
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      tab === "skill" ? "show active" : ""
                    }`}
                    id="pills-1"
                  >
                    <div className="text">
                      <p>
                        I help agencies & brands like Google, Volvo and others
                        to turn their ideas into designs.
                      </p>
                    </div>
                    <ul>
                      <li>Graphic design template</li>
                      <li>Motion ui design</li>
                      <li>Theme template design</li>
                      <li>User interface design</li>
                      <li>User experience design</li>
                    </ul>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "experience" ? "show active" : ""
                    }`}
                    id="pills-2"
                  >
                    <div className="text">
                      <p>
                        I help agencies & brands like Google, Volvo and others
                        to turn their ideas into designs.
                      </p>
                    </div>
                    <ul>
                      <li>Graphic design template</li>
                      <li>Motion ui design</li>
                      <li>Theme template design</li>
                      <li>User interface design</li>
                      <li>User experience design</li>
                    </ul>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "awards" ? "show active" : ""
                    }`}
                    id="pills-3"
                  >
                    <div className="text">
                      <p>
                        I help agencies & brands like Google, Volvo and others
                        to turn their ideas into designs.
                      </p>
                    </div>
                    <ul>
                      <li>Graphic design template</li>
                      <li>Motion ui design</li>
                      <li>Theme template design</li>
                      <li>User interface design</li>
                      <li>User experience design</li>
                    </ul>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "education" ? "show active" : ""
                    }`}
                    id="pills-4"
                  >
                    <div className="text">
                      <p>
                        I help agencies & brands like Google, Volvo and others
                        to turn their ideas into designs.
                      </p>
                    </div>
                    <ul>
                      <li>Graphic design template</li>
                      <li>Motion ui design</li>
                      <li>Theme template design</li>
                      <li>User interface design</li>
                      <li>User experience design</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
